import { Box, Typography } from '@mui/material';
import React from 'react';
import { ManageColorTheme } from '../../../helper/ChangeTheme';
import { globleStyle } from '../../../assets/Style/globleStyle';
import { RightAerrow } from '../../../assets/Image/Icon';
import { useNavigate } from 'react-router-dom';

export default function BredCrums(props: {
  action: React.ReactNode;
  heading: string;
  description: string;
  path: { name: string; path: string }[];
}) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {props.path.map((val, i) => {
            return (
              <Typography
                key={i}
                onClick={() => {
                  navigate(val.path);
                }}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  color:
                    props?.path?.length - 1 !== i
                      ? ManageColorTheme(
                          globleStyle.layout.light.description,
                          globleStyle.layout.dark.description
                        )
                      : ManageColorTheme(
                          globleStyle.layout.light.activeColor,
                          globleStyle.layout.dark.activeColor
                        ),
                }}
                variant="caption"
              >
                {val.name}{' '}
                {props?.path?.length - 1 !== i && (
                  <Box sx={{ marginLeft: 1, marginRight: 1 }}>
                    <RightAerrow />
                  </Box>
                )}
              </Typography>
            );
          })}
        </Box>
        <Typography variant="h5">{props.heading}</Typography>
        <Typography
          variant="body2"
          sx={{
            color: ManageColorTheme(
              globleStyle.layout.light.description,
              globleStyle.layout.dark.description
            ),
          }}
        >
          {props.description}
        </Typography>
      </Box>
      {props.action}
    </Box>
  );
}
