import { createSlice } from '@reduxjs/toolkit';
import { ThemeCounterState } from '../../type/Slice/ThemeSliceType';
import { themeStoragekey } from '../../constant/theme';

const initialState: ThemeCounterState = {
  theme: '',
};

// Create the slice
const ThemeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme: state => {
      const isDarkMode: string = localStorage.getItem(themeStoragekey) || '';
      state.theme = isDarkMode;
    },
  },
});

// Export the actions
export const { changeTheme } = ThemeSlice.actions;

// Export the reducer
export default ThemeSlice.reducer;
