import { Box, Card, Typography, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { ManageColorTheme } from '../../../helper/ChangeTheme';
import { globleStyle } from '../../../assets/Style/globleStyle';
import authLogo from '../../../assets/Image/logo.svg';
import authLogoLight from '../../../assets/Image/whiteLogo.svg';

export default function Header(props: {
  card: ReactNode;
  heading: string;
  text: ReactNode | string;
}) {
  const mode = localStorage.getItem('theme');
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Card
        variant="elevation"
        sx={{
          border: 'none',
          boxShadow: '0px 12px 24px -4px #919EAB1F',
          padding: '20px',
          paddingTop: '40px',
          paddingBottom: '30px',
          borderRadius: '10px',
          [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            width: '90%', // Very small devices
          },
          [theme.breakpoints.between('xs', 'sm')]: {
            width: '90%', // Small devices
          },
          [theme.breakpoints.between('sm', 'md')]: {
            width: '75%', // Medium devices (tablets)
          },
          [theme.breakpoints.between('md', 'lg')]: {
            width: '50%', // Large devices (small desktops)
          },
          [theme.breakpoints.between('lg', 'xl')]: {
            width: '30%', // Extra large devices (desktops)
          },
          [theme.breakpoints.up('xl')]: {
            width: '22%', // Largest devices
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={mode === 'light' ? authLogo : authLogoLight} alt="logo" />
        </Box>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            fontFamily: globleStyle.font.type,
            color: ManageColorTheme(
              globleStyle.layout.light.heading,
              globleStyle.layout.dark.heading
            ),
          }}
        >
          {props.heading}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: ManageColorTheme(
              globleStyle.layout.light.description,
              globleStyle.layout.dark.description
            ),
          }}
        >
          {props.text}
        </Typography>
        {props.card}
      </Card>
    </Box>
  );
}
