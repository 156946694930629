import React, { useState } from 'react';
import Header from '../../Component/AuthLayout/Header';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Buttons from '../../Component/Buttons';
import * as Yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTHPATH } from '../../Route/Path';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { yupResolver } from '@hookform/resolvers/yup';
import apiService from '../../API/APIService';
import { decryptData, encryptData } from '../../API/encrypt';
import { Notification } from '../../helper/Notification';
import { passwordRegex } from '../../constant/StaticData';

interface IFormInput {
  password: string;
  confirmPassword: string;
}

const validationSchema: Yup.ObjectSchema<IFormInput> = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(passwordRegex, 'Password must be at least 8 characters long, contain both letters and numbers, and include at least one special character'), // You can add more password rules if needed
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'), // Ensures confirmPassword matches password
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const splitPath = location.pathname.split('/');

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
  });

  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    try {
      setLoading(true);
      const header = {
        Authorization: `Bearer ${splitPath[2]}`,
      };
      const finalData = {
        password: data.password,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.put(
        '/v1/api/admin/forgot-password-email',
        secret_text,
        header
      );
      if (res.status) {
        setLoading(false);
        Notification('success', res.message);
        navigate(AUTHPATH.root);
      } else {
        setLoading(false);
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Header
        heading="Reset Password 🔒"
        text="Your new password must be different from previously used passwords"
        card={
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              type={showPassword ? 'text' : 'password'}
              sx={{ width: '100%' }}
              placeholder="Password"
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Typography
              variant="body2"
              sx={{ textAlign: 'end', color: '#999999' }}
            >
              20/2000
            </Typography> */}
            <TextField
              type={showPassword ? 'text' : 'password'}
              sx={{ width: '100%' }}
              placeholder="Confirm Password"
              {...register('confirmPassword')}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Typography
              variant="body2"
              sx={{ textAlign: 'end', color: '#999999' }}
            >
              20/2000
            </Typography> */}
            <Buttons
              style={{ width: '100%', marginTop: '20px' }}
              loading={loading}
              iconAlign="left"
              iconSize={18}
              type="submit"
              icon=""
              variant="button"
              onClick={() => {}}
              label="RESET PASSWORD"
            />
            <Box
              onClick={() => {
                navigate(AUTHPATH.root);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '5%',
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  marginTop: 0.8,
                  marginRight: 1,
                  color: ManageColorTheme(
                    globleStyle.layout.light.heading,
                    globleStyle.layout.dark.heading
                  ),
                }}
              >
                <ArrowBackIosIcon />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  cursor: 'pointer',
                  color: ManageColorTheme(
                    globleStyle.layout.light.heading,
                    globleStyle.layout.dark.heading
                  ),
                }}
              >
                Back to login
              </Typography>
            </Box>
          </Box>
        }
      />
    </div>
  );
}
