import React from 'react';
import Header from '../../Component/AuthLayout/Header';
import { Box, Typography } from '@mui/material';
import Buttons from '../../Component/Buttons';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTHPATH } from '../../Route/Path';
import { decryptData, encryptData } from '../../API/encrypt';
import apiService from '../../API/APIService';
import { Notification } from '../../helper/Notification';

export default function EmailVerify() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleResetPassword = async () => {
    try {
      if (email !== '') {
        const finalData = {
          email: email,
        };
        const incriptData = (await encryptData(finalData)) as string;
        const secret_text = {
          secret_text: incriptData,
        };
        const res = await apiService.put(
          'v1/api/admin/forget-password',
          secret_text
        );
        if (res.status) {
          navigate(AUTHPATH.verify, { state: { email: email } });

          Notification('success', res.message);
        } else {
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
    }
  };

  return (
    <div>
      <Header
        heading="Forgot Password ✉️"
        text={
          <p>
            We have sent password reset link to your Email Address{' '}
            <b>{email}</b>. Please go to your mail box and reset your password.
          </p>
        }
        card={
          <Box>
            <Buttons
              style={{ width: '100%', marginTop: '20px' }}
              loading={false}
              iconAlign="left"
              iconSize={18}
              icon=""
              variant="button"
              onClick={() => {
                navigate(AUTHPATH.root);
              }}
              label="Back to Login"
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 2,
              }}
            >
              <Typography variant="body1">{`Didn't get the mail?`}</Typography>
              &nbsp;
              <Typography
                onClick={() => {
                  handleResetPassword();
                }}
                variant="body1"
                sx={{
                  cursor: 'pointer',
                  color: ManageColorTheme(
                    globleStyle.layout.light.heading,
                    globleStyle.layout.dark.heading
                  ),
                }}
              >
                Resend
              </Typography>
            </Box>
          </Box>
        }
      />
    </div>
  );
}
