import { Box, IconButton, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globleStyle } from '../../../assets/Style/globleStyle';
import { ManageColorTheme, onChangeTheme } from '../../../helper/ChangeTheme';
import DropdownMenu from '../../../Component/Dropdown';
import profile from '../../../assets/Image/profile.png';
import { Notification } from '../../../assets/Image/Icon';
// import SearchInput from '../../../Component/SearchInput';
import { roleStoragekey } from '../../../constant/role';
import { logout } from '../../../Redux/slices/AuthSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTHPATH, PATH } from '../../../Route/Path';
import SideModel from '../../SideModel';
import NotificationScreen from '../Notification';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { changeTheme } from '../../../Redux/slices/ThemeSlice';
import apiService from '../../../API/APIService';
import { generateQueryString } from '../../../helper/generateQueryString';
import moment from 'moment';
import iconLogo from '../../../assets/Image/iconLogo.svg';
import { headerSet } from '../../../API/Header';

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const mode = localStorage.getItem('theme');
  const sidebarStatus = localStorage.getItem('sidebar');

  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      dispatch(logout());
      localStorage.removeItem(roleStoragekey);
      navigate(AUTHPATH.root);
      console.log('error', error);
    }
  };

  const getNotification = async () => {
    try {
      setLoading(true);
      const data = {
        page: 0,
        size: limit,
        created_at__sw: moment().format('YYYY-MM-DD'),
        sort: 'created_at',
      };
      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/get-notification-list?${query}`,
        headerSet(false)
      );
      if (res.status) {
        setNotification(res?.data?.rows);
        setStatus(res?.data?.pagination?.hasNextPage);
        setLoading(false);
      }
    } catch (error) {
      handleLogout();
      setLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getNotification();
    }, 2000);
  }, [limit]);

  const dropdownData = [
    {
      name: (
        <MenuItem
          onClick={() => {
            navigate(PATH.profile);
          }}
        >
          Change Password
        </MenuItem>
      ),
      icon: '',
    },
    {
      name: (
        <MenuItem
          onClick={() => {
            handleLogout();
          }}
        >
          Logout
        </MenuItem>
      ),
      icon: '',
    },
  ];

  return (
    <Box
      sx={{
        height: '7vh',
        borderBottomWidth: '1px',
        backgroundColor: ManageColorTheme(
          globleStyle.layout.light.header,
          globleStyle.layout.dark.header
        ),
        borderBottomColor: '#EFEFEF',
        borderBottomStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
      }}
    >
      <Box>
        {sidebarStatus === 'mini' && (
          <img src={iconLogo} height={30} width={30} />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* <SearchInput /> */}
        {process.env.REACT_APP_THEME_MODE === 'both' && (
          <Box sx={{ marginLeft: 5 }}>
            <IconButton
              onClick={() => {
                onChangeTheme();
                dispatch(changeTheme());
              }}
              color="inherit"
            >
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Box>
        )}

        <Box>
          <SideModel
            button={
              <Box sx={{ borderRadius: '100%' }}>
                <Notification />
              </Box>
            }
            align="right"
            content={
              <NotificationScreen
                status={status}
                loading={loading}
                notification={notification}
                setLimit={() => {
                  setLimit(limit + 10);
                }}
              />
            }
          />
        </Box>
        <Box>
          <DropdownMenu
            button={
              <Box sx={{ borderRadius: '100%' }}>
                <img src={profile} alt="img" />
              </Box>
            }
            data={dropdownData}
          />
        </Box>
      </Box>
    </Box>
  );
}
