/* eslint-disable */

export const Icon = () => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3333 3.66665L9.14992 4.84998L7.82492 3.52498V12.8333H6.17492V3.52498L4.84992 4.84998L3.66659 3.66665L6.99992 0.333313L10.3333 3.66665ZM13.6666 7.83331V17C13.6666 17.9166 12.9166 18.6666 11.9999 18.6666H1.99992C1.07492 18.6666 0.333252 17.9166 0.333252 17V7.83331C0.333252 6.90831 1.07492 6.16665 1.99992 6.16665H4.49992V7.83331H1.99992V17H11.9999V7.83331H9.49992V6.16665H11.9999C12.9166 6.16665 13.6666 6.90831 13.6666 7.83331Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};

export const ToggleIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddd_2140_332232)">
        <circle cx="13.5" cy="11.5" r="10.5" fill="white" />
      </g>
      <path
        d="M16.41 16.59L11.83 12L16.41 7.41L15 6L9.00003 12L15 18L16.41 16.59Z"
        fill="#E1E1E1"
      />
      <defs>
        <filter
          id="filter0_ddd_2140_332232"
          x="0"
          y="0"
          width="27"
          height="27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.298039 0 0 0 0 0.305882 0 0 0 0 0.392157 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2140_332232"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.298039 0 0 0 0 0.305882 0 0 0 0 0.392157 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2140_332232"
            result="effect2_dropShadow_2140_332232"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect3_dropShadow_2140_332232"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.912302 0 0 0 0 0.91581 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_2140_332232"
            result="effect3_dropShadow_2140_332232"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_2140_332232"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 3.33331H3.33341C2.41675 3.33331 1.67508 4.08331 1.67508 4.99998L1.66675 15C1.66675 15.9166 2.41675 16.6666 3.33341 16.6666H16.6667C17.5834 16.6666 18.3334 15.9166 18.3334 15V4.99998C18.3334 4.08331 17.5834 3.33331 16.6667 3.33331ZM16.6667 15H3.33341V6.66665L10.0001 10.8333L16.6667 6.66665V15ZM10.0001 9.16665L3.33341 4.99998H16.6667L10.0001 9.16665Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};

export const Notification = () => {
  return (
    <svg
      width="18"
      height="23"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.9 20 8 20ZM14 14V9C14 5.93 12.37 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.64 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14ZM12 15H4V9C4 6.52 5.51 4.5 8 4.5C10.49 4.5 12 6.52 12 9V15Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};

export const FacebookIcon = () => {
  return (
    <svg
      width="11"
      height="19"
      viewBox="0 0 11 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86554 18.7264V10.5304H9.63054L10.0415 7.3214H6.86554V5.2774C6.86554 4.3514 7.12354 3.7174 8.45254 3.7174H10.1365V0.856398C9.31754 0.768398 8.49354 0.726398 7.66954 0.729398C5.22554 0.729398 3.54754 2.2214 3.54754 4.9604V7.3154H0.800537V10.5244H3.55354V18.7264H6.86554Z"
        fill="#497CE2"
      />
    </svg>
  );
};

export const TwiterIcon = () => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7695 4.72483C17.7825 4.89983 17.7825 5.07383 17.7825 5.24783C17.7825 10.5728 13.7295 16.7088 6.32247 16.7088C4.04047 16.7088 1.92047 16.0478 0.136475 14.8998C0.460475 14.9368 0.772475 14.9498 1.10947 14.9498C2.99247 14.9498 4.72547 14.3138 6.11047 13.2288C4.33947 13.1918 2.85547 12.0318 2.34347 10.4358C2.59247 10.4728 2.84247 10.4978 3.10447 10.4978C3.46547 10.4978 3.82847 10.4478 4.16547 10.3608C2.31847 9.98683 0.935475 8.36583 0.935475 6.40783V6.35783C1.47247 6.65683 2.09547 6.84383 2.75547 6.86883C1.67047 6.14683 0.959475 4.91183 0.959475 3.51483C0.959475 2.76683 1.15847 2.08083 1.50747 1.48283C3.49047 3.92583 6.47147 5.52283 9.81347 5.69783C9.75147 5.39783 9.71347 5.08683 9.71347 4.77483C9.71347 2.55483 11.5095 0.746826 13.7415 0.746826C14.9015 0.746826 15.9485 1.23283 16.6845 2.01883C17.5945 1.84383 18.4665 1.50683 19.2405 1.04583C18.9415 1.98083 18.3045 2.76683 17.4695 3.26583C18.2805 3.17783 19.0665 2.95383 19.7885 2.64183C19.2405 3.43983 18.5555 4.15083 17.7695 4.72483Z"
        fill="#666CFF"
      />
    </svg>
  );
};

export const GithubIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7626 0C5.25357 0 0.788574 4.465 0.788574 9.974C0.788574 14.38 3.64557 18.119 7.60957 19.439C8.10857 19.529 8.28857 19.222 8.28857 18.958C8.28857 18.721 8.28057 18.093 8.27757 17.262C5.50257 17.864 4.91657 15.924 4.91657 15.924C4.46457 14.772 3.80957 14.465 3.80957 14.465C2.90457 13.846 3.87857 13.86 3.87857 13.86C4.88057 13.93 5.40557 14.888 5.40557 14.888C6.29557 16.412 7.74157 15.972 8.30757 15.717C8.39857 15.072 8.65857 14.632 8.94257 14.383C6.72857 14.132 4.40057 13.276 4.40057 9.453C4.40057 8.366 4.78957 7.474 5.42457 6.778C5.32357 6.525 4.97857 5.51 5.52357 4.138C5.52357 4.138 6.36057 3.869 8.26557 5.159C9.06357 4.938 9.91457 4.827 10.7616 4.823C11.6106 4.827 12.4626 4.938 13.2576 5.159C15.1636 3.868 15.9996 4.138 15.9996 4.138C16.5446 5.51 16.2026 6.525 16.0986 6.778C16.7386 7.474 17.1226 8.365 17.1226 9.453C17.1226 13.286 14.7926 14.128 12.5706 14.375C12.9256 14.683 13.2456 15.291 13.2456 16.221C13.2456 17.555 13.2336 18.631 13.2336 18.958C13.2336 19.225 13.4116 19.535 13.9206 19.437C17.8826 18.115 20.7366 14.379 20.7366 9.974C20.7366 4.465 16.2716 0 10.7626 0Z"
        fill="#222222"
      />
    </svg>
  );
};

export const GoogleIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9976 8.10282H9.67057V11.5538H14.4626C14.0166 13.7468 12.1496 15.0068 9.67057 15.0068C6.74757 15.0068 4.39157 12.6508 4.39157 9.72682C4.39157 6.80382 6.74757 4.44782 9.67057 4.44782C10.9296 4.44782 12.0676 4.89482 12.9606 5.62582L15.5606 3.02682C13.9766 1.64582 11.9456 0.793823 9.67057 0.793823C4.71657 0.793823 0.736572 4.77282 0.736572 9.72782C0.736572 14.6828 4.71557 18.6618 9.67057 18.6618C14.1376 18.6618 18.1996 15.4128 18.1996 9.72782C18.1996 9.19982 18.1186 8.63082 17.9976 8.10282Z"
        fill="#DF6A6A"
      />
    </svg>
  );
};

export const BackIcon = () => {
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.225 1.72512L8.75 0.250122L0.5 8.50012L8.75 16.7501L10.225 15.2751L3.45 8.50012L10.225 1.72512Z"
        fill="#666CFF"
      />
    </svg>
  );
};

export const RightAerrow = () => {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33334 0L0.15834 1.175L3.97501 5L0.15834 8.825L1.33334 10L6.33334 5L1.33334 0Z"
        fill="#E1E1E1"
      />
    </svg>
  );
};

export const Previous = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
        fill="#999999"
      />
    </svg>
  );
};

export const PreviousActive = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
        fill="#666CFF"
      />
    </svg>
  );
};

export const Next = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00009 0L0.590088 1.41L5.17009 6L0.590088 10.59L2.00009 12L8.00009 6L2.00009 0Z"
        fill="#999999"
      />
    </svg>
  );
};

export const NextActive = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00009 0L0.590088 1.41L5.17009 6L0.590088 10.59L2.00009 12L8.00009 6L2.00009 0Z"
        fill="#666CFF"
      />
    </svg>
  );
};

export const SortAsce = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.00006L5 1.00006L1 5.00006"
        stroke="#9D9D9D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SortAsceDown = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99997 1L4.99997 5L0.999969 1"
        stroke="#9D9D9D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SortAsceDownTop = () => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66669 4.50002L5.00002 1.16669L8.33335 4.50002M8.33335 9.50002L5.00002 12.8334L1.66669 9.50002"
        stroke="#9D9D9D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ActionIcon = () => {
  return (
    <svg
      width="4"
      height="17"
      viewBox="0 0 4 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.5C3.1 4.5 4 3.6 4 2.5C4 1.4 3.1 0.5 2 0.5C0.9 0.5 0 1.4 0 2.5C0 3.6 0.9 4.5 2 4.5ZM2 6.5C0.9 6.5 0 7.4 0 8.5C0 9.6 0.9 10.5 2 10.5C3.1 10.5 4 9.6 4 8.5C4 7.4 3.1 6.5 2 6.5ZM2 12.5C0.9 12.5 0 13.4 0 14.5C0 15.6 0.9 16.5 2 16.5C3.1 16.5 4 15.6 4 14.5C4 13.4 3.1 12.5 2 12.5Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};

export const DocumentIcon = () => {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1367 0.666016C15.1941 0.666016 15.2503 0.670857 15.3051 0.680152L15.4852 0.681216C15.7572 0.681216 16.0172 0.791882 16.2066 0.987882L22.9612 8.02388C23.1386 8.20922 23.2387 8.45855 23.2387 8.71588V20.9692C23.2599 24.3145 20.6519 27.0332 17.3012 27.1532H6.44791C3.19427 27.0833 0.584844 24.4541 0.504394 21.2335L0.503906 6.65321C0.582573 3.34522 3.31324 0.681216 6.59591 0.681216L14.9683 0.680152C15.0231 0.670857 15.0793 0.666016 15.1367 0.666016ZM14.1359 2.68068L6.59991 2.68122C4.39057 2.68122 2.55591 4.47055 2.50391 6.67722V20.9692C2.45591 23.2372 4.24257 25.1039 6.48924 25.1532H17.2666C19.4919 25.0732 21.2532 23.2372 21.2387 20.9759L21.2386 9.97668L18.5607 9.97801C16.1194 9.97135 14.1367 7.98202 14.1367 5.54468L14.1359 2.68068ZM11.3578 10.2112C11.6338 10.2112 11.8838 10.3232 12.0648 10.5042L12.0662 10.5051L15.1942 13.6464C15.5835 14.0384 15.5822 14.6704 15.1915 15.0597C14.7995 15.4491 14.1662 15.4491 13.7768 15.0571L12.3572 13.6313L12.3578 19.2659C12.3578 19.8179 11.9098 20.2659 11.3578 20.2659C10.8058 20.2659 10.3578 19.8179 10.3578 19.2659L10.3572 13.6313L8.93951 15.0571C8.74484 15.2544 8.48751 15.3517 8.23151 15.3517C7.97684 15.3517 7.72084 15.2544 7.52617 15.0597C7.13551 14.6704 7.13284 14.0384 7.52351 13.6464L10.6488 10.5051L10.6934 10.464C10.7127 10.4468 10.7327 10.4304 10.7533 10.4147L10.6488 10.5051C10.686 10.4678 10.7261 10.4341 10.7684 10.4042C10.933 10.2828 11.1371 10.2112 11.3578 10.2112ZM16.1359 3.80202L16.1367 5.54468C16.1367 6.88335 17.2247 7.97402 18.5634 7.97802L20.1426 7.97668L16.1359 3.80202Z"
        fill="#999999"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49992 0.333374C3.81325 0.333374 0.833252 3.31337 0.833252 7.00004C0.833252 10.6867 3.81325 13.6667 7.49992 13.6667C11.1866 13.6667 14.1666 10.6867 14.1666 7.00004C14.1666 3.31337 11.1866 0.333374 7.49992 0.333374ZM10.8333 9.39337L9.89325 10.3334L7.49992 7.94004L5.10659 10.3334L4.16659 9.39337L6.55992 7.00004L4.16659 4.60671L5.10659 3.66671L7.49992 6.06004L9.89325 3.66671L10.8333 4.60671L8.43992 7.00004L10.8333 9.39337Z"
        fill="white"
      />
    </svg>
  );
};

export const CloseIconRed = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49984 0.666626C3.8915 0.666626 0.166504 4.39163 0.166504 8.99996C0.166504 13.6083 3.8915 17.3333 8.49984 17.3333C13.1082 17.3333 16.8332 13.6083 16.8332 8.99996C16.8332 4.39163 13.1082 0.666626 8.49984 0.666626ZM12.6665 11.9916L11.4915 13.1666L8.49984 10.175L5.50817 13.1666L4.33317 11.9916L7.32484 8.99996L4.33317 6.00829L5.50817 4.83329L8.49984 7.82496L11.4915 4.83329L12.6665 6.00829L9.67484 8.99996L12.6665 11.9916Z"
        fill="#F43F5E"
      />
    </svg>
  );
};
