import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { MultiSelectPropsType } from './type';
import { Tooltip, Typography } from '@mui/material';
import InfoIcon from '../../../assets/Image/Info-black.svg';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  value: string,
  selectedValues: readonly string[],
  theme: Theme
) {
  return {
    fontWeight:
      selectedValues.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props: MultiSelectPropsType) {
  const theme = useTheme();

  const handleChange = (
    event: SelectChangeEvent<typeof props.selectedValues>
  ) => {
    const {
      target: { value },
    } = event;
    props.setSelectedValues(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <FormControl sx={{ marginTop: -1, width: '100%' }}>
        <InputLabel id={`${props.id}-label`}>{props.label}</InputLabel>
        <Select
          labelId={`${props.id}-label`}
          id={props.id}
          multiple={props.type}
          value={props.selectedValues}
          error={props.error}
          onChange={handleChange}
          input={<OutlinedInput id={props.id} label={props.label} />}
          renderValue={selected => {
            return props.type ? (
              <Box
                onClick={e => e.stopPropagation()}
                sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
              >
                {selected.length > 6 ? (
                  <>
                    {selected.slice(0, 6).map(value => (
                      <Chip
                        key={value}
                        label={
                          props.data.find(item => item.value === value)?.label
                        }
                      />
                    ))}
                    <Chip label={`+${selected.length - 6}`} />
                  </>
                ) : (
                  selected.map(value => (
                    <Chip
                      key={value}
                      label={
                        props.data.find(item => item.value === value)?.label
                      }
                    />
                  ))
                )}
              </Box>
            ) : (
              selected.map(value => (
                <Typography key={value}>
                  {props.data.find(item => item.value === value)?.label}
                </Typography>
              ))
            );
          }}
          MenuProps={MenuProps}
        >
          {props.data.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              style={getStyles(item.value, props.selectedValues, theme)}
              sx={item.showInfo ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } : {}}
            >
              {item.label}
              {item.showInfo && (
              <Tooltip title={item.infoMessage} arrow placement='top'>
                <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={InfoIcon} alt="info" />
                </Typography>
              </Tooltip>
              )}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="caption" sx={{ color: '#d32f2f', marginLeft: 2 }}>
          {props.errorMessage}
        </Typography>
      </FormControl>
    </div>
  );
}
