import './assets/Style/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Redux/store';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://f2602ed4d41afa04c3edbd679d1eb02a@o4508437918842881.ingest.us.sentry.io/4508442395344896",
  integrations: [],
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
