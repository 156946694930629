import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { ButtonsType } from './ButtonsType';

export default function Buttons(props: ButtonsType) {
  return (
    <div>
      {props.variant === 'button' && (
        <Button
          type={props.type ? props.type : 'button'}
          sx={{
            ...props.style,
            ':hover': { backgroundColor: props.style?.backgroundColor },
          }}
          variant="contained"
          onClick={() => {
            props.onClick();
          }}
          fullWidth
        >
          {props.iconAlign === 'left' ? (
            props.loading ? (
              <CircularProgress
                color="inherit"
                size={props.iconSize ? props.iconSize : 20}
                sx={{ marginRight: '10px' }}
              />
            ) : (
              props.icon && (
                <div style={{ marginRight: '6px', height: 20, width: 20 }}>
                  {props.icon}
                </div>
              )
            )
          ) : null}{' '}
          {props.label}{' '}
          {props.iconAlign === 'right' ? (
            props.loading ? (
              <CircularProgress
                color="inherit"
                size={props.iconSize ? props.iconSize : 20}
                sx={{ marginLeft: '20px' }}
              />
            ) : (
              props.icon && (
                <div style={{ marginLeft: '6px', height: 20, width: 20 }}>
                  {props.icon}
                </div>
              )
            )
          ) : null}
        </Button>
      )}

      {props.variant === 'text' && (
        <Button
          type={props.type ? props.type : 'button'}
          sx={props.style}
          variant="text"
          onClick={() => {
            props.onClick();
          }}
          fullWidth
        >
          {props.iconAlign === 'left' ? (
            props.loading ? (
              <CircularProgress
                color="inherit"
                size={props.iconSize ? props.iconSize : 20}
                sx={{ marginRight: '10px' }}
              />
            ) : (
              props.icon && (
                <div style={{ marginRight: '6px', height: 20, width: 20 }}>
                  {props.icon}
                </div>
              )
            )
          ) : null}{' '}
          {props.label}{' '}
          {props.iconAlign === 'right' ? (
            props.loading ? (
              <CircularProgress
                color="inherit"
                size={props.iconSize ? props.iconSize : 20}
                sx={{ marginLeft: '20px' }}
              />
            ) : (
              props.icon && (
                <div style={{ marginLeft: '6px', height: 20, width: 20 }}>
                  {props.icon}
                </div>
              )
            )
          ) : null}
        </Button>
      )}

      {props.variant === 'outline' && (
        <Button
          type={props.type ? props.type : 'button'}
          sx={props.style}
          variant="outlined"
          onClick={() => {
            props.onClick();
          }}
          fullWidth
        >
          {props.iconAlign === 'left' ? (
            props.loading ? (
              <CircularProgress
                color="inherit"
                size={props.iconSize ? props.iconSize : 20}
                sx={{ marginRight: '10px' }}
              />
            ) : (
              props.icon && (
                <div style={{ marginRight: '6px', height: 20, width: 20 }}>
                  {props.icon}
                </div>
              )
            )
          ) : null}{' '}
          {props.label}{' '}
          {props.iconAlign === 'right' ? (
            props.loading ? (
              <CircularProgress
                color="inherit"
                size={props.iconSize ? props.iconSize : 20}
                sx={{ marginLeft: '20px' }}
              />
            ) : (
              props.icon && (
                <div style={{ marginLeft: '6px', height: 20, width: 20 }}>
                  {props.icon}
                </div>
              )
            )
          ) : null}
        </Button>
      )}
    </div>
  );
}
