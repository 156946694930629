export const headerSet = (type: boolean) => {
  const token = localStorage.getItem('access_token');
  if (type) {
    return {
      'Content-Type': 'application/text', // Default header
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      'Content-Type': 'application/json', // Default header
      Authorization: `Bearer ${token}`,
    };
  }
};
