import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tables from '../../../../Component/Table';
import { HeaderType, SortType } from '../../../../Component/Table/type';
import GlobleTableRow from '../../../../Component/Table/GlobleTableRow';
import apiService from '../../../../API/APIService';
import { generateQueryString } from '../../../../helper/generateQueryString';
import { CreateFilter } from '../../../../Component/Table/CreateFilter';
import BredCrums from '../../../../Component/Layout/BredCrums';
import ArtTableRow from './TableRow';
import { ArtDataType } from './TableRow/type';
import Models from '../../../../Component/Model';
import ArtDetails from '../ArtDetails';
import { ArtDetailsProps } from '../ArtDetails/type';
import { headerSet } from '../../../../API/Header';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/slices/AuthSlice';
import { roleStoragekey } from '../../../../constant/role';
import { AUTHPATH } from '../../../../Route/Path';
import { useNavigate } from 'react-router-dom';

export default function ArtList() {
  const [rowCount, setRowCount] = useState(10);
  const [sort, setSort] = useState<SortType[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ArtDataType[]>([]);
  const [selectRow, setSelectRow] = useState<string>('');
  const [artDetails, setArtDetails] = useState<ArtDetailsProps | null>(null);
  const [checkData, setCheckData] = useState<ArtDataType[]>([]);
  const [headers, setHeader] = useState<HeaderType[]>([
    {
      label: 'Art Name',
      sort: 'title',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'title__con',
      type: 'text',
      width: '30%',
      option: [],
    },
    {
      label: 'Artist',
      sort: 'created_by_name',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'artist_name',
      type: 'text',
      width: '10%',
      option: [],
    },
    {
      label: 'Category',
      sort: 'category_name',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'category_name',
      type: 'select',
      width: '10%',
      option: [],
    },
    {
      label: 'Sub Category',
      sort: 'subcategory_name',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'sub_category_name',
      type: 'text',
      width: '15%',
      option: [],
    },
    {
      label: 'Display Status',
      sort: 'display_status',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'display_status__in',
      type: 'select',
      width: '10%',
      option: [
        { value: '', label: 'All' },
        { value: '0', label: 'Draft' },
        { value: '1', label: 'Published' },
        { value: '2', label: 'Hide' },
      ],
    },
    {
      label: 'Most Liked',
      sort: 'artLikedCount',
      sortStatus: true,
      search: false,
      value: '',
      filterQuery: '',
      type: '',
      width: '10%',
      option: [],
    },
    {
      label: 'Created Date',
      sort: 'created_at',
      sortStatus: true,
      search: false,
      value: '',
      filterQuery: '',
      type: '',
      width: '10%',
      option: [],
    },
    {
      label: 'Status',
      sort: 'status',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'status__in',
      type: 'select',
      width: '10%',
      option: [
        { value: '', label: 'All' },
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' },
      ],
    },
    {
      label: 'Action',
      sort: 'action',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: '',
      type: '',
      width: '5%',
      option: [],
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCategory = async () => {
    try {
      const data = [{ value: '', label: 'All' }];
      const headerData = [];
      const res = await apiService.get(
        `/v1/api/admin/get-categories`,
        headerSet(false)
      );
      if (res?.status) {
        for (let i = 0; i < res?.data?.rows?.length; i++) {
          const element = res?.data?.rows[i];
          data.push({ value: element?.name, label: element?.name });
        }

        for (let j = 0; j < headers.length; j++) {
          const element = headers[j];
          headerData.push({
            ...element,
            option: element.label === 'Category' ? data : element.option,
          });
        }

        setHeader(headerData);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      console.log('error', error);
      handleLogout();
    }
  };

  useEffect(() => {
    try {
      getCategory();
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  const getData = async () => {
    try {
      const obj = CreateFilter(headers);
      setLoading(true);
      const data: {
        size: number;
        page: number;
        sort?: string; // Mark sort as optional
      } = {
        size: rowCount,
        page: currentPage,
        sort: `${sort[sort.length - 1]?.name}${sort[sort.length - 1]?.status === 1 ? '-' : ''}`,
        ...obj,
      };

      if (sort[sort.length - 1]?.name === undefined) {
        delete data.sort;
      }

      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/get-art-list?${query}`,
        headerSet(false)
      );
      if (res?.status) {
        setData(res?.data?.rows);
        setIsNext(res?.data?.pagination?.hasNextPage);
        setTotalData(res?.data?.rows?.length);
        setTotalData(res?.data?.pagination?.count);
        setCheckData([]);
        setLoading(false);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      console.log(error);
      handleLogout();
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, rowCount, sort, headers]);

  const previousPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(totalData / rowCount) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const rowChecked = (check: ArtDataType[]) => {
    if (check?.length !== data?.length) {
      const checked = checkData?.filter(val => val.id === check[0].id);
      const removeCheck = checkData?.filter(val => val.id !== check[0].id);
      if (checked?.length === 1) {
        setCheckData(removeCheck);
      } else {
        setCheckData([...checkData, ...check]);
      }
    } else {
      if (check?.length === checkData?.length) {
        setCheckData([]);
      } else {
        setCheckData(check);
      }
    }
  };

  const getArtDetails = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/get-art-detail?art_id=${selectRow}`,
        headerSet(false)
      );
      if (res.status) {
        setArtDetails(res.data);
      }
    } catch (error) {
      console.log('error', error);
      handleLogout();
    }
  };

  useEffect(() => {
    if (selectRow) {
      getArtDetails();
    }
  }, [selectRow]);

  return (
    <>
      <BredCrums
        heading="Art Works"
        path={[
          { name: 'Dashboard', path: '/dashboard' },
          { name: 'Art Works', path: '' },
        ]}
        description="Find all of your seller art."
        action={
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {/* <Buttons
              type="button"
              label="ADD USER"
              style={{ marginRight: 2, marginTop: 2 }}
              onClick={() => {
                navigate(PATH.userCreate);
              }}
              loading={false}
              variant="button"
              iconAlign="left"
            /> */}
            {/* <Buttons
              type="button"
              label="EXPORT"
              style={{ marginTop: 2 }}
              onClick={() => {}}
              loading={false}
              variant="outline"
              icon={<Icon />}
              iconAlign="left"
            /> */}
          </Box>
        }
      />
      <Box sx={{ marginTop: '3%' }}>
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: 2,
          }}
        >
          <Buttons label="Filter" onClick={() => {}} variant="button" />
        </Box> */}
        <Box>
          <Tables
            checkbox={false}
            height={570}
            headers={headers}
            loading={loading}
            sort={sort.length > 1 ? [sort[sort.length - 1]] : sort}
            rowCount={rowCount}
            currentPage={currentPage}
            totalData={totalData}
            checkData={checkData}
            data={data}
            isNext={isNext}
            limit={[10, 20, 30, 40]}
            handleChangeRowCount={(value: number) => {
              setRowCount(value);
              setCurrentPage(0);
            }}
            setSort={(value: SortType[]) => {
              setSort(value);
            }}
            setCheckAll={() => {
              rowChecked([...data]);
            }}
            setHeader={(val: HeaderType[]) => {
              setHeader(val);
              setCurrentPage(0);
            }}
            previousPage={() => {
              previousPage();
            }}
            getData={() => {
              getData();
            }}
            nextPage={() => {
              nextPage();
            }}
            row={data.map((val: ArtDataType, i) => {
              return (
                <GlobleTableRow
                  setCheck={(val: ArtDataType) => {
                    rowChecked([val]);
                  }}
                  rowClick={(val: string) => {
                    setSelectRow(val);
                  }}
                  checkData={checkData}
                  val={val}
                  checkbox={false}
                  key={i}
                  row={
                    <ArtTableRow
                      getData={() => {
                        getData();
                      }}
                      data={val}
                    />
                  }
                />
              );
            })}
          />
        </Box>
      </Box>
      <Models
        open={selectRow ? true : false}
        handleOpen={() => {
          setSelectRow('');
          setArtDetails(null);
        }}
        handleClose={() => {
          setSelectRow('');
          setArtDetails(null);
        }}
        button={<div></div>}
        content={
          artDetails ? (
            <ArtDetails
              onClose={() => {
                setSelectRow('');
                setArtDetails(null);
              }}
              data={artDetails}
            />
          ) : null
        }
      />
    </>
  );
}
