import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Grid, TextField } from '@mui/material';
import Buttons from '../../../../Component/Buttons';

import { useLocation, useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from '../../../../API/encrypt';
import apiService from '../../../../API/APIService';
import { headerSet } from '../../../../API/Header';
import { Notification } from '../../../../helper/Notification';
import { AUTHPATH, PATH } from '../../../../Route/Path';
import { generateQueryString } from '../../../../helper/generateQueryString';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/slices/AuthSlice';
import { roleStoragekey } from '../../../../constant/role';

interface IFormInput {
  firstName: string;
  value: string;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Setting name is required'),
  value: Yup.string().required('Value is required'),
});

export default function Form(props: { isEdit: boolean }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const splitPath = location.pathname.split('/');

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
  });

  const values = watch();

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getData = async (key: string) => {
    try {
      const data = {
        id__in: key,
      };
      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/setting-list?${query}`,
        headerSet(false)
      );
      if (res.status) {
        setValue('firstName', res?.data?.rows?.[0]?.title);
        setValue('value', res.data.rows?.[0]?.setting_value);
      }
    } catch (error) {
      console.log('error', error);
      handleLogout();
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      getData(splitPath[2]);
    }
  }, [location, props]);

  const addData = async (data: IFormInput) => {
    setLoading(true);
    try {
      const finalData = {
        title: data.firstName,
        setting_value: data.value,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.post(
        '/v1/api/admin/setting-create',
        secret_text,
        headerSet(false)
      );
      if (res.status) {
        setLoading(true);
        Notification('success', res.message);
        navigate(PATH.setting);
      } else {
        setLoading(false);
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  const EditData = async (data: IFormInput) => {
    setLoading(true);
    try {
      const finalData = {
        setting_id: splitPath[2],
        title: data.firstName,
        setting_value: data.value,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.put(
        '/v1/api/admin/setting-update',
        secret_text,
        headerSet(false)
      );
      if (res.status) {
        setLoading(true);
        Notification('success', res.message);
        navigate(PATH.setting);
      } else {
        setLoading(false);
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (data?.value) {
      if (props.isEdit) {
        EditData(data);
      } else {
        addData(data);
      }
    } else {
      Notification('error', 'Please Enter Valid Value');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            label="Setting Name"
            variant="outlined"
            type="text"
            value={values.firstName}
            fullWidth
            {...register('firstName')}
            sx={{ marginBottom: 2 }}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            InputLabelProps={{ shrink: !!values.firstName || undefined }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Value"
            variant="outlined"
            type="text"
            value={values.value}
            fullWidth
            {...register('value')}
            sx={{ marginBottom: 2 }}
            error={!!errors.value}
            helperText={errors.value?.message}
            InputLabelProps={{ shrink: !!values.value || undefined }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Buttons
          type="submit"
          label={props.isEdit ? 'Save' : 'Create'}
          iconAlign="left"
          loading={loading}
          variant="button"
          onClick={() => {}}
        />
        <Buttons
          type="button"
          iconAlign="left"
          label="Cancel"
          variant="outline"
          style={{ marginLeft: 2 }}
          onClick={() => {
            navigate('/setting');
          }}
        />
      </Box>
    </Box>
  );
}
