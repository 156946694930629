import React from 'react';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { Box } from '@mui/material';
import { PATH } from '../../../../Route/Path';
import Form from '../Form';

export default function CreateCountry() {
  return (
    <div>
      <BredCrums
        heading="Add Country"
        path={[
          { name: 'Dashboard', path: PATH.home },
          { name: 'Countries', path: '/master' + PATH.CountryList },
          { name: 'Add Country', path: '' },
        ]}
        description="Add new country."
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />
      <Box sx={{ marginTop: '3%' }}>
        <Form isEdit={false} />
      </Box>
    </div>
  );
}
