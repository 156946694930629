import * as React from 'react';
import { DropDownPropsType } from './type';
import { Box, Button, Menu } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export default function DropdownMenu(props: DropDownPropsType) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          backgroundColor: 'transparent',
          color: 'black',
          ':hover': { color: 'black' },
        }}
      >
        {props.button}
        {props.icon ? open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> : null}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {props?.data?.map((val, i) => {
          return <Box key={i}>{val.name}</Box>;
        })}
      </Menu>
    </div>
  );
}
