/* eslint-disable */

export function generateQueryString(params: any) {
  // Check if params is an object
  if (typeof params !== 'object' || params === null) {
    throw new Error('Params must be an object');
  }

  // Initialize an array to store key-value pairs
  const queryParts = [];

  // Iterate over each key-value pair in the params object
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      // Encode key and value and add them to the array
      queryParts.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  }

  // Join the array into a single string with '&' as a separator
  return queryParts.join('&');
}
