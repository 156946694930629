import React, { CSSProperties } from 'react';
import { S3_URL } from '../../config';
import { Typography } from '@mui/material';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';

interface ImageWithNamePropsType {
  imageURL: string;
  imageStyle: CSSProperties;
  alt: string;
}

export default function ImageWithName(props: ImageWithNamePropsType) {
  return (
    <div>
      {props.imageURL ? (
        <img
          src={S3_URL + props.imageURL}
          style={{ ...props.imageStyle, objectFit: 'cover' }}
        />
      ) : (
        <Typography
          sx={{
            ...props.imageStyle,
            textTransform: 'capitalize',
            backgroundColor: ManageColorTheme(
              globleStyle.layout.light.activeColor,
              globleStyle.layout.dark.activeColor
            ),
            fontSize: 28,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
          }}
        >
          {props.alt[0]}
        </Typography>
      )}
    </div>
  );
}
