import { Box, TableCell, Typography } from '@mui/material';
import { ArtStylePropsType } from './type';
import moment from 'moment';
import {
  PaymentMethod,
  PaymentStatus,
} from '../../../../../constant/StaticData';

export default function PaymentTableRow(props: ArtStylePropsType) {
  return (
    <>
      <TableCell>
        <Typography variant="body1">{props?.data?.order_no}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.payment_intent_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.userInfo?.profile?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.artistInfo?.profile?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.orderInfo?.buyer_total_amount}
        </Typography>
      </TableCell>
      {/* <TableCell>
        <Typography variant="body1">
          {props?.data?.orderInfo?.seller_total_amount}
        </Typography>
      </TableCell> */}
      <TableCell>
        <Box>
          {moment(props.data.created_at).format('MMM Do YYYY')}
          <br />
          <Typography variant="caption">
            {moment(props.data.created_at).format('hh:mm A')}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              backgroundColor:
                PaymentStatus(props?.data?.payment_status) === 'Pending'
                  ? 'rgb(251, 140, 0)'
                  : PaymentStatus(props?.data?.payment_status) === 'Success'
                    ? 'green'
                    : 'red',
              borderRadius: 100,
              marginRight: 1,
            }}
          ></Box>
          <Typography>{PaymentStatus(props?.data?.payment_status)}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {PaymentMethod(props?.data?.payment_method)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{props?.data?.card_type}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{props?.data?.currency?.toUpperCase()}</Typography>
      </TableCell>
    </>
  );
}
