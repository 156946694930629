import { Box, Collapse, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ManageColorTheme } from '../../../../helper/ChangeTheme';
import { globleStyle } from '../../../../assets/Style/globleStyle';
import { SidebarIremPropsType } from '../type';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function SidebarItem(props: SidebarIremPropsType) {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState<string[]>([]);

  useEffect(() => {
    const activePath = location.pathname ? location.pathname.split('/') : [];
    if (path !== activePath) setPath(activePath);
  }, [location]);

  return (
    <Box>
      {props.val.children?.length > 0 ? (
        <Box>
          <Box
            onClick={() => {
              props.handleToggle(props.val.path);
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '5%',
              cursor: 'pointer',
              color: props.status
                ? ManageColorTheme(
                    globleStyle.layout.light.sidebar.textHover,
                    globleStyle.layout.dark.sidebar.textHover
                  )
                : ManageColorTheme(
                    globleStyle.layout.light.sidebar.textActive,
                    globleStyle.layout.dark.sidebar.textActive
                  ),
              backgroundColor: props.status
                ? ManageColorTheme(
                    globleStyle.layout.light.sidebar.hover,
                    globleStyle.layout.dark.sidebar.hover
                  )
                : '',
              ':hover': {
                color: ManageColorTheme(
                  globleStyle.layout.light.sidebar.textHover,
                  globleStyle.layout.dark.sidebar.textHover
                ),
                backgroundColor: ManageColorTheme(
                  globleStyle.layout.light.sidebar.hover,
                  globleStyle.layout.dark.sidebar.hover
                ),
                '& .MuiTypography-root': {
                  color: ManageColorTheme(
                    globleStyle.layout.light.sidebar.textHover,
                    globleStyle.layout.dark.sidebar.textHover
                  ), // Change text color to white on hover
                },
              },
            }}
          >
            {props.val.icon}
            <Typography
              variant="body2"
              sx={{
                color: props.status
                  ? ManageColorTheme(
                      globleStyle.layout.light.sidebar.textHover,
                      globleStyle.layout.dark.sidebar.textHover
                    )
                  : ManageColorTheme(
                      globleStyle.layout.light.sidebar.textActive,
                      globleStyle.layout.dark.sidebar.textActive
                    ),
                marginLeft: '5%',
                cursor: 'pointer',
                paddingTop: '5%',
                paddingBottom: '5%',
                width: '100%',
              }}
            >
              {props.val.name}
            </Typography>
            <Box sx={{ marginRight: 3 }}>
              {props.status ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Box>
          </Box>

          <Collapse
            sx={{ paddingLeft: '15%', marginRight: '10%' }}
            in={props.status}
          >
            {props.val.children?.map((val, i) => {
              return (
                <Box
                  onClick={() => {
                    navigate(`${props.val.path}${val.path}`);
                  }}
                  key={i}
                  sx={{
                    padding: '5%',
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    borderRadius: '4px',
                    boxShadow:
                      `/${path[2]}` === val.path
                        ? '0px 2px 3px 0px #4C4E641F'
                        : '',
                    backgroundColor: `/${path[2]}` === val.path ? 'white' : '',
                    ':hover': {
                      backgroundColor: 'white',
                      boxShadow: '0px 2px 3px 0px #4C4E641F',
                      '& .MuiTypography-root': {
                        color: ManageColorTheme(
                          globleStyle.layout.light.activeColor,
                          globleStyle.layout.dark.activeColor
                        ),
                      },
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        `/${path[2]}` === val.path
                          ? ManageColorTheme(
                              globleStyle.layout.light.activeColor,
                              globleStyle.layout.dark.activeColor
                            )
                          : ManageColorTheme('#000000', '#ffffff'),
                      marginLeft: '5%',
                      cursor: 'pointer',
                      fontWeight: '600',
                      fontSize: '14px',
                      ':hover': {
                        color: ManageColorTheme(
                          globleStyle.layout.light.activeColor,
                          globleStyle.layout.dark.activeColor
                        ),
                      },
                    }}
                  >
                    {val.name}
                  </Typography>
                </Box>
              );
            })}
          </Collapse>
        </Box>
      ) : (
        <Box
          onClick={() => {
            props.handleToggle(props.val.path);
            navigate(props.val.path);
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '5%',
            cursor: 'pointer',
            backgroundColor: props.status
              ? ManageColorTheme(
                  globleStyle.layout.light.sidebar.hover,
                  globleStyle.layout.dark.sidebar.hover
                )
              : '',
            color: props.status
              ? ManageColorTheme(
                  globleStyle.layout.light.sidebar.textHover,
                  globleStyle.layout.dark.sidebar.textHover
                )
              : ManageColorTheme(
                  globleStyle.layout.light.sidebar.textActive,
                  globleStyle.layout.dark.sidebar.textActive
                ),
            ':hover': {
              color: ManageColorTheme(
                globleStyle.layout.light.sidebar.textHover,
                globleStyle.layout.dark.sidebar.textHover
              ),
              backgroundColor: ManageColorTheme(
                globleStyle.layout.light.sidebar.hover,
                globleStyle.layout.dark.sidebar.hover
              ),
              '& .MuiTypography-root': {
                color: ManageColorTheme(
                  globleStyle.layout.light.sidebar.textHover,
                  globleStyle.layout.dark.sidebar.textHover
                ), // Change text color to white on hover
              },
            },
          }}
        >
          {props.val.icon}
          <Typography
            variant="body2"
            sx={{
              color: props.status
                ? ManageColorTheme(
                    globleStyle.layout.light.sidebar.textHover,
                    globleStyle.layout.dark.sidebar.textHover
                  )
                : ManageColorTheme(
                    globleStyle.layout.light.sidebar.textActive,
                    globleStyle.layout.dark.sidebar.textActive
                  ),
              marginLeft: '5%',
              paddingTop: '5%',
              cursor: 'pointer',
              paddingBottom: '5%',
              width: '100%',
              '&:hover': {
                color: ManageColorTheme(
                  globleStyle.layout.light.sidebar.textHover,
                  globleStyle.layout.dark.sidebar.textHover
                ),
              },
            }}
          >
            {props.val.name}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
