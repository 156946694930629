import { Box, MenuItem, TableCell, Typography } from '@mui/material';
import { ArtImage, ArtTablePropsType } from './type';
import ImageWithName from '../../../../../Component/ImageWithName';
import moment from 'moment';
import { SwiperSlide } from 'swiper/react';
import FadeSlider from '../../../../../Component/Slider';
import DropdownMenu from '../../../../../Component/Dropdown';
import { Notification } from '../../../../../helper/Notification';
import apiService from '../../../../../API/APIService';
import { decryptData, encryptData } from '../../../../../API/encrypt';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { headerSet } from '../../../../../API/Header';
import {
  ArtStatus,
  UserStatus,
  copyType,
  isFrame,
} from '../../../../../constant/StaticData';
import Status from '../../../../../Component/Status';
import Models from '../../../../../Component/Model';
import DeleteModel from '../../../../../Component/Table/DeleteModel';
import ActiveModel from '../../../../../Component/Table/ActiveModel';
import { ManageColorTheme } from '../../../../../helper/ChangeTheme';

export default function ArtTableRow(props: ArtTablePropsType) {
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await apiService.delete(
        `/v1/api/admin/delete-art?art_id=${props?.data?.art_id}`,
        headerSet(false)
      );
      if (res.status) {
        setLoading(false);
        handleClose();
        props.getData();
        Notification('success', res.message);
      } else {
        setLoading(false);
        handleClose();
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
      handleClose();
    }
  };

  const handleChangeStatus = async () => {
    try {
      if (role) {
        const finalData = {
          art_id: props.data.art_id,
          status: role === 'active' ? 1 : 0, // 0 => Inactive 1 => Active
        };
        const incriptData = (await encryptData(finalData)) as string;
        const secret_text = {
          secret_text: incriptData,
        };
        const res = await apiService.put(
          '/v1/api/admin/update-art-status',
          secret_text
        );
        if (res.status) {
          setRole('');
          Notification('success', res.message);
          props.getData();
        } else {
          setRole('');
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setRole('');
    }
  };

  const dropdownDataActive = [
    {
      name: (
        <Models
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          button={
            <MenuItem sx={{ color: 'rgb(211, 47, 47)' }} onClick={() => {}}>
              <DeleteIcon color="error" sx={{ marginRight: 1 }} /> Delete
            </MenuItem>
          }
          content={
            <DeleteModel
              handleDelete={handleDelete}
              description="You want to delete Art"
              handleClose={handleClose}
              loading={loading}
              id={props?.data?.id}
            />
          }
        />
      ),
      icon: <DeleteIcon />,
    },
  ];

  return (
    <>
      <TableCell sx={{ cursor: 'pointer' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: 50, height: 50 }}>
            {props?.data?.thumbnail_image ? (
              <ImageWithName
                imageURL={props?.data?.thumbnail_image}
                imageStyle={{ width: 50, height: 50, borderRadius: 4 }}
                alt={props.data.title}
              />
            ) : (
              <FadeSlider
                slide={
                  <>
                    {props?.data?.artImages?.map((val: ArtImage, i: number) => {
                      return (
                        <SwiperSlide key={i}>
                          <ImageWithName
                            imageURL={val.image}
                            imageStyle={{
                              width: 50,
                              height: 50,
                              borderRadius: 4,
                            }}
                            alt={props.data.title}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </>
                }
              />
            )}
          </Box>

          <Box sx={{ marginLeft: 2 }}>
            <Typography variant="body1">{props.data.title}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="caption" sx={{ marginRight: 2 }}>
                {copyType(props?.data?.frame_type)}
              </Typography>
              {copyType(props?.data?.frame_type) === 'Hard Copy' && (
                <Typography variant="caption">
                  {isFrame(props?.data?.is_frame)}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {props?.data?.userInfo?.profile?.name}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {props.data.category_name}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {props.data.subcategory_name}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {ArtStatus(props?.data?.display_status)}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {props.data.artLikedCount}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {moment(props.data.created_at).format('MMM Do YYYY')}
      </TableCell>
      <TableCell
        sx={{ cursor: 'pointer' }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Models
          open={role ? true : false}
          handleOpen={() => {
            setRole(props?.data?.status === 1 ? 'inActive' : 'active');
          }}
          handleClose={() => {
            setRole('');
            props.getData();
          }}
          button={
            <Status
              label={`${UserStatus(props?.data?.status)}`}
              status={
                UserStatus(props?.data?.status) === 'Active' ? true : false
              }
            />
          }
          content={
            <ActiveModel
              handleDelete={handleChangeStatus}
              description="Are you sure you want to change the status"
              handleClose={() => {
                setRole('');
                props.getData();
              }}
              label={props?.data?.status === 1 ? 'Yes' : 'Yes'}
              loading={loading}
              id={props?.data?.id}
            />
          }
        />
      </TableCell>
      <TableCell
        sx={{ cursor: 'pointer' }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DropdownMenu
          button={
            <Box sx={{ color: ManageColorTheme('#000000', '#ffffff') }}>
              <MoreVertIcon />
            </Box>
          }
          data={dropdownDataActive}
          icon={false}
        />
      </TableCell>
    </>
  );
}
