import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tables from '../../../../Component/Table';
import { HeaderType, SortType } from '../../../../Component/Table/type';
// import Buttons from '../../../../Component/Buttons';
import GlobleTableRow from '../../../../Component/Table/GlobleTableRow';
import apiService from '../../../../API/APIService';
import { generateQueryString } from '../../../../helper/generateQueryString';
import { CreateFilter } from '../../../../Component/Table/CreateFilter';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { OrderDataType } from './TableRow/type';
import Models from '../../../../Component/Model';
import OrderDataTypeTableRow from './TableRow';
import { useLocation, useNavigate } from 'react-router-dom';
import OrderDetails from '../OrderDetails';
import { headerSet } from '../../../../API/Header';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/slices/AuthSlice';
import { roleStoragekey } from '../../../../constant/role';
import { AUTHPATH } from '../../../../Route/Path';
// import { Icon } from '../../../../assets/Image/Icon';
// import { useNavigate } from 'react-router-dom';
// import { PATH } from '../../../../Route/Path';

export default function OrderDataTypeItemList() {
  const [rowCount, setRowCount] = useState(10);
  const [sort, setSort] = useState<SortType[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrderDataType[]>([]);
  const [selectRow, setSelectRow] = useState<string>('');
  const [artDetails, setArtDetails] = useState<OrderDataType | null>(null);
  // const navigate = useNavigate();
  const location = useLocation();
  const splitPath = location.pathname.split('/');
  const [checkData, setCheckData] = useState<OrderDataType[]>([]);
  const [headers, setHeader] = useState<HeaderType[]>([
    {
      label: 'Order Item ID',
      sort: 'order_no',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'order_art_no__con',
      type: '',
      width: '10%',
      option: [],
    },
    {
      label: 'Art Name',
      sort: 'art_name',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: 'art_name',
      type: '',
      width: '30%',
      option: [],
    },
    {
      label: 'Category',
      sort: 'category_name',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: 'category_name',
      type: '',
      width: '10%',
      option: [],
    },
    {
      label: 'Sub Category',
      sort: 'subcategory_name',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: 'sub_category_name',
      type: '',
      width: '10%',
      option: [],
    },
    {
      label: 'Art Style',
      sort: '',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: 'art_style_name',
      type: 'text',
      width: '10%',
      option: [],
    },
    {
      label: 'Material',
      sort: 'material',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: 'material',
      type: '',
      width: '10%',
      option: [],
    },
    // {
    //   label: 'buyer',
    //   sort: 'buyer_name',
    //   sortStatus: false,
    //   search: false,
    //   value: '',
    //   filterQuery: 'buyer_name',
    //   type: '',
    //   width: '15%',
    //   option: [],
    // },
    {
      label: 'Buyer Amount',
      sort: 'amount',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: 'status__in',
      type: '',
      width: '5%',
      option: [],
    },
    {
      label: 'Seller Amount',
      sort: 'amount',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: 'status__in',
      type: '',
      width: '5%',
      option: [],
    },
    // {
    //   label: 'Order status',
    //   sort: 'Order_status',
    //   sortStatus: false,
    //   search: false,
    //   value: '',
    //   filterQuery: 'Order_status',
    //   type: '',
    //   width: '10%',
    //   option: [],
    // },
    // {
    //   label: 'Order date',
    //   sort: 'created_at',
    //   sortStatus: false,
    //   search: false,
    //   value: '',
    //   filterQuery: 'created_at',
    //   type: '',
    //   width: '10%',
    //   option: [],
    // },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getData = async () => {
    try {
      const obj = CreateFilter(headers);
      setLoading(true);
      const data: {
        size: number;
        page: number;
        order_id__in: string;
        sort?: string; // Mark sort as optional
      } = {
        size: rowCount,
        page: currentPage,
        order_id__in: splitPath[3],
        sort: `${sort[sort.length - 1]?.name}${sort[sort.length - 1]?.status === 1 ? '-' : ''}`,
        ...obj,
      };

      if (sort[sort.length - 1]?.name === undefined) {
        delete data.sort;
      }

      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/get-order-item-list?${query}`,
        headerSet(false)
      );
      if (res?.status) {
        setData(res?.data?.rows);
        setIsNext(res?.data?.pagination?.hasNextPage);
        setTotalData(res?.data?.rows?.length);
        setTotalData(res?.data?.pagination?.count);
        setCheckData([]);
        setLoading(false);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      handleLogout();
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, rowCount, sort, headers]);

  const previousPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(totalData / rowCount) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const rowChecked = (check: OrderDataType[]) => {
    if (check?.length !== data?.length) {
      const checked = checkData?.filter(val => val.id === check[0].id);
      const removeCheck = checkData?.filter(val => val.id !== check[0].id);
      if (checked?.length === 1) {
        setCheckData(removeCheck);
      } else {
        setCheckData([...checkData, ...check]);
      }
    } else {
      if (check?.length === checkData?.length) {
        setCheckData([]);
      } else {
        setCheckData(check);
      }
    }
  };

  useEffect(() => {
    if (selectRow) {
      const filterData = data?.filter(val => val.id === selectRow);
      if (filterData?.length > 0) {
        setArtDetails(filterData[0]);
      } else {
        setArtDetails(null);
      }
    }
  }, [selectRow]);

  return (
    <>
      <BredCrums
        heading="Order Details"
        path={[
          { name: 'Dashboard', path: '/dashboard' },
          { name: 'Orders', path: '/sales/order' },
          { name: 'Order Details', path: '' },
        ]}
        description="Find all of your Order Details."
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />
      <Box sx={{ marginTop: '3%' }}>
        <Box>
          <Tables
            checkbox={false}
            height={570}
            headers={headers}
            loading={loading}
            sort={sort.length > 1 ? [sort[sort.length - 1]] : sort}
            rowCount={rowCount}
            currentPage={currentPage}
            totalData={totalData}
            checkData={checkData}
            data={data}
            isNext={isNext}
            limit={[10, 20, 30, 40]}
            handleChangeRowCount={(value: number) => {
              setRowCount(value);
              setCurrentPage(0);
            }}
            setSort={(value: SortType[]) => {
              setSort(value);
            }}
            setCheckAll={() => {
              rowChecked([...data]);
            }}
            setHeader={(val: HeaderType[]) => {
              setHeader(val);
              setCurrentPage(0);
            }}
            previousPage={() => {
              previousPage();
            }}
            getData={() => {
              getData();
            }}
            nextPage={() => {
              nextPage();
            }}
            row={data.map((val: OrderDataType, i) => {
              return (
                <GlobleTableRow
                  setCheck={(val: OrderDataType) => {
                    rowChecked([val]);
                  }}
                  rowClick={(val: string) => {
                    setSelectRow(val);
                  }}
                  checkData={checkData}
                  val={val}
                  checkbox={false}
                  key={i}
                  row={
                    <OrderDataTypeTableRow
                      getData={() => {
                        getData();
                      }}
                      data={val}
                    />
                  }
                />
              );
            })}
          />
        </Box>
      </Box>
      <Models
        open={selectRow ? true : false}
        handleOpen={() => {
          setSelectRow('');
          setArtDetails(null);
        }}
        handleClose={() => {
          setSelectRow('');
          setArtDetails(null);
        }}
        button={<div></div>}
        content={
          artDetails ? (
            <OrderDetails
              onClose={() => {
                setSelectRow('');
                setArtDetails(null);
              }}
              data={artDetails}
            />
          ) : null
        }
      />
    </>
  );
}
