import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { userDataType } from './TableRow/type';
import Buttons from '../../../../Component/Buttons';
import apiService from '../../../../API/APIService';
import { generateQueryString } from '../../../../helper/generateQueryString';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { useNavigate } from 'react-router-dom';
import { AUTHPATH, PATH } from '../../../../Route/Path';
import UserTableRow from './TableRow';
import { headerSet } from '../../../../API/Header';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/slices/AuthSlice';
import { roleStoragekey } from '../../../../constant/role';

export default function CMSList() {
  const [rowCount, setRowCount] = useState(10);
  const [isNext, setIsNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<userDataType[]>([]);
  const [openSlice, setOpenSlice] = useState('');
  const navigate = useNavigate();

  console.log('isNext', isNext);
  console.log('loading', loading);

  useEffect(() => {
    setRowCount(20);
  }, []);

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const data: {
        size: number;
        page: number;
      } = {
        size: rowCount,
        page: 0,
      };

      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/cms-list?${query}&attributes=id|title|cms_key|content|image`,
        headerSet(false)
      );
      if (res?.status) {
        setData(res?.data?.rows);
        setOpenSlice(res?.data?.rows?.[0]?.cms_key);
        setIsNext(res?.data?.pagination?.hasNextPage);
        setLoading(false);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      handleLogout();
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [rowCount]);

  return (
    <>
      <BredCrums
        heading="CMS"
        path={[
          { name: 'Dashboard', path: '/dashboard' },
          { name: 'CMS', path: '' },
        ]}
        description="Find all of your company’s CMS"
        action={
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Buttons
              type="button"
              label="Create CMS"
              style={{ marginRight: 2, marginTop: 2 }}
              onClick={() => {
                navigate(PATH.cmsCreate);
              }}
              loading={false}
              variant="button"
              iconAlign="left"
            />
          </Box>
        }
      />
      <Box sx={{ marginTop: '3%' }}>
        <Box>
          {data?.map((val: userDataType, i: number) => {
            return (
              <UserTableRow
                setOpenSlice={(val: string) => {
                  if (openSlice === val) {
                    setOpenSlice('');
                  } else {
                    setOpenSlice(val);
                  }
                }}
                openSlice={openSlice}
                key={i}
                data={val}
                getData={() => {
                  getData();
                }}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
}
