import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  styled,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
} from '@mui/material';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';

// Custom StepConnector for customizing the connector colors
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.grey[200], // Default connector color
    borderWidth: 4,
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderColor: ManageColorTheme(
      globleStyle.layout.light.activeColor,
      globleStyle.layout.dark.activeColor
    ),
    borderWidth: 4, // Connector color for active steps
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderColor: ManageColorTheme(
      globleStyle.layout.light.activeColor,
      globleStyle.layout.dark.activeColor
    ),
    borderWidth: 4, // Connector color for completed steps
  },
}));

interface OrderDataType {
  label: string;
  description: string;
  date: string;
}

interface OrderStatusProps {
  activeStep: number; // Step number to control the active status
  stepColors: string[]; // Optional: Custom colors for the steps
  steps: OrderDataType[]; // Optional: Custom steps
}

export const OrderStatusStepper: React.FC<OrderStatusProps> = ({
  activeStep,
  stepColors,
  steps,
}) => {
  // Detect screen size for responsive behavior
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Change layout for small screens

  return (
    <Box sx={{ width: '100%', p: 0, m: 0 }}>
      {' '}
      {/* Remove padding and margin */}
      <Stepper
        activeStep={activeStep}
        alternativeLabel={!isSmallScreen} // Horizontal on larger screens, vertical on smaller
        orientation={isSmallScreen ? 'vertical' : 'horizontal'} // Change orientation based on screen size
        connector={<CustomConnector />}
        sx={{
          width: '100%', // Ensure the stepper fills the width
          px: 0, // Remove padding on the sides
          mx: 0, // Remove margin on the sides
        }}
      >
        {steps.map((step, index) => (
          <Step
            key={step.label}
            completed={activeStep > index}
            sx={{ width: '100%', px: 0 }} // Ensure each step takes up full width
          >
            <StepLabel
              StepIconProps={{
                sx: {
                  color: '#fff', // Icon text color (keep it white for visibility)
                  backgroundColor:
                    activeStep >= index
                      ? stepColors[index]
                      : theme.palette.grey[200], // Set the background color for each step
                  borderRadius: '50%', // Ensure the circle shape
                  padding: 1.2, // Add padding for better visibility
                  border: '2px solid', // Add border for the step point
                  borderColor:
                    activeStep >= index
                      ? stepColors[index]
                      : theme.palette.grey[200], // Border matches the status point color
                },
              }}
            >
              <Box textAlign="center" sx={{ maxWidth: '100%' }}>
                {' '}
                {/* Ensure content is centered and full width */}
                <Typography variant="subtitle1" fontWeight="bold">
                  {step.label}
                </Typography>
                <Typography variant="body2">{step.description}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {step.date}
                </Typography>
              </Box>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
