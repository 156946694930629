import { PATH } from '../../../Route/Path';
import { EmailIcon } from '../../../assets/Image/Icon';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SecurityIcon from '@mui/icons-material/Security';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BallotIcon from '@mui/icons-material/Ballot';

export const sidebarData = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon />,
    children: [],
  },
  {
    name: 'Users',
    path: '/users',
    icon: <SupervisedUserCircleIcon />,
    children: [
      {
        name: 'Buyers',
        path: PATH.userList,
        icon: <EmailIcon />,
        children: [],
      },
      {
        name: 'Sellers',
        path: PATH.seller,
        icon: <EmailIcon />,
        children: [],
      },
      {
        name: 'Buyers & Sellers',
        path: PATH.buyerAndSeller,
        icon: <EmailIcon />,
        children: [],
      },
      {
        name: 'Seller Requests',
        path: PATH.SellerRequest,
        icon: <EmailIcon />,
        children: [],
      },
    ],
  },

  {
    name: 'CMS',
    path: '/cms',
    icon: <SecurityIcon />,
    children: [],
  },
  {
    name: 'Art Works',
    path: '/art',
    icon: <CategoryIcon />,
    children: [],
  },
  {
    name: 'Master',
    path: '/master',
    icon: <BallotIcon />,
    children: [
      {
        name: 'Countries',
        path: PATH.CountryList,
        icon: <EmailIcon />,
        children: [],
      },
      {
        name: 'Categories',
        path: PATH.CatogaryList,
        icon: <EmailIcon />,
        children: [],
      },
      {
        name: 'Art Styles',
        path: PATH.ArtStyleList,
        icon: <EmailIcon />,
        children: [],
      },
    ],
  },
  {
    name: 'Orders and Sales ',
    path: '/sales',
    icon: <AttachMoneyIcon />,
    children: [
      {
        name: 'Orders',
        path: PATH.OrderList,
        icon: <EmailIcon />,
        children: [],
      },

      {
        name: 'Payment History',
        path: PATH.PaymentList,
        icon: <EmailIcon />,
        children: [],
      },

      {
        name: 'Commission History',
        path: PATH.CommissionList,
        icon: <EmailIcon />,
        children: [],
      },
    ],
  },

  {
    name: 'Setting',
    path: PATH.setting,
    icon: <SettingsIcon />,
    children: [],
  },

  {
    name: 'Notification',
    path: PATH.notification,
    icon: <NotificationsIcon />,
    children: [],
  },
];
