import { Navigate } from 'react-router-dom';
import { ROLE, roleStoragekey } from '../constant/role';
import { AUTHPATH, PATH } from './Path';
import React from 'react';

export const ProtectedRoute: React.FC<{
  element: React.ReactElement;
  allowedRoles: string[];
}> = ({ element, allowedRoles }) => {
  const role = localStorage.getItem(roleStoragekey);

  if (allowedRoles.includes(role ? role : ROLE.auth)) {
    return element;
  }

  return <Navigate to={role ? PATH.home : AUTHPATH.root} />;
};
