import { Box, MenuItem, TableCell, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ArtStylePropsType } from './type';
import apiService from '../../../../../API/APIService';
import { Notification } from '../../../../../helper/Notification';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { headerSet } from '../../../../../API/Header';
import DropdownMenu from '../../../../../Component/Dropdown';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from '../../../../../API/encrypt';
import moment from 'moment';
import { UserStatus } from '../../../../../constant/StaticData';
import Status from '../../../../../Component/Status';
import DeleteModel from '../../../../../Component/Table/DeleteModel';
import Models from '../../../../../Component/Model';
import ActiveModel from '../../../../../Component/Table/ActiveModel';
import { ManageColorTheme } from '../../../../../helper/ChangeTheme';

export default function ArtStyleTableRow(props: ArtStylePropsType) {
  const navigate = useNavigate();
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await apiService.delete(
        `/v1/api/admin/artstyle-delete?art_style_id=${props?.data?.id}`,
        headerSet(false)
      );
      if (res.status) {
        props.getData();
        Notification('success', res.message);
        setLoading(false);
        handleClose();
      } else {
        Notification('error', res.message);
        setLoading(false);
        handleClose();
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
      handleClose();
    }
  };

  const handleChangeStatus = async () => {
    try {
      if (role) {
        const finalData = {
          art_style_id: props.data.id,
          status: role === 'active' ? 1 : 0, // 0 => Inactive 1 => Active
        };
        const incriptData = (await encryptData(finalData)) as string;
        const secret_text = {
          secret_text: incriptData,
        };
        const res = await apiService.put(
          '/v1/api/admin/artstyle-update-status',
          secret_text
        );
        if (res.status) {
          setRole('');
          Notification('success', res.message);
          props.getData();
        } else {
          setRole('');
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setRole('');
    }
  };

  const dropdownData = [
    {
      name: (
        <MenuItem
          onClick={() => {
            navigate(`/master/art-style/${props.data.id}`);
          }}
        >
          {' '}
          <EditIcon sx={{ marginRight: 1 }} /> Edit
        </MenuItem>
      ),
      icon: '',
    },
    {
      name: (
        <Models
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          button={
            <MenuItem sx={{ color: 'rgb(211, 47, 47)' }} onClick={() => {}}>
              <DeleteIcon color="error" sx={{ marginRight: 1 }} /> Delete
            </MenuItem>
          }
          content={
            <DeleteModel
              handleDelete={handleDelete}
              description="You want to delete art style"
              handleClose={handleClose}
              loading={loading}
              id={props?.data?.id}
            />
          }
        />
      ),
      icon: '',
    },
  ];

  return (
    <>
      <TableCell>
        <Typography variant="body1">{props?.data?.title}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.userDetails?.name}
        </Typography>
      </TableCell>
      <TableCell>
        {moment(props.data.created_at).format('MMM Do YYYY')}
      </TableCell>
      <TableCell>
        <Models
          open={role ? true : false}
          handleOpen={() => {
            setRole(props?.data?.status === 1 ? 'inActive' : 'active');
          }}
          handleClose={() => {
            setRole('');
            props.getData();
          }}
          button={
            <Status
              label={`${UserStatus(props?.data?.status)}`}
              status={
                UserStatus(props?.data?.status) === 'Active' ? true : false
              }
            />
          }
          content={
            <ActiveModel
              handleDelete={handleChangeStatus}
              description="Are you sure you want to change the status"
              handleClose={() => {
                setRole('');
                props.getData();
              }}
              label={props?.data?.status === 1 ? 'Yes' : 'Yes'}
              loading={loading}
              id={props?.data?.id}
            />
          }
        />
      </TableCell>
      <TableCell
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DropdownMenu
          button={
            <Box sx={{ color: ManageColorTheme('#000000', '#ffffff') }}>
              <MoreVertIcon />
            </Box>
          }
          data={dropdownData}
          icon={false}
        />
      </TableCell>
    </>
  );
}
