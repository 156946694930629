import IconButton from '@mui/material/IconButton';
import React, { ReactNode } from 'react';

interface IconButtonProps {
  onClick: () => void;
  icon: ReactNode;
}
export default function IconButtons(props: IconButtonProps) {
  return (
    <IconButton
      sx={{ padding: '15px' }}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.icon}
    </IconButton>
  );
}
