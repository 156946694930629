import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, TextField } from '@mui/material';
import Buttons from '../../../../Component/Buttons';

import { useLocation, useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from '../../../../API/encrypt';
import apiService from '../../../../API/APIService';
import { headerSet } from '../../../../API/Header';
import { Notification } from '../../../../helper/Notification';
import { AUTHPATH, PATH } from '../../../../Route/Path';
import { generateQueryString } from '../../../../helper/generateQueryString';
import MultipleSelect from '../../../../Component/Inputs/MultiSelect';
import { countryList } from '../../../../constant/StaticData';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/slices/AuthSlice';
import { roleStoragekey } from '../../../../constant/role';

interface IFormInput {
  firstName: string;
  status: string;
  stripeStatus: string
}

interface FindCoutry {
  title: string;
  flag_url: string;
  currency_code: string;
  code: string;
  currency: string;
  phone_code: string;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Country name is required'),
  status: Yup.string().required('Delivery Status is required'),
  stripeStatus: Yup.string().required('Stripe Account Status is required'),
  
});

export default function Form(props: { isEdit: boolean }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const splitPath = location.pathname.split('/');
  const [countryData, setCountryData] = useState<
    { label: string; value: string }[]
  >([]);
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: countryList[0]?.title || '', // Default to first country
      status: 'Available', // Default to "Available"
      stripeStatus: 'Available', // Default to "Available"
    },
  });

  const values = watch();

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getData = async (key: string) => {
    try {
      const data = {
        page: 0,
        size: 10,
        id__in: key,
      };
      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/country-list?${query}`,
        headerSet(false)
      );
      if (res.status) {
        setValue('firstName', res?.data?.rows[0]?.title);
        setValue(
          'status',
          res?.data?.rows?.[0]?.is_delivery_available
            ? 'Available'
            : 'Not Available' // Changed from 'Un Available'
        );
        setValue(
          'stripeStatus',
          res?.data?.rows?.[0]?.account_creation_status === 1
            ? 'Available'
            : res?.data?.rows?.[0]?.account_creation_status === 2
            ? 'Disabled'
            : 'Not Available'
        );
      }
    } catch (error) {
      handleLogout();
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      getData(splitPath[3]);
    }
  }, [location, props]);

  const addData = async (data: IFormInput) => {
    setLoading(true);
    try {
      const findCoutry: FindCoutry[] = countryList.filter(
        val => val.title === data.firstName
      );
      const finalData = {
        ...findCoutry[0],
        is_delivery_available: data.status === 'Available' ? true : false,
        account_creation_status: data.stripeStatus === 'Available' ? 1 : data.stripeStatus === 'Disabled' ? 2 : 0,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.post(
        '/v1/api/admin/country-create',
        secret_text,
        headerSet(false)
      );
      if (res.status) {
        setLoading(true);
        Notification('success', res.message);
        navigate('/master' + PATH.CountryList);
      } else {
        setLoading(false);
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  const EditData = async (data: IFormInput) => {
    setLoading(true);
    try {
      const findCoutry: FindCoutry[] = countryList.filter(
        val => val.title === data.firstName
      );
      const finalData = {
        country_id: splitPath[3],
        ...findCoutry[0],
        is_delivery_available: data.status === 'Available' ? true : false,
        account_creation_status: data.stripeStatus === 'Available' ? 1 : data.stripeStatus === 'Disabled' ? 2 : 0,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.put(
        '/v1/api/admin/country-update',
        secret_text,
        headerSet(false)
      );
      if (res.status) {
        setLoading(true);
        Notification('success', res.message);
        navigate('/master' + PATH.CountryList);
      } else {
        setLoading(false);
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (props.isEdit) {
      EditData(data);
    } else {
      addData(data);
    }
  };

  useEffect(() => {
    const data = countryList.map(element => ({
      label: `${element.flag_url} ${element.title}`,
      value: element.title,
    }));

    setCountryData(data);

    // Ensure valid default values
    if (!isDiliver.some(option => option.value === values.status)) {
      setValue('status', 'Available'); // Set default if invalid
    }
    if (!countryData.some(option => option.value === values.firstName)) {
      setValue('firstName', ''); // Set default if invalid
    }
  }, [countryList, values.firstName, values.status]);

  const isDiliver = [
    { label: 'Available', value: 'Available' },
    { label: 'Unavailable', value: 'Not Available' }, 
  ];
  const isStripeAccount = [
    { label: 'Available', value: 'Available' },
    { label: 'Unavailable', value: 'Not Available' },
    { label: 'Disabled', value: 'Disabled' , infoMessage: "Once a country is disabled, it cannot be automatically re-enabled, even if it becomes available from Stripe.", showInfo:true }, 
  ];

  const findCoutry: FindCoutry[] = countryList.filter(
    val => val.title === values.firstName
  );

  const removeData = countryData.filter(val => val.value !== '');

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ width: '48%' }}>
          <MultipleSelect
            setSelectedValues={val => {
              if (val[0]) setValue('firstName', val[0]); // Only set if valid
            }}
            selectedValues={[values.firstName || '']} // Ensure no empty string
            label="Select Country"
            data={removeData}
            error={!!errors.firstName}
            errorMessage={errors.firstName?.message}
            type={false}
            id="country-input"
          />
        </Box>
        <Box sx={{ width: '48%' }}>
          <MultipleSelect
            setSelectedValues={val => {
              setValue('status', val[0]);
            }}
            selectedValues={[values.status || 'Available']} // Default to "Available" if empty
            label="Delivery Status"
            data={isDiliver}
            error={!!errors.status}
            errorMessage={errors.status?.message}
            type={false}
            id="delivery-input"
          />
        </Box>
        <Box sx={{ width: '48%',marginTop:3.5 }}>
          <MultipleSelect 
            setSelectedValues={val => {
              setValue('stripeStatus', val[0]);
            }}
            selectedValues={[values.stripeStatus || 'Available']} // Default to "Available" if empty
            label="Stripe Account Status"
            data={isStripeAccount}
            error={!!errors.stripeStatus}
            errorMessage={errors.stripeStatus?.message}
            type={false}
            id="stripe-input"
          />
        </Box>
        <Box sx={{ width: '48%' }}>
          <TextField
            label="Country Code"
            variant="outlined"
            type="text"
            value={findCoutry?.[0]?.code}
            fullWidth
            disabled
            // {...register('firstName')}
            sx={{ marginTop: 3 }}
            // error={!!errors.firstName}
            // helperText={errors.firstName?.message}
            InputLabelProps={{ shrink: !!values.firstName || undefined }}
          />
        </Box>
        <Box sx={{ width: '48%' }}>
          <TextField
            label="Phone Code"
            variant="outlined"
            type="text"
            value={findCoutry?.[0]?.phone_code}
            fullWidth
            disabled
            // {...register('firstName')}
            sx={{ marginTop: 3 }}
            // error={!!errors.firstName}
            // helperText={errors.firstName?.message}
            InputLabelProps={{ shrink: !!values.firstName || undefined }}
          />
        </Box>
        <Box sx={{ width: '48%' }}>
          <TextField
            label="Currency"
            variant="outlined"
            type="text"
            value={findCoutry?.[0]?.currency}
            fullWidth
            disabled
            // {...register('firstName')}
            sx={{ marginTop: 3 }}
            // error={!!errors.firstName}
            // helperText={errors.firstName?.message}
            InputLabelProps={{ shrink: !!values.firstName || undefined }}
          />
        </Box>
        <Box sx={{ width: '48%' }}>
          <TextField
            label="Currency Code"
            variant="outlined"
            type="text"
            value={findCoutry?.[0]?.currency_code}
            fullWidth
            disabled
            // {...register('firstName')}
            sx={{ marginTop: 3 }}
            // error={!!errors.firstName}
            // helperText={errors.firstName?.message}
            InputLabelProps={{ shrink: !!values.firstName || undefined }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Buttons
          type="submit"
          label={props.isEdit ? 'Save' : 'Create'}
          iconAlign="left"
          loading={loading}
          variant="button"
          onClick={() => {}}
        />
        <Buttons
          type="button"
          iconAlign="left"
          label="Cancel"
          variant="outline"
          style={{ marginLeft: 2 }}
          onClick={() => {
            navigate('/master/country');
          }}
        />
      </Box>
    </Box>
  );
}
