import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';

export default function Tags(props: { label: string | ReactNode }) {
  return (
    <Box
      sx={{
        color: '#ffffff',
        paddingY: 0.5,
        paddingX: 2,
        borderRadius: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ManageColorTheme(
          globleStyle.layout.light.activeColor,
          globleStyle.layout.dark.activeColor
        ),
      }}
    >
      {props.label}
    </Box>
  );
}
