import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { ManageColorTheme } from '../../../helper/ChangeTheme';
import { globleStyle } from '../../../assets/Style/globleStyle';
import Buttons from '../../Buttons';
import { NotificationType } from './type';
import moment from 'moment';
import { S3_URL } from '../../../config';
import noImage from '../../../assets/Image/noimage.png';
import { useNavigate } from 'react-router-dom';
import noData from '../../../assets/Image/noData.gif';

interface NotificationProps {
  setLimit: () => void;
  status: boolean;
  loading: boolean;
  notification: NotificationType[];
}
export default function NotificationScreen(props: NotificationProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        [theme.breakpoints.down('xs')]: {
          width: '100vw', // Very small devices
        },
        [theme.breakpoints.between('xs', 'sm')]: {
          width: '100vw', // Small devices
        },
        [theme.breakpoints.between('sm', 'md')]: {
          width: '80vw', // Medium devices (tablets)
        },
        [theme.breakpoints.between('md', 'lg')]: {
          width: '60vw', // Large devices (small desktops)
        },
        [theme.breakpoints.between('lg', 'xl')]: {
          width: '50vw', // Extra large devices (desktops)
        },
        [theme.breakpoints.up('xl')]: {
          width: '30vw', // Largest devices
        },
        overflowY: 'auto', // Ensure scrollbar appears
        '&::-webkit-scrollbar': {
          width: '1px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: ManageColorTheme('#ffffff', '#000000'),
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#e9e9e9',
          borderRadius: '2px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#e9e9e9',
        },
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: ManageColorTheme(
            globleStyle.layout.light.activeColor,
            globleStyle.layout.dark.activeColor
          ),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: ManageColorTheme(
              globleStyle.layout.light.lightText,
              globleStyle.layout.dark.lightText
            ),
          }}
        >
          Notification
        </Typography>
      </Box>
      <Box>
        {props?.notification?.length > 0 ? (
          props?.notification?.map((val: NotificationType, i: number) => {
            const artData = JSON.parse(val?.data);
            return (
              <Box
                sx={{ padding: 1, ':hover': { backgroundColor: 'whitesmoke' } }}
                key={i}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <Typography variant="caption">
                    {moment(val.created_at).format('MMM DD YYYY hh:mm A')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  {artData?.thumbnail_image ? (
                    <img
                      height={70}
                      width={70}
                      style={{ borderRadius: 5 }}
                      src={S3_URL + artData?.thumbnail_image}
                    />
                  ) : artData?.image?.length > 0 ? (
                    <img
                      height={70}
                      width={70}
                      style={{ borderRadius: 5 }}
                      src={S3_URL + artData?.image?.[0]?.image}
                    />
                  ) : (
                    <img
                      height={70}
                      width={70}
                      style={{ borderRadius: 5 }}
                      src={noImage}
                    />
                  )}

                  <Box sx={{ marginLeft: 1 }}>
                    <Typography variant="subtitle2">{val.title}</Typography>
                    <Typography variant="caption">{val.description}</Typography>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Box sx={{ marginTop: 14 }}>
                <img src={noData} />
              </Box>
              <Typography
                variant="h5"
                sx={{ color: '#bdbdbd', marginBottom: 14 }}
              >
                No Data Found
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      <Buttons
        onClick={() => {
          navigate('/notification');
        }}
        iconAlign="left"
        loading={props.loading}
        label="Show More"
        type="button"
        variant="outline"
        style={{
          width: 'fit-content',
          margin: 'auto',
          display: 'block',
          marginTop: 2,
          marginBottom: 3,
        }}
      />
    </Box>
  );
}
