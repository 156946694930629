import React from 'react';
import { TableRowPropsType } from './type';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';

export default function GlobleTableRow(props: TableRowPropsType) {
  const checkStatus = props.checkData.filter(val => val.id === props.val.id);
  return (
    <TableRow
      onClick={() => {
        props.rowClick(props.val.id);
      }}
      sx={{
        backgroundColor: ManageColorTheme(
          globleStyle.layout.light.tableRowBg,
          globleStyle.layout.dark.tableRowBg
        ),
        '&:hover': {
          backgroundColor: ManageColorTheme(
            globleStyle.layout.light.tableRowBgHover,
            globleStyle.layout.dark.tableRowBgHover
          ),
        },
      }}
    >
      {props.checkbox && (
        <TableCell>
          <Checkbox
            onChange={() => {
              props.setCheck(props.val);
            }}
            checked={checkStatus?.length > 0 ? true : false}
          />
        </TableCell>
      )}

      {props.row}
    </TableRow>
  );
}
