import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import '../../assets/Style/Fadestyle.css';
import { Autoplay, EffectFade } from 'swiper/modules';
import { ReactNode } from 'react';

export default function FadeSlider(props: { slide: ReactNode }) {
  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={false}
        autoplay={{
          delay: 2000, // Set a positive delay value, e.g., 3000ms for 3 seconds
          disableOnInteraction: false, // Keep autoplay running after interactions
        }}
        speed={1500}
        loop={true}
        pagination={{
          clickable: false,
        }}
        modules={[EffectFade, Autoplay]}
      >
        {props?.slide}
      </Swiper>
    </>
  );
}
