import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tables from '../../../../Component/Table';
import { HeaderType, SortType } from '../../../../Component/Table/type';
import Buttons from '../../../../Component/Buttons';
import GlobleTableRow from '../../../../Component/Table/GlobleTableRow';
import apiService from '../../../../API/APIService';
import { generateQueryString } from '../../../../helper/generateQueryString';
import { CreateFilter } from '../../../../Component/Table/CreateFilter';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { Country } from './TableRow/type';
import { AUTHPATH, PATH } from '../../../../Route/Path';
// import { Icon } from '../../../../assets/Image/Icon';
import { useNavigate } from 'react-router-dom';
import CountryTableRow from './TableRow';
import { headerSet } from '../../../../API/Header';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/slices/AuthSlice';
import { roleStoragekey } from '../../../../constant/role';
// import { PATH } from '../../../../Route/Path';

export default function CountryList() {
  const [rowCount, setRowCount] = useState(10);
  const [sort, setSort] = useState<SortType[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Country[]>([]);
  const navigate = useNavigate();
  const [checkData, setCheckData] = useState<Country[]>([]);
  const [headers, setHeader] = useState<HeaderType[]>([
    {
      label: 'Country Name',
      sort: 'title',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'title__con',
      type: 'text',
      width: '30%',
      option: [],
    },
    // {
    //   label: 'Country Flag',
    //   sort: '',
    //   sortStatus: false,
    //   search: false,
    //   value: '',
    //   filterQuery: '',
    //   type: 'text',
    //   width: '10%',
    //   option: [],
    // },
    {
      label: 'Country Currency',
      sort: '',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: '',
      type: 'text',
      width: '10%',
      option: [],
    },
    {
      label: 'Country Phone',
      sort: '',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: '',
      type: 'text',
      width: '10%',
      option: [],
    },
    {
      label: 'Country Code',
      sort: '',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: '',
      type: 'text',
      width: '10%',
      option: [],
    },
    {
      label: 'Created Date',
      sort: 'created_at',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'created_at__sw',
      type: 'date',
      width: '20%',
      option: [],
    },
    {
      label: 'Delivery Status',
      sort: 'is_delivery_available',
      sortStatus: false,
      search: true,
      value: '',
      filterQuery: 'is_delivery_available__in',
      type: 'select',
      width: '20%',
      option: [
        { value: '', label: 'All' },
        { label: 'Available', value: '1' },
        { label: 'Unavailable', value: '0' },
      ],
    },
    {
      label: 'Stripe Account Status',
      sort: 'account_creation_status',
      sortStatus: false,
      search: true,
      value: '',
      filterQuery: 'account_creation_status__in',
      type: 'select',
      width: '20%',
      option: [
        { value: '', label: 'All' },
        { label: 'Available', value: '1' },
        { label: 'Unavailable', value: '0' },
        { label: 'Disabled', value: '2' },
      ],
    },
    {
      label: 'Action',
      sort: 'action',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: '',
      type: '',
      width: '5%',
      option: [],
    },
  ]);

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getData = async () => {
    try {
      const obj = CreateFilter(headers);
      setLoading(true);
      const data: {
        size: number;
        page: number;
        sort?: string; // Mark sort as optional
      } = {
        size: rowCount,
        page: currentPage,
        sort: `${sort[sort.length - 1]?.name}${sort[sort.length - 1]?.status === 1 ? '-' : ''}`,
        ...obj,
      };

      if (sort[sort.length - 1]?.name === undefined) {
        delete data.sort;
      }

      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/country-list?${query}`,
        headerSet(false)
      );
      if (res?.status) {
        setData(res?.data?.rows);
        setIsNext(res?.data?.pagination?.hasNextPage);
        setTotalData(res?.data?.rows?.length);
        setTotalData(res?.data?.pagination?.count);
        setCheckData([]);
        setLoading(false);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      handleLogout();
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, rowCount, sort, headers]);

  const previousPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(totalData / rowCount) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const rowChecked = (check: Country[]) => {
    if (check?.length !== data?.length) {
      const checked = checkData?.filter(val => val.id === check[0].id);
      const removeCheck = checkData?.filter(val => val.id !== check[0].id);
      if (checked?.length === 1) {
        setCheckData(removeCheck);
      } else {
        setCheckData([...checkData, ...check]);
      }
    } else {
      if (check?.length === checkData?.length) {
        setCheckData([]);
      } else {
        setCheckData(check);
      }
    }
  };

  return (
    <>
      <BredCrums
        heading="Countries"
        path={[
          { name: 'Dashboard', path: '/dashboard' },
          { name: 'Countries', path: '' },
        ]}
        description="Find all of your country."
        action={
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Buttons
              type="button"
              label="Create Country"
              style={{ marginRight: 2, marginTop: 2 }}
              onClick={() => {
                navigate('/master' + PATH.CountryCreate);
              }}
              loading={false}
              variant="button"
              iconAlign="left"
            />
          </Box>
        }
      />
      <Box sx={{ marginTop: '3%' }}>
        <Box>
          <Tables
            checkbox={false}
            height={570}
            headers={headers}
            loading={loading}
            sort={sort.length > 1 ? [sort[sort.length - 1]] : sort}
            rowCount={rowCount}
            currentPage={currentPage}
            totalData={totalData}
            checkData={checkData}
            data={data}
            isNext={isNext}
            limit={[10, 20, 30, 40]}
            handleChangeRowCount={(value: number) => {
              setRowCount(value);
              setCurrentPage(0);
            }}
            setSort={(value: SortType[]) => {
              setSort(value);
            }}
            setCheckAll={() => {
              rowChecked([...data]);
            }}
            setHeader={(val: HeaderType[]) => {
              setHeader(val);
              setCurrentPage(0);
            }}
            previousPage={() => {
              previousPage();
            }}
            getData={() => {
              getData();
            }}
            nextPage={() => {
              nextPage();
            }}
            row={data.map((val: Country, i) => {
              return (
                <GlobleTableRow
                  setCheck={(val: Country) => {
                    rowChecked([val]);
                  }}
                  rowClick={(val: string) => {
                    console.log(val);
                  }}
                  checkData={checkData}
                  val={val}
                  checkbox={false}
                  key={i}
                  row={
                    <CountryTableRow
                      getData={() => {
                        getData();
                      }}
                      data={val}
                    />
                  }
                />
              );
            })}
          />
        </Box>
      </Box>
    </>
  );
}
