import { createSlice } from '@reduxjs/toolkit';
import { CounterState } from '../../type/Slice/AuthSliceType';
import { ROLE } from '../../constant/role';

const initialState: CounterState = {
  role: ROLE.auth,
};

// Create the slice
const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: state => {
      state.role = ROLE.user;
    },
    logout: state => {
      state.role = ROLE.auth;
    },
  },
});

// Export the actions
export const { login, logout } = AuthSlice.actions;

// Export the reducer
export default AuthSlice.reducer;
