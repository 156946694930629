// export const emailRegex = new RegExp(
//   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
// );

export const passwordRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&()"'#():;{}[\]<>,*-/|\\_.?~^=])[A-Za-z\d@.#$!%*?&()"'#():;{}<>,[\]~.?*-_/=|\\]{8,15}$/
);

export const nullRow = ['', '', '', '', '', ''];

export const PaymentMethod = (payment: number) => {
  switch (payment) {
    case 0:
      return '';
      break;
    case 1:
      return 'Card';
      break;
    default:
      break;
  }
};

export const UserStatus = (roleStatus: number) => {
  switch (roleStatus) {
    case 0:
      return 'Inactive';
      break;
    case 1:
      return 'Active';
      break;
    default:
      break;
  }
};

export const AdminUserStatus = (roleStatus: number) => {
  switch (roleStatus) {
    case 0:
      return 'Not Verified';
      break;
    case 1:
      return 'Verified';
      break;
    default:
      break;
  }
};

export const ArtStatus = (roleStatus: number) => {
  switch (roleStatus) {
    case 0:
      return 'Draft';
      break;
    case 1:
      return 'Published';
      break;
    case 2:
      return 'Hide';
      break;
    default:
      break;
  }
};

export const isFrame = (type: number) => {
  switch (type) {
    case 0:
      return 'Without Frame';
      break;
    case 1:
      return 'With Frame';
      break;

    default:
      break;
  }
};

export const copyType = (type: number) => {
  switch (type) {
    case 0:
      return 'Soft Copy';
      break;
    case 1:
      return 'Hard Copy';
      break;
    default:
      break;
  }
};

export const Dimention = (type: number) => {
  switch (type) {
    case 0:
      return 'Centimitter';
      break;
    case 1:
      return 'Inch';
      break;
    case 2:
      return 'Feet';
      break;
    default:
      break;
  }
};

export const OrderStatus = (type: number) => {
  switch (type) {
    case 0:
      return 'Pending';
      break;
    case 1:
      return 'Success';
      break;
    case 2:
      return 'Faild';
      break;
    default:
      break;
  }
};

export const PaymentStatus = (tyle: string) => {
  switch (tyle) {
    case 'SUCCESS':
      return 'Success';
      break;
    case 'PENDING':
      return 'Pending';
      break;
    case 'FAILED':
      return 'Failed';
      break;
    default:
      break;
  }
};

export const countryList = [
  {
    title: '',
    flag_url: '',
    currency_code: '',
    currency: '',
    code: '',
    phone_code: '',
  },
  {
    title: 'Afghanistan',
    flag_url: '🇦🇫',
    currency_code: 'AFN',
    currency: '؋',
    code: 'AF',
    phone_code: '+93',
  },
  {
    title: 'Albania',
    flag_url: '🇦🇱',
    currency_code: 'ALL',
    currency: 'Lek',
    code: 'AL',
    phone_code: '+355',
  },
  {
    title: 'Algeria',
    flag_url: '🇩🇿',
    currency_code: 'DZD',
    currency: 'د.ج',
    code: 'DZ',
    phone_code: '+213',
  },
  {
    title: 'Andorra',
    flag_url: '🇦🇩',
    currency_code: 'EUR',
    currency: '€',
    code: 'AD',
    phone_code: '+376',
  },
  {
    title: 'Angola',
    flag_url: '🇦🇴',
    currency_code: 'AOA',
    currency: 'Kz',
    code: 'AO',
    phone_code: '+244',
  },
  {
    title: 'Antigua and Barbuda',
    flag_url: '🇦🇬',
    currency_code: 'XCD',
    currency: '$',
    code: 'AG',
    phone_code: '+1-268',
  },
  {
    title: 'Argentina',
    flag_url: '🇦🇷',
    currency_code: 'ARS',
    currency: '$',
    code: 'AR',
    phone_code: '+54',
  },
  {
    title: 'Armenia',
    flag_url: '🇦🇲',
    currency_code: 'AMD',
    currency: '֏',
    code: 'AM',
    phone_code: '+374',
  },
  {
    title: 'Australia',
    flag_url: '🇦🇺',
    currency_code: 'AUD',
    currency: '$',
    code: 'AU',
    phone_code: '+61',
  },
  {
    title: 'Austria',
    flag_url: '🇦🇹',
    currency_code: 'EUR',
    currency: '€',
    code: 'AT',
    phone_code: '+43',
  },
  {
    title: 'Azerbaijan',
    flag_url: '🇦🇿',
    currency_code: 'AZN',
    currency: '₼',
    code: 'AZ',
    phone_code: '+994',
  },
  {
    title: 'Bahamas',
    flag_url: '🇧🇸',
    currency_code: 'BSD',
    currency: '$',
    code: 'BS',
    phone_code: '+1-242',
  },
  {
    title: 'Bahrain',
    flag_url: '🇧🇭',
    currency_code: 'BHD',
    currency: 'ب.د',
    code: 'BH',
    phone_code: '+973',
  },
  {
    title: 'Bangladesh',
    flag_url: '🇧🇩',
    currency_code: 'BDT',
    currency: '৳',
    code: 'BD',
    phone_code: '+880',
  },
  {
    title: 'Barbados',
    flag_url: '🇧🇧',
    currency_code: 'BBD',
    currency: '$',
    code: 'BB',
    phone_code: '+1-246',
  },
  {
    title: 'Belarus',
    flag_url: '🇧🇾',
    currency_code: 'BYN',
    currency: 'Br',
    code: 'BY',
    phone_code: '+375',
  },
  {
    title: 'Belgium',
    flag_url: '🇧🇪',
    currency_code: 'EUR',
    currency: '€',
    code: 'BE',
    phone_code: '+32',
  },
  {
    title: 'Belize',
    flag_url: '🇧🇿',
    currency_code: 'BZD',
    currency: '$',
    code: 'BZ',
    phone_code: '+501',
  },
  {
    title: 'Benin',
    flag_url: '🇧🇯',
    currency_code: 'XOF',
    currency: 'Fr',
    code: 'BJ',
    phone_code: '+229',
  },
  {
    title: 'Bhutan',
    flag_url: '🇧🇹',
    currency_code: 'INR',
    currency: '₹',
    code: 'BT',
    phone_code: '+975',
  },
  {
    title: 'Bolivia',
    flag_url: '🇧🇴',
    currency_code: 'BOB',
    currency: 'Bs',
    code: 'BO',
    phone_code: '+591',
  },
  {
    title: 'Bosnia and Herzegovina',
    flag_url: '🇧🇦',
    currency_code: 'BAM',
    currency: 'KM',
    code: 'BA',
    phone_code: '+387',
  },
  {
    title: 'Botswana',
    flag_url: '🇧🇼',
    currency_code: 'BWP',
    currency: 'P',
    code: 'BW',
    phone_code: '+267',
  },
  {
    title: 'Brazil',
    flag_url: '🇧🇷',
    currency_code: 'BRL',
    currency: 'R$',
    code: 'BR',
    phone_code: '+55',
  },
  {
    title: 'Brunei',
    flag_url: '🇧🇳',
    currency_code: 'BND',
    currency: '$',
    code: 'BN',
    phone_code: '+673',
  },
  {
    title: 'Bulgaria',
    flag_url: '🇧🇬',
    currency_code: 'BGN',
    currency: 'лв',
    code: 'BG',
    phone_code: '+359',
  },
  {
    title: 'Burkina Faso',
    flag_url: '🇧🇫',
    currency_code: 'XOF',
    currency: 'Fr',
    code: 'BF',
    phone_code: '+226',
  },
  {
    title: 'Burundi',
    flag_url: '🇧🇮',
    currency_code: 'BIF',
    currency: 'Fr',
    code: 'BI',
    phone_code: '+257',
  },
  {
    title: 'Cabo Verde',
    flag_url: '🇨🇻',
    currency_code: 'CVE',
    currency: '$',
    code: 'CV',
    phone_code: '+238',
  },
  {
    title: 'Cambodia',
    flag_url: '🇰🇭',
    currency_code: 'KHR',
    currency: '៛',
    code: 'KH',
    phone_code: '+855',
  },
  {
    title: 'Cameroon',
    flag_url: '🇨🇲',
    currency_code: 'XAF',
    currency: 'Fr',
    code: 'CM',
    phone_code: '+237',
  },
  {
    title: 'Canada',
    flag_url: '🇨🇦',
    currency_code: 'CAD',
    currency: '$',
    code: 'CA',
    phone_code: '+1',
  },
  {
    title: 'Central African Republic',
    flag_url: '🇨🇫',
    currency_code: 'XAF',
    currency: 'Fr',
    code: 'CF',
    phone_code: '+236',
  },
  {
    title: 'Chad',
    flag_url: '🇹🇩',
    currency_code: 'XAF',
    currency: 'Fr',
    code: 'TD',
    phone_code: '+235',
  },
  {
    title: 'Chile',
    flag_url: '🇨🇱',
    currency_code: 'CLP',
    currency: '$',
    code: 'CL',
    phone_code: '+56',
  },
  {
    title: 'China',
    flag_url: '🇨🇳',
    currency_code: 'CNY',
    currency: '¥',
    code: 'CN',
    phone_code: '+86',
  },
  {
    title: 'Colombia',
    flag_url: '🇨🇴',
    currency_code: 'COP',
    currency: '$',
    code: 'CO',
    phone_code: '+57',
  },
  {
    title: 'Comoros',
    flag_url: '🇲🇲',
    currency_code: 'KMF',
    currency: 'Fr',
    code: 'KM',
    phone_code: '+269',
  },
  {
    title: 'Congo',
    flag_url: '🇨🇬',
    currency_code: 'XAF',
    currency: 'Fr',
    code: 'CG',
    phone_code: '+242',
  },
  {
    title: 'Congo, Democratic Republic of the',
    flag_url: '🇨🇩',
    currency_code: 'CDF',
    currency: 'Fr',
    code: 'CD',
    phone_code: '+243',
  },
  {
    title: 'Costa Rica',
    flag_url: '🇨🇷',
    currency_code: 'CRC',
    currency: '₡',
    code: 'CR',
    phone_code: '+506',
  },
  {
    title: 'Croatia',
    flag_url: '🇭🇷',
    currency_code: 'HRK',
    currency: 'kn',
    code: 'HR',
    phone_code: '+385',
  },
  {
    title: 'Cuba',
    flag_url: '🇨🇺',
    currency_code: 'CUP',
    currency: '$',
    code: 'CU',
    phone_code: '+53',
  },
  {
    title: 'Cyprus',
    flag_url: '🇨🇾',
    currency_code: 'EUR',
    currency: '€',
    code: 'CY',
    phone_code: '+357',
  },
  {
    title: 'Czech Republic',
    flag_url: '🇨🇿',
    currency_code: 'CZK',
    currency: 'Kč',
    code: 'CZ',
    phone_code: '+420',
  },
  {
    title: 'Denmark',
    flag_url: '🇩🇰',
    currency_code: 'DKK',
    currency: 'kr',
    code: 'DK',
    phone_code: '+45',
  },
  {
    title: 'Djibouti',
    flag_url: '🇩🇯',
    currency_code: 'DJF',
    currency: 'Fr',
    code: 'DJ',
    phone_code: '+253',
  },
  {
    title: 'Dominica',
    flag_url: '🇩🇲',
    currency_code: 'XCD',
    currency: '$',
    code: 'DM',
    phone_code: '+1-767',
  },
  {
    title: 'Dominican Republic',
    flag_url: '🇩🇴',
    currency_code: 'DOP',
    currency: '$',
    code: 'DO',
    phone_code: '+1-809',
  },
  {
    title: 'Ecuador',
    flag_url: '🇪🇨',
    currency_code: 'USD',
    currency: '$',
    code: 'EC',
    phone_code: '+593',
  },
  {
    title: 'Egypt',
    flag_url: '🇪🇬',
    currency_code: 'EGP',
    currency: 'ج.م',
    code: 'EG',
    phone_code: '+20',
  },
  {
    title: 'El Salvador',
    flag_url: '🇸🇻',
    currency_code: 'USD',
    currency: '$',
    code: 'SV',
    phone_code: '+503',
  },
  {
    title: 'Equatorial Guinea',
    flag_url: '🇬🇶',
    currency_code: 'XAF',
    currency: 'Fr',
    code: 'GQ',
    phone_code: '+240',
  },
  {
    title: 'Eritrea',
    flag_url: '🇪🇷',
    currency_code: 'ERN',
    currency: 'Nfk',
    code: 'ER',
    phone_code: '+291',
  },
  {
    title: 'Estonia',
    flag_url: '🇪🇪',
    currency_code: 'EUR',
    currency: '€',
    code: 'EE',
    phone_code: '+372',
  },
  {
    title: 'Eswatini',
    flag_url: '🇸🇿',
    currency_code: 'SZL',
    currency: 'E',
    code: 'SZ',
    phone_code: '+268',
  },
  {
    title: 'Ethiopia',
    flag_url: '🇪🇹',
    currency_code: 'ETB',
    currency: 'Br',
    code: 'ET',
    phone_code: '+251',
  },
  {
    title: 'Fiji',
    flag_url: '🇫🇯',
    currency_code: 'FJD',
    currency: '$',
    code: 'FJ',
    phone_code: '+679',
  },
  {
    title: 'Finland',
    flag_url: '🇫🇮',
    currency_code: 'EUR',
    currency: '€',
    code: 'FI',
    phone_code: '+358',
  },
  {
    title: 'France',
    flag_url: '🇫🇷',
    currency_code: 'EUR',
    currency: '€',
    code: 'FR',
    phone_code: '+33',
  },
  {
    title: 'Gabon',
    flag_url: '🇬🇦',
    currency_code: 'XAF',
    currency: 'Fr',
    code: 'GA',
    phone_code: '+241',
  },
  {
    title: 'Gambia',
    flag_url: '🇬🇲',
    currency_code: 'GMD',
    currency: 'D',
    code: 'GM',
    phone_code: '+220',
  },
  {
    title: 'Georgia',
    flag_url: '🇬🇪',
    currency_code: 'GEL',
    currency: '₾',
    code: 'GE',
    phone_code: '+995',
  },
  {
    title: 'Germany',
    flag_url: '🇩🇪',
    currency_code: 'EUR',
    currency: '€',
    code: 'DE',
    phone_code: '+49',
  },
  {
    title: 'Ghana',
    flag_url: '🇬🇭',
    currency_code: 'GHS',
    currency: '₵',
    code: 'GH',
    phone_code: '+233',
  },
  {
    title: 'Greece',
    flag_url: '🇬🇷',
    currency_code: 'EUR',
    currency: '€',
    code: 'GR',
    phone_code: '+30',
  },
  {
    title: 'Grenada',
    flag_url: '🇲🇸',
    currency_code: 'XCD',
    currency: '$',
    code: 'GD',
    phone_code: '+1-473',
  },
  {
    title: 'Guatemala',
    flag_url: '🇲🇽',
    currency_code: 'GTQ',
    currency: 'Q',
    code: 'GT',
    phone_code: '+502',
  },
  {
    title: 'Guinea',
    flag_url: '🇬🇳',
    currency_code: 'GNF',
    currency: 'Fr',
    code: 'GN',
    phone_code: '+224',
  },
  {
    title: 'Guinea-Bissau',
    flag_url: '🇬🇼',
    currency_code: 'XOF',
    currency: 'Fr',
    code: 'GW',
    phone_code: '+245',
  },
  {
    title: 'Guyana',
    flag_url: '🇬🇾',
    currency_code: 'GYD',
    currency: '$',
    code: 'GY',
    phone_code: '+592',
  },
  {
    title: 'Haiti',
    flag_url: '🇭🇹',
    currency_code: 'HTG',
    currency: 'G',
    code: 'HT',
    phone_code: '+509',
  },
  {
    title: 'Honduras',
    flag_url: '🇭🇳',
    currency_code: 'HNL',
    currency: 'L',
    code: 'HN',
    phone_code: '+504',
  },
  {
    title: 'Hungary',
    flag_url: '🇭🇺',
    currency_code: 'HUF',
    currency: 'Ft',
    code: 'HU',
    phone_code: '+36',
  },
  {
    title: 'Iceland',
    flag_url: '🇮🇸',
    currency_code: 'ISK',
    currency: 'kr',
    code: 'IS',
    phone_code: '+354',
  },
  {
    title: 'India',
    flag_url: '🇮🇳',
    currency_code: 'INR',
    currency: '₹',
    code: 'IN',
    phone_code: '+91',
  },
  {
    title: 'Indonesia',
    flag_url: '🇮🇩',
    currency_code: 'IDR',
    currency: 'Rp',
    code: 'ID',
    phone_code: '+62',
  },
  {
    title: 'Iran',
    flag_url: '🇮🇷',
    currency_code: 'IRR',
    currency: '﷼',
    code: 'IR',
    phone_code: '+98',
  },
  {
    title: 'Iraq',
    flag_url: '🇮🇶',
    currency_code: 'IQD',
    currency: 'ع.د',
    code: 'IQ',
    phone_code: '+964',
  },
  {
    title: 'Ireland',
    flag_url: '🇮🇪',
    currency_code: 'EUR',
    currency: '€',
    code: 'IE',
    phone_code: '+353',
  },
  {
    title: 'Israel',
    flag_url: '🇮🇱',
    currency_code: 'ILS',
    currency: '₪',
    code: 'IL',
    phone_code: '+972',
  },
  {
    title: 'Italy',
    flag_url: '🇮🇹',
    currency_code: 'EUR',
    currency: '€',
    code: 'IT',
    phone_code: '+39',
  },
  {
    title: 'Jamaica',
    flag_url: '🇯🇲',
    currency_code: 'JMD',
    currency: '$',
    code: 'JM',
    phone_code: '+1-876',
  },
  {
    title: 'Japan',
    flag_url: '🇯🇵',
    currency_code: 'JPY',
    currency: '¥',
    code: 'JP',
    phone_code: '+81',
  },
  {
    title: 'Jordan',
    flag_url: '🇯🇴',
    currency_code: 'JOD',
    currency: 'د.ا',
    code: 'JO',
    phone_code: '+962',
  },
  {
    title: 'Kazakhstan',
    flag_url: '🇰🇿',
    currency_code: 'KZT',
    currency: '₸',
    code: 'KZ',
    phone_code: '+7',
  },
  {
    title: 'Kenya',
    flag_url: '🇰🇪',
    currency_code: 'KES',
    currency: 'KSh',
    code: 'KE',
    phone_code: '+254',
  },
  {
    title: 'Kiribati',
    flag_url: '🇰🇮',
    currency_code: 'AUD',
    currency: '$',
    code: 'KI',
    phone_code: '+686',
  },
  {
    title: 'Korea, North',
    flag_url: '🇰🇵',
    currency_code: 'KPW',
    currency: '₩',
    code: 'KP',
    phone_code: '+850',
  },
  {
    title: 'Korea, South',
    flag_url: '🇰🇷',
    currency_code: 'KRW',
    currency: '₩',
    code: 'KR',
    phone_code: '+82',
  },
  {
    title: 'Kosovo',
    flag_url: '🇽🇰',
    currency_code: 'EUR',
    currency: '€',
    code: 'XK',
    phone_code: '+383',
  },
  {
    title: 'Kuwait',
    flag_url: '🇰🇼',
    currency_code: 'KWD',
    currency: 'د.ك',
    code: 'KW',
    phone_code: '+965',
  },
  {
    title: 'Kyrgyzstan',
    flag_url: '🇰🇬',
    currency_code: 'KGS',
    currency: 'лв',
    code: 'KG',
    phone_code: '+996',
  },
  {
    title: 'Laos',
    flag_url: '🇱🇦',
    currency_code: 'LAK',
    currency: '₭',
    code: 'LA',
    phone_code: '+856',
  },
  {
    title: 'Latvia',
    flag_url: '🇱🇻',
    currency_code: 'EUR',
    currency: '€',
    code: 'LV',
    phone_code: '+371',
  },
  {
    title: 'Lebanon',
    flag_url: '🇱🇧',
    currency_code: 'LBP',
    currency: 'ل.ل',
    code: 'LB',
    phone_code: '+961',
  },
  {
    title: 'Lesotho',
    flag_url: '🇱🇸',
    currency_code: 'LSL',
    currency: 'L',
    code: 'LS',
    phone_code: '+266',
  },
  {
    title: 'Liberia',
    flag_url: '🇱🇸',
    currency_code: 'LRD',
    currency: '$',
    code: 'LR',
    phone_code: '+231',
  },
  {
    title: 'Libya',
    flag_url: '🇱🇾',
    currency_code: 'LYD',
    currency: 'ل.د',
    code: 'LY',
    phone_code: '+218',
  },
  {
    title: 'Liechtenstein',
    flag_url: '🇱🇮',
    currency_code: 'CHF',
    currency: 'Fr',
    code: 'LI',
    phone_code: '+423',
  },
  {
    title: 'Lithuania',
    flag_url: '🇱🇹',
    currency_code: 'EUR',
    currency: '€',
    code: 'LT',
    phone_code: '+370',
  },
  {
    title: 'Luxembourg',
    flag_url: '🇱🇺',
    currency_code: 'EUR',
    currency: '€',
    code: 'LU',
    phone_code: '+352',
  },
  {
    title: 'Madagascar',
    flag_url: '🇲🇬',
    currency_code: 'MGA',
    currency: 'Ar',
    code: 'MG',
    phone_code: '+261',
  },
  {
    title: 'Malawi',
    flag_url: '🇲🇼',
    currency_code: 'MWK',
    currency: 'K',
    code: 'MW',
    phone_code: '+265',
  },
  {
    title: 'Malaysia',
    flag_url: '🇲🇾',
    currency_code: 'MYR',
    currency: 'RM',
    code: 'MY',
    phone_code: '+60',
  },
  {
    title: 'Maldives',
    flag_url: '🇲🇻',
    currency_code: 'MVR',
    currency: 'MVR',
    code: 'MV',
    phone_code: '+960',
  },
  {
    title: 'Mali',
    flag_url: '🇲🇱',
    currency_code: 'XOF',
    currency: 'Fr',
    code: 'ML',
    phone_code: '+223',
  },
  {
    title: 'Malta',
    flag_url: '🇲🇹',
    currency_code: 'EUR',
    currency: '€',
    code: 'MT',
    phone_code: '+356',
  },
  {
    title: 'Marshall Islands',
    flag_url: '🇲🇭',
    currency_code: 'USD',
    currency: '$',
    code: 'MH',
    phone_code: '+692',
  },
  {
    title: 'Mauritania',
    flag_url: '🇲🇷',
    currency_code: 'MRU',
    currency: 'UM',
    code: 'MR',
    phone_code: '+222',
  },
  {
    title: 'Mauritius',
    flag_url: '🇲🇺',
    currency_code: 'MUR',
    currency: '₨',
    code: 'MU',
    phone_code: '+230',
  },
  {
    title: 'Mexico',
    flag_url: '🇲🇽',
    currency_code: 'MXN',
    currency: '$',
    code: 'MX',
    phone_code: '+52',
  },
  {
    title: 'Micronesia',
    flag_url: '🇫🇲',
    currency_code: 'USD',
    currency: '$',
    code: 'FM',
    phone_code: '+691',
  },
  {
    title: 'Moldova',
    flag_url: '🇲🇩',
    currency_code: 'MDL',
    currency: 'L',
    code: 'MD',
    phone_code: '+373',
  },
  {
    title: 'Monaco',
    flag_url: '🇲🇨',
    currency_code: 'EUR',
    currency: '€',
    code: 'MC',
    phone_code: '+377',
  },
  {
    title: 'Mongolia',
    flag_url: '🇲🇳',
    currency_code: 'MNT',
    currency: '₮',
    code: 'MN',
    phone_code: '+976',
  },
  {
    title: 'Montenegro',
    flag_url: '🇲🇪',
    currency_code: 'EUR',
    currency: '€',
    code: 'ME',
    phone_code: '+382',
  },
  {
    title: 'Morocco',
    flag_url: '🇲🇦',
    currency_code: 'MAD',
    currency: 'د.م.',
    code: 'MA',
    phone_code: '+212',
  },
  {
    title: 'Mozambique',
    flag_url: '🇲🇿',
    currency_code: 'MZN',
    currency: 'MT',
    code: 'MZ',
    phone_code: '+258',
  },
  {
    title: 'Myanmar',
    flag_url: '🇲🇲',
    currency_code: 'MMK',
    currency: 'Ks',
    code: 'MM',
    phone_code: '+95',
  },
  {
    title: 'Namibia',
    flag_url: '🇳🇦',
    currency_code: 'NAD',
    currency: '$',
    code: 'NA',
    phone_code: '+264',
  },
  {
    title: 'Nauru',
    flag_url: '🇳🇷',
    currency_code: 'AUD',
    currency: '$',
    code: 'NR',
    phone_code: '+674',
  },
  {
    title: 'Nepal',
    flag_url: '🇳🇵',
    currency_code: 'NPR',
    currency: '₨',
    code: 'NP',
    phone_code: '+977',
  },
  {
    title: 'Netherlands',
    flag_url: '🇳🇱',
    currency_code: 'EUR',
    currency: '€',
    code: 'NL',
    phone_code: '+31',
  },
  {
    title: 'New Zealand',
    flag_url: '🇳🇿',
    currency_code: 'NZD',
    currency: '$',
    code: 'NZ',
    phone_code: '+64',
  },
  {
    title: 'Nicaragua',
    flag_url: '🇳🇮',
    currency_code: 'NIO',
    currency: 'C$',
    code: 'NI',
    phone_code: '+505',
  },
  {
    title: 'Niger',
    flag_url: '🇳🇪',
    currency_code: 'XOF',
    currency: 'Fr',
    code: 'NE',
    phone_code: '+227',
  },
  {
    title: 'Nigeria',
    flag_url: '🇳🇬',
    currency_code: 'NGN',
    currency: '₦',
    code: 'NG',
    phone_code: '+234',
  },
  {
    title: 'North Macedonia',
    flag_url: '🇲🇰',
    currency_code: 'MKD',
    currency: 'ден',
    code: 'MK',
    phone_code: '+389',
  },
  {
    title: 'Norway',
    flag_url: '🇳🇴',
    currency_code: 'NOK',
    currency: 'kr',
    code: 'NO',
    phone_code: '+47',
  },
  {
    title: 'Oman',
    flag_url: '🇴🇲',
    currency_code: 'OMR',
    currency: 'ر.ع.',
    code: 'OM',
    phone_code: '+968',
  },
  {
    title: 'Pakistan',
    flag_url: '🇵🇰',
    currency_code: 'PKR',
    currency: '₨',
    code: 'PK',
    phone_code: '+92',
  },
  {
    title: 'Palau',
    flag_url: '🇵🇼',
    currency_code: 'USD',
    currency: '$',
    code: 'PW',
    phone_code: '+680',
  },
  {
    title: 'Panama',
    flag_url: '🇵🇦',
    currency_code: 'PAB',
    currency: 'B/.',
    code: 'PA',
    phone_code: '+507',
  },
  {
    title: 'Papua New Guinea',
    flag_url: '🇵🇬',
    currency_code: 'PGK',
    currency: 'K',
    code: 'PG',
    phone_code: '+675',
  },
  {
    title: 'Paraguay',
    flag_url: '🇵🇾',
    currency_code: 'PYG',
    currency: '₲',
    code: 'PY',
    phone_code: '+595',
  },
  {
    title: 'Peru',
    flag_url: '🇵🇪',
    currency_code: 'PEN',
    currency: 'S/',
    code: 'PE',
    phone_code: '+51',
  },
  {
    title: 'Philippines',
    flag_url: '🇵🇭',
    currency_code: 'PHP',
    currency: '₱',
    code: 'PH',
    phone_code: '+63',
  },
  {
    title: 'Poland',
    flag_url: '🇵🇱',
    currency_code: 'PLN',
    currency: 'zł',
    code: 'PL',
    phone_code: '+48',
  },
  {
    title: 'Portugal',
    flag_url: '🇵🇹',
    currency_code: 'EUR',
    currency: '€',
    code: 'PT',
    phone_code: '+351',
  },
  {
    title: 'Qatar',
    flag_url: '🇶🇦',
    currency_code: 'QAR',
    currency: 'ر.ق',
    code: 'QA',
    phone_code: '+974',
  },
  {
    title: 'Romania',
    flag_url: '🇷🇴',
    currency_code: 'RON',
    currency: 'lei',
    code: 'RO',
    phone_code: '+40',
  },
  {
    title: 'Russia',
    flag_url: '🇷🇺',
    currency_code: 'RUB',
    currency: '₽',
    code: 'RU',
    phone_code: '+7',
  },
  {
    title: 'Rwanda',
    flag_url: '🇷🇼',
    currency_code: 'RWF',
    currency: 'Fr',
    code: 'RW',
    phone_code: '+250',
  },
  {
    title: 'Saint Kitts and Nevis',
    flag_url: '🇰🇳',
    currency_code: 'XCD',
    currency: '$',
    code: 'KN',
    phone_code: '+1-869',
  },
  {
    title: 'Saint Lucia',
    flag_url: '🇱🇨',
    currency_code: 'XCD',
    currency: '$',
    code: 'LC',
    phone_code: '+1-758',
  },
  {
    title: 'Saint Vincent and the Grenadines',
    flag_url: '🇻🇨',
    currency_code: 'XCD',
    currency: '$',
    code: 'VC',
    phone_code: '+1-784',
  },
  {
    title: 'Samoa',
    flag_url: '🇼🇸',
    currency_code: 'WST',
    currency: '$',
    code: 'WS',
    phone_code: '+685',
  },
  {
    title: 'San Marino',
    flag_url: '🇸🇲',
    currency_code: 'EUR',
    currency: '€',
    code: 'SM',
    phone_code: '+378',
  },
  {
    title: 'Sao Tome and Principe',
    flag_url: '🇹🇱',
    currency_code: 'STN',
    currency: 'Db',
    code: 'ST',
    phone_code: '+239',
  },
  {
    title: 'Saudi Arabia',
    flag_url: '🇸🇦',
    currency_code: 'SAR',
    currency: 'ر.س',
    code: 'SA',
    phone_code: '+966',
  },
  {
    title: 'Senegal',
    flag_url: '🇸🇳',
    currency_code: 'XOF',
    currency: 'Fr',
    code: 'SN',
    phone_code: '+221',
  },
  {
    title: 'Serbia',
    flag_url: '🇷🇸',
    currency_code: 'RSD',
    currency: 'д.',
    code: 'RS',
    phone_code: '+381',
  },
  {
    title: 'Seychelles',
    flag_url: '🇸🇨',
    currency_code: 'SCR',
    currency: '₨',
    code: 'SC',
    phone_code: '+248',
  },
  {
    title: 'Sierra Leone',
    flag_url: '🇱🇸',
    currency_code: 'SLL',
    currency: 'Le',
    code: 'SL',
    phone_code: '+232',
  },
  {
    title: 'Singapore',
    flag_url: '🇸🇬',
    currency_code: 'SGD',
    currency: '$',
    code: 'SG',
    phone_code: '+65',
  },
  {
    title: 'Slovakia',
    flag_url: '🇸🇰',
    currency_code: 'EUR',
    currency: '€',
    code: 'SK',
    phone_code: '+421',
  },
  {
    title: 'Slovenia',
    flag_url: '🇸🇮',
    currency_code: 'EUR',
    currency: '€',
    code: 'SI',
    phone_code: '+386',
  },
  {
    title: 'Solomon Islands',
    flag_url: '🇸🇧',
    currency_code: 'SBD',
    currency: '$',
    code: 'SB',
    phone_code: '+677',
  },
  {
    title: 'Somalia',
    flag_url: '🇸🇴',
    currency_code: 'SOS',
    currency: 'Sh',
    code: 'SO',
    phone_code: '+252',
  },
  {
    title: 'South Africa',
    flag_url: '🇿🇦',
    currency_code: 'ZAR',
    currency: 'R',
    code: 'ZA',
    phone_code: '+27',
  },
  {
    title: 'South Sudan',
    flag_url: '🇸🇸',
    currency_code: 'SSP',
    currency: '£',
    code: 'SS',
    phone_code: '+211',
  },
  {
    title: 'Spain',
    flag_url: '🇪🇸',
    currency_code: 'EUR',
    currency: '€',
    code: 'ES',
    phone_code: '+34',
  },
  {
    title: 'Sri Lanka',
    flag_url: '🇱🇰',
    currency_code: 'LKR',
    currency: 'Rs',
    code: 'LK',
    phone_code: '+94',
  },
  {
    title: 'Sudan',
    flag_url: '🇸🇩',
    currency_code: 'SDG',
    currency: 'ج.س.',
    code: 'SD',
    phone_code: '+249',
  },
  {
    title: 'Suriname',
    flag_url: '🇸🇷',
    currency_code: 'SRD',
    currency: '$',
    code: 'SR',
    phone_code: '+597',
  },
  {
    title: 'Sweden',
    flag_url: '🇸🇪',
    currency_code: 'SEK',
    currency: 'kr',
    code: 'SE',
    phone_code: '+46',
  },
  {
    title: 'Switzerland',
    flag_url: '🇨🇭',
    currency_code: 'CHF',
    currency: 'Fr',
    code: 'CH',
    phone_code: '+41',
  },
  {
    title: 'Syria',
    flag_url: '🇸🇾',
    currency_code: 'SYP',
    currency: 'ل.س',
    code: 'SY',
    phone_code: '+963',
  },
  {
    title: 'Taiwan',
    flag_url: '🇹🇼',
    currency_code: 'TWD',
    currency: 'NT$',
    code: 'TW',
    phone_code: '+886',
  },
  {
    title: 'Tajikistan',
    flag_url: '🇹🇯',
    currency_code: 'TJS',
    currency: 'ЅМ',
    code: 'TJ',
    phone_code: '+992',
  },
  {
    title: 'Tanzania',
    flag_url: '🇹🇿',
    currency_code: 'TZS',
    currency: 'TSh',
    code: 'TZ',
    phone_code: '+255',
  },
  {
    title: 'Thailand',
    flag_url: '🇹🇭',
    currency_code: 'THB',
    currency: '฿',
    code: 'TH',
    phone_code: '+66',
  },
  {
    title: 'Timor-Leste',
    flag_url: '🇹🇱',
    currency_code: 'USD',
    currency: '$',
    code: 'TL',
    phone_code: '+670',
  },
  {
    title: 'Togo',
    flag_url: '🇹🇬',
    currency_code: 'XOF',
    currency: 'Fr',
    code: 'TG',
    phone_code: '+228',
  },
  {
    title: 'Tonga',
    flag_url: '🇹🇴',
    currency_code: 'TOP',
    currency: '$',
    code: 'TO',
    phone_code: '+676',
  },
  {
    title: 'Trinidad and Tobago',
    flag_url: '🇹🇹',
    currency_code: 'TTD',
    currency: '$',
    code: 'TT',
    phone_code: '+1-868',
  },
  {
    title: 'Tunisia',
    flag_url: '🇹🇳',
    currency_code: 'TND',
    currency: 'د.ت',
    code: 'TN',
    phone_code: '+216',
  },
  {
    title: 'Turkey',
    flag_url: '🇹🇷',
    currency_code: 'TRY',
    currency: '₺',
    code: 'TR',
    phone_code: '+90',
  },
  {
    title: 'Turkmenistan',
    flag_url: '🇹🇲',
    currency_code: 'TMT',
    currency: 'T',
    code: 'TM',
    phone_code: '+993',
  },
  {
    title: 'Tuvalu',
    flag_url: '🇹🇻',
    currency_code: 'AUD',
    currency: '$',
    code: 'TV',
    phone_code: '+688',
  },
  {
    title: 'Uganda',
    flag_url: '🇺🇬',
    currency_code: 'UGX',
    currency: 'USh',
    code: 'UG',
    phone_code: '+256',
  },
  {
    title: 'Ukraine',
    flag_url: '🇺🇦',
    currency_code: 'UAH',
    currency: '₴',
    code: 'UA',
    phone_code: '+380',
  },
  {
    title: 'United Arab Emirates',
    flag_url: '🇦🇪',
    currency_code: 'AED',
    currency: 'د.إ',
    code: 'AE',
    phone_code: '+971',
  },
  {
    title: 'United Kingdom',
    flag_url: '🇬🇧',
    currency_code: 'GBP',
    currency: '£',
    code: 'GB',
    phone_code: '+44',
  },
  {
    title: 'United States',
    flag_url: '🇺🇸',
    currency_code: 'USD',
    currency: '$',
    code: 'US',
    phone_code: '+1',
  },
  {
    title: 'Uruguay',
    flag_url: '🇺🇾',
    currency_code: 'UYU',
    currency: '$',
    code: 'UY',
    phone_code: '+598',
  },
  {
    title: 'Uzbekistan',
    flag_url: '🇺🇿',
    currency_code: 'UZS',
    currency: 'лв',
    code: 'UZ',
    phone_code: '+998',
  },
  {
    title: 'Vanuatu',
    flag_url: '🇻🇺',
    currency_code: 'VUV',
    currency: 'Vt',
    code: 'VU',
    phone_code: '+678',
  },
  {
    title: 'Vatican City',
    flag_url: '🇻🇦',
    currency_code: 'EUR',
    currency: '€',
    code: 'VA',
    phone_code: '+379',
  },
  {
    title: 'Venezuela',
    flag_url: '🇻🇪',
    currency_code: 'VES',
    currency: 'Bs.S.',
    code: 'VE',
    phone_code: '+58',
  },
  {
    title: 'Vietnam',
    flag_url: '🇻🇳',
    currency_code: 'VND',
    currency: '₫',
    code: 'VN',
    phone_code: '+84',
  },
  {
    title: 'Yemen',
    flag_url: '🇾🇪',
    currency_code: 'YER',
    currency: 'ر.ي',
    code: 'YE',
    phone_code: '+967',
  },
  {
    title: 'Zambia',
    flag_url: '🇿🇲',
    currency_code: 'ZMW',
    currency: 'ZK',
    code: 'ZM',
    phone_code: '+260',
  },
  {
    title: 'Zimbabwe',
    flag_url: '🇿🇼',
    currency_code: 'ZWL',
    currency: '$',
    code: 'ZW',
    phone_code: '+263',
  },
];
