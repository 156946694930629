import { combineReducers } from '@reduxjs/toolkit';
import AuthSlice from './AuthSlice';
import ThemeSlice from './ThemeSlice';

const rootReducer = combineReducers({
  auth: AuthSlice,
  theme: ThemeSlice,
  // Add more reducers here if needed
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
