import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../Redux/slices/AuthSlice';
import { useNavigate } from 'react-router';
import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { globleStyle } from '../../assets/Style/globleStyle';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import Buttons from '../../Component/Buttons';
import Header from '../../Component/AuthLayout/Header';
// import SocialMedia from '../../Component/AuthLayout/SocialMedia';
import { ROLE, roleStoragekey } from '../../constant/role';
import { AUTHPATH, PATH } from '../../Route/Path';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import apiService from '../../API/APIService';
import { decryptData, encryptData } from '../../API/encrypt';
import { Notification } from '../../helper/Notification';
import { passwordRegex } from '../../constant/StaticData';

interface IFormInput {
  email: string;
  password: string;
}

const validationSchema: Yup.ObjectSchema<IFormInput> = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(passwordRegex, 'Password must be at least 8 characters long, contain both letters and numbers, and include at least one special character'),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
  });

  // const values = watch()

  const onSubmit: SubmitHandler<IFormInput> = data => {
    handleLogin(data);
  };

  const handleLogin = async (data: IFormInput) => {
    try {
      const finalData = {
        email: data.email,
        password: data.password,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.post('/v1/api/admin/login', secret_text);
      if (res.status) {
        Notification('success', res.message);
        localStorage.setItem(roleStoragekey, ROLE.user);
        localStorage.setItem(
          'refresh_token',
          res?.data?.authentication?.refresh_token
        );
        localStorage.setItem(
          'access_token',
          res?.data?.authentication?.access_token
        );
        dispatch(login());
        navigate(PATH.home);
      } else {
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Header
      heading="Welcome to Arton! 👋🏻"
      text="Please sign-in to your account and start the adventure"
      card={
        <Box
          component="form"
          sx={{ marginTop: 3, marginBottom: 3 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            type="text"
            sx={{ width: '100%' }}
            placeholder="Email Address"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          {/* <Typography
            variant="body2"
            sx={{ textAlign: 'end', color: '#999999' }}
          >
            {values?.email?.length}/50
          </Typography> */}
          <TextField
            type={showPassword ? 'text' : 'password'}
            sx={{ width: '100%', marginTop: 3 }}
            placeholder="Password"
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <Typography
            variant="body2"
            sx={{ textAlign: 'end', color: '#999999' }}
          >
            {values?.password?.length}/16
          </Typography> */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox />
              <Typography>Remember Me</Typography>
            </Box>
            <Typography
              onClick={() => {
                navigate(AUTHPATH.forgot);
              }}
              variant="body1"
              sx={{
                cursor: 'pointer',
                color: ManageColorTheme(
                  globleStyle.layout.light.heading,
                  globleStyle.layout.dark.heading
                ),
              }}
            >
              Forgot Password?
            </Typography>
          </Box>
          <Buttons
            style={{ width: '100%', marginTop: '20px' }}
            loading={false}
            iconAlign="left"
            iconSize={18}
            icon=""
            variant="button"
            type="submit"
            onClick={() => {}}
            label="SIGN IN"
          />
          {/* <Typography
            variant="body1"
            sx={{ textAlign: 'center', marginTop: 2, marginBottom: 2 }}
          >
            or
          </Typography>
          <SocialMedia /> */}
        </Box>
      }
    />
  );
};

export default Login;
