import { ConfigDataType } from './configType';

export const THEME_MODE = process.env.REACT_APP_THEME_MODE;
export const THEME_MODE_TYPE = process.env.REACT_APP_THEME_MODE_TYPE;
export const BASE_URL = process.env.REACT_APP_API_BASE;
export const S3_URL = process.env.REACT_APP_S3_BUCKET_URL;
export const CreateSecureKey =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const configData: ConfigDataType = {
  CreateSecureKey:
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
  jwt: {
    key: '!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB',
  },
};
