import { Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../../../assets/Image/logo.svg';
import whiteLogo from '../../../assets/Image/whiteLogo.svg';
import { ManageColorTheme } from '../../../helper/ChangeTheme';
import { globleStyle } from '../../../assets/Style/globleStyle';
import SidebarItem from './SidebarItem';
import { valueType } from './type';
import { sidebarData } from './sidebarData';
import { ToggleIcon } from '../../../assets/Image/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from '../../../Route/Path';

export default function Sidebar() {
  const [active, setActive] = useState('');
  const sidebars = localStorage.getItem('sidebar');
  const [sidebar, setSidebar] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const themColor = localStorage.getItem('theme');

  useEffect(() => {
    const sidebarStatus = localStorage.getItem('sidebar');
    if (!sidebarStatus) {
      localStorage.setItem('sidebar', 'full');
    }
  }, [sidebar]);

  useEffect(() => {
    const activePath = location.pathname.split('/');
    setActive(`/${activePath[1]}`);
  }, [location]);

  const handleToggle = (val: string) => {
    if (val === active) {
      setActive('');
    } else {
      setActive(val);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'auto',
        // width: sidebars === 'full' ? '18%' : '0%',
        // position: 'absolute',
        zIndex: '99999',
        transition: 'width 0.2s ease-in-out',
        backgroundColor: ManageColorTheme(
          globleStyle.layout.light.sidebar.bg,
          globleStyle.layout.dark.sidebar.bg
        ),
        borderRightColor: '#EFEFEF',
        borderRightStyle: 'solid',
        borderRightWidth: '2px',
        // Apply custom media queries
        [theme.breakpoints.down('xs')]: {
          position: 'absolute',
          width: sidebars === 'full' ? '100%' : '0%', // Very small devices
        },
        [theme.breakpoints.between('xs', 'sm')]: {
          position: 'absolute',
          width: sidebars === 'full' ? '100%' : '0%', // Small devices
        },
        [theme.breakpoints.between('sm', 'md')]: {
          position: 'absolute',
          width: sidebars === 'full' ? '50%' : '0%', // Medium devices (tablets)
        },
        [theme.breakpoints.between('md', 'lg')]: {
          position: 'absolute',
          width: sidebars === 'full' ? '30%' : '0%', // Large devices (small desktops)
        },
        [theme.breakpoints.between('lg', 'xl')]: {
          width: sidebars === 'full' ? '25%' : '0%', // Extra large devices (desktops)
        },
        [theme.breakpoints.up('xl')]: {
          width: sidebars === 'full' ? '18%' : '0%', // Largest devices
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Box
          onClick={() => {
            navigate(PATH.home);
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent: 'center',
            marginBottom: '10px',
            height: '7vh',
          }}
        >
          {themColor === 'light' ? (
            <img src={logo} alt="logo" width="100%" height="100%" />
          ) : (
            <img src={whiteLogo} alt="logo" width="100%" height="100%" />
          )}
        </Box>
        <Box
          onClick={() => {
            localStorage.setItem('sidebar', sidebar === true ? 'mini' : 'full');
            setSidebar(!sidebar);
          }}
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            top: '5.7vh',
            right: '0px',
            // Apply custom media queries
            [theme.breakpoints.down('xs')]: {
              display: 'block',
            },
            [theme.breakpoints.between('xs', 'sm')]: {
              display: 'block',
            },
            [theme.breakpoints.between('sm', 'md')]: {
              display: 'block',
            },
            [theme.breakpoints.between('md', 'lg')]: {
              display: 'block',
            },
            [theme.breakpoints.between('lg', 'xl')]: {
              display: 'none',
            },
            [theme.breakpoints.up('xl')]: {
              display: 'none',
            },
          }}
        >
          <ToggleIcon />
        </Box>
        {/* <Typography
          variant="caption"
          sx={{
            marginLeft: '20%',
            color: ManageColorTheme('#999999', '#ffffff'),
          }}
        >
          APPS & PAGES
        </Typography> */}
        <Box sx={{ marginTop: '0px' }}>
          {sidebarData?.map((val: valueType, i: number) => {
            return (
              <SidebarItem
                key={i}
                val={val}
                status={active === val.path ? true : false}
                handleToggle={(val: string) => {
                  handleToggle(val);
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
