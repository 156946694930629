import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, TextField, Typography } from '@mui/material';
import Buttons from '../../../../Component/Buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import UploadBox from '../../../../Component/UploadBox';
import { useEffect, useState } from 'react';
import apiService from '../../../../API/APIService';
import { decryptData, encryptData } from '../../../../API/encrypt';
import { Notification } from '../../../../helper/Notification';
import { PATH } from '../../../../Route/Path';
import { headerSet } from '../../../../API/Header';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { S3_URL } from '../../../../config';

interface IFormInput {
  title: string;
  content: string;
}

interface FileData {
  // eslint-disable-next-line
  image: any; // Changed to 'string'
  // eslint-disable-next-line
  file: any;
}

const validationSchema: Yup.ObjectSchema<IFormInput> = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  content: Yup.string().required('Content is required'),
});

export default function Form(props: { isEdit: boolean }) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const splitPath = location.pathname.split('/');
  const [cmsId, setCmsId] = useState('');
  const [imageData, setImageData] = useState<FileData[]>([]);
  const [newImage, setNewImage] = useState<File[]>([]);
  const [newOldImage, setNewOldImage] = useState<string[]>([]);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
  });

  const values = watch();

  const getCMSData = async (key: string) => {
    try {
      const res = await apiService.get(
        `/v1/api/master/cms-detail?cms_key=${key}`,
        headerSet(false)
      );
      if (res.status) {
        setValue('title', res.data.title);
        setValue('content', res.data.content);
        setCmsId(res.data.id);
        setImageData(
          res.data.image ? [{ image: S3_URL + res.data.image, file: null }] : []
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (props.isEdit) {
      getCMSData(splitPath[2]);
    }
  }, [location]);

  const addData = async (data: IFormInput) => {
    setLoading(true);
    try {
      const finalData = {
        title: data.title,
        content: data.content,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const formData = new FormData();
      formData.append('secret_text', incriptData);
      formData.append('image', newImage[0]);
      const res = await apiService.post(
        '/v1/api/admin/cms-create',
        formData,
        headerSet(true)
      );
      if (res.status) {
        setLoading(true);
        Notification('success', res.message);
        navigate(PATH.cms);
      } else {
        setLoading(false);
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  const EditData = async (data: IFormInput) => {
    setLoading(true);
    try {
      const finalData = {
        cms_id: cmsId,
        title: data.title,
        image: newOldImage?.length > 0 ? newOldImage[0] : '',
        content: data.content,
        status: 0,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const formData = new FormData();
      formData.append('secret_text', incriptData);
      if (newImage?.length > 0) {
        formData.append('image', newImage[0]);
      }
      const res = await apiService.put(
        '/v1/api/admin/cms-update',
        formData,
        headerSet(true)
      );
      if (res.status) {
        setLoading(true);
        Notification('success', res.message);
        navigate(PATH.cms);
      } else {
        setLoading(false);
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (props.isEdit) {
      EditData(data);
    } else {
      addData(data);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Title"
        variant="outlined"
        type="text"
        value={values.title}
        fullWidth
        {...register('title')}
        error={!!errors.title}
        helperText={errors.title?.message}
        InputLabelProps={{ shrink: !!values.title || undefined }}
      />
      <Box sx={{ marginTop: 5, marginBottom: 5 }}>
        <Controller
          name="content"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CKEditor
              editor={ClassicEditor}
              data={value}
              onChange={(event, editor) => {
                const data = editor.getData();
                onChange(data);
              }}
              config={{
                placeholder: 'Type your content here...',
              }}
            />
          )}
        />
        {errors.content && (
          <Typography variant="caption" sx={{ color: '#d32f2f' }}>
            {errors.content.message}
          </Typography>
        )}
      </Box>
      <UploadBox
        label="Upload Image"
        id="cms-image"
        error={false}
        type="image"
        select="Select image jpg, svg, png, gif, jpeg"
        imageData={imageData}
        validation={['jpg', 'jpeg', 'svg', 'png', 'Svg', 'gif']}
        helperText={''}
        getFileData={(newImage: File[], oldImage: string[]) => {
          setNewImage(newImage);
          setNewOldImage(oldImage);
        }}
        multiple={false}
        size={500000000}
      />
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Buttons
          type="submit"
          label={props.isEdit ? 'Save' : 'Create'}
          variant="button"
          iconAlign="left"
          loading={loading}
          onClick={() => {}}
        />
        <Buttons
          type="button"
          label="Cancel"
          variant="outline"
          iconAlign="left"
          style={{ marginLeft: 2 }}
          onClick={() => {
            navigate('/cms');
          }}
        />
      </Box>
    </Box>
  );
}
