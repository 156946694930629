import { Box, Typography } from '@mui/material';
import React from 'react';
import Buttons from '../Buttons';

export default function ActiveModel(props: {
  id: string | number;
  handleClose: () => void;
  handleDelete: (id: string | number) => void;
  description: string;
  loading: boolean;
  label: string;
}) {
  return (
    <div style={{ padding: 10, paddingBottom: 20, width: 500 }}>
      <Typography variant="h3">Change Status</Typography>
      <Typography variant="subtitle1">{props.description}</Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginTop: 5,
        }}
      >
        <Buttons
          variant="outline"
          onClick={() => {
            props.handleClose();
          }}
          iconAlign="left"
          label="Cancel"
        />
        <Box sx={{ marginLeft: 2 }}>
          <Buttons
            variant="button"
            loading={props.loading}
            iconAlign="left"
            style={{
              backgroundColor:
                props.label === 'Active' ||
                props.label === 'Verify' ||
                props.label === 'Yes'
                  ? ''
                  : 'rgb(211, 47, 47)',
            }}
            onClick={() => {
              props.handleDelete(props.id);
            }}
            label={props.label}
          />
        </Box>
      </Box>
    </div>
  );
}
