import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';
import { CloseIconRed, DocumentIcon } from '../../assets/Image/Icon';
import { FileIcon } from 'react-file-icon';
import { Notification } from '../../helper/Notification';
import { S3_URL } from '../../config';

interface UploadBoxProps {
  getFileData: (newImage: File[], oldImage: string[]) => void;
  label: string;
  error: boolean;
  helperText: string;
  multiple?: boolean;
  type: 'file' | 'image' | 'video';
  validation: string[];
  size: number;
  id: string;
  imageData: FileData[];
  select: string;
}

interface FileData {
  // eslint-disable-next-line
  image: any; // Changed to 'string'
  // eslint-disable-next-line
  file: any;
}

export default function UploadBox(props: UploadBoxProps) {
  const [fileData, setFileData] = useState<FileData[]>([]);
  const [selectFile, setSelectFile] = useState<string>('');
  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    setFileData(props.imageData);
    setSelectFile(props?.imageData[0]?.image);
  }, [props.imageData]);

  const fileValidation = (files: File) => {
    const type = files.type.split('/');
    const sizee = files.size;
    if (props.validation.includes(type[1])) {
      if (sizee < props.size) {
        uploadImage(files);
      } else {
        Notification('error', `file size to larg.`);
      }
    } else {
      Notification('error', `Please enter valide ${props.type}`);
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      fileValidation(files[0]);
      event.target.value = '';
    }
  };

  const handleTextFieldClick = () => {
    document.getElementById(props.id)?.click();
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = fileData.filter((_, i) => i !== index);
    setSelectFile(
      updatedFiles?.length > 0
        ? updatedFiles[updatedFiles?.length - 1].image
        : ''
    );
    setFileData(updatedFiles);
  };

  const convertFileToBase64 = (
    file: File
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }
    });
  };

  const uploadImage = async (files: File) => {
    const base64Url = await convertFileToBase64(files);
    if (base64Url) {
      const filesData = [...fileData];
      filesData.push({ image: base64Url, file: files });
      setSelectFile(`${base64Url}`);
      setFileData(filesData);
    }
  };

  useEffect(() => {
    const newImage = [];
    const oldImage = [];
    for (let index = 0; index < fileData.length; index++) {
      const element = fileData[index];
      if (element.file) {
        newImage.push(element.file);
      } else {
        const trimmedImage = element.image.replace(S3_URL, '');
        oldImage.push(trimmedImage);
      }
    }
    props.getFileData(newImage, oldImage);
  }, [fileData]);

  function formatFilename(filename: string) {
    const parts = filename.split('.');
    const extension = parts.pop();
    const name = parts.join('.');
    const truncatedName = name.length > 10 ? name.slice(0, 20) : name;
    return `${truncatedName}.${extension}`;
  }

  function formatFileSize(bytes: number) {
    if (bytes < 1024) {
      return `${bytes} B`; // Bytes
    } else if (bytes < 1024 * 1024) {
      return `${(bytes / 1024).toFixed(2)} KB`; // Kilobytes
    } else if (bytes < 1024 * 1024 * 1024) {
      return `${(bytes / (1024 * 1024)).toFixed(2)} MB`; // Megabytes
    } else {
      return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`; // Gigabytes
    }
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files) {
      fileValidation(files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files) {
      fileValidation(files[0]);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  console.log('isDragging', isDragging);

  return (
    <Box>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        {props.label}
      </Typography>
      <input
        id={props.id}
        type="file"
        accept={
          props?.type === 'file'
            ? '.pdf,.doc,.docx,.xls,.xlsx,.txt,.ppt,.pptx,.zip,.rar,.csv'
            : `${props.type}/*`
        }
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple={props.multiple}
      />
      {fileData?.length > 0 && props.type !== 'file' ? (
        props?.type === 'video' ? (
          <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            sx={{
              backgroundColor: ManageColorTheme('#F6F7F9', '#121212'),
              borderRadius: 2,
              borderStyle: 'dashed',
              position: 'relative',
              borderWidth: 2,
              borderColor: ManageColorTheme(
                globleStyle.layout.light.activeColor,
                globleStyle.layout.dark.activeColor
              ),
            }}
            onClick={
              props.multiple
                ? () => {
                    handleTextFieldClick();
                  }
                : () => {}
            }
          >
            <video
              height={250}
              width="100%"
              style={{ objectFit: 'contain' }}
              controls
              src={selectFile}
            />
            {!props.multiple && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 2,
                  top: 2,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setFileData([]);
                  setSelectFile('');
                }}
              >
                <CloseIconRed />
              </Box>
            )}
          </Box>
        ) : (
          <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            sx={{
              backgroundColor: ManageColorTheme('#F6F7F9', '#121212'),
              borderRadius: 2,
              borderStyle: 'dashed',
              position: 'relative',
              borderWidth: 2,
              borderColor: ManageColorTheme(
                globleStyle.layout.light.activeColor,
                globleStyle.layout.dark.activeColor
              ),
            }}
            onClick={
              props.multiple
                ? () => {
                    handleTextFieldClick();
                  }
                : () => {}
            }
          >
            <img
              height={250}
              width="100%"
              style={{ objectFit: 'contain' }}
              src={selectFile}
              alt={fileData[0]?.file?.name}
            />
            {!props.multiple && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 2,
                  top: 2,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setFileData([]);
                  setSelectFile('');
                }}
              >
                <CloseIconRed />
              </Box>
            )}
          </Box>
        )
      ) : (
        <Box
          sx={{
            padding: 5,
            backgroundColor: ManageColorTheme('#F6F7F9', '#121212'),
            borderRadius: 2,
            borderStyle: 'dashed',
            borderWidth: 2,
            borderColor: props.error
              ? '#d32f2f'
              : ManageColorTheme(
                  globleStyle.layout.light.activeColor,
                  globleStyle.layout.dark.activeColor
                ),
          }}
          onClick={
            props.multiple
              ? () => {
                  handleTextFieldClick();
                }
              : fileData?.length < 1
                ? () => {
                    handleTextFieldClick();
                  }
                : () => {}
          }
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 3,
            }}
          >
            <DocumentIcon />
          </Box>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Drag & Drop or
            <Typography
              variant="body1"
              sx={{
                marginX: 0.5,
                color: ManageColorTheme(
                  globleStyle.layout.light.activeColor,
                  globleStyle.layout.dark.activeColor
                ),
              }}
            >
              choose
            </Typography>
            file to upload
          </Typography>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            {props.select}
          </Typography>
        </Box>
      )}
      {props.helperText && (
        <Typography variant="caption" sx={{ color: '#d32f2f' }}>
          {props.helperText}
        </Typography>
      )}
      {props.type === 'image' || props.type === 'video' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            marginTop: 2,
          }}
        >
          {props.multiple && fileData?.length > 0 ? (
            <Box
              sx={{
                paddingTop: 1,
                height: 100,
                width: 100,
                backgroundColor: ManageColorTheme('#F6F7F9', '#121212'),
                marginTop: -1,
                marginRight: 2,
                borderRadius: 1,
                borderStyle: 'dashed',
                borderWidth: 2,
                borderColor: ManageColorTheme(
                  globleStyle.layout.light.activeColor,
                  globleStyle.layout.dark.activeColor
                ),
              }}
              onClick={() => handleTextFieldClick()}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 1,
                }}
              >
                <DocumentIcon />
              </Box>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                Drop or choose file to upload
              </Typography>
            </Box>
          ) : null}
          {props.multiple && fileData?.length > 0
            ? fileData.map((file, index) => (
                <Box key={index} sx={{ position: 'relative', marginRight: 2 }}>
                  {props.type === 'video' ? (
                    <video
                      onClick={() => {
                        setSelectFile(file.image);
                      }}
                      height={100}
                      width={100}
                      style={{ objectFit: 'contain' }}
                      controls
                      src={file.image}
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setSelectFile(file.image);
                      }}
                      height={100}
                      width={100}
                      style={{ borderRadius: 5, objectFit: 'cover' }}
                      src={file.image}
                      alt={file?.file?.name}
                    />
                  )}

                  <Box
                    sx={{
                      position: 'absolute',
                      right: -5,
                      top: -5,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleRemoveFile(index);
                    }}
                  >
                    <CloseIconRed />
                  </Box>
                </Box>
              ))
            : null}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            marginTop: 2,
          }}
        >
          {fileData?.map((val, i) => {
            const extension = val?.file?.name?.split('.')?.pop()?.toLowerCase();
            return (
              <Box
                key={i}
                sx={{
                  backgroundColor: '#F6F7F9',
                  position: 'relative',
                  borderRadius: 2,
                  p: 1,
                  maxWidth: '20%',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 2,
                  marginTop: 3,
                }}
              >
                <Box sx={{ width: 40, height: 50 }}>
                  <FileIcon extension={extension} />
                </Box>
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="caption">
                    {formatFilename(val?.file?.name)}
                  </Typography>
                  <br />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="caption">
                      {formatFileSize(val.file.size)}
                    </Typography>
                    {/* <Typography variant='caption'>4 second left</Typography> */}
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    right: -5,
                    top: -5,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleRemoveFile(i)}
                >
                  <CloseIconRed />
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
