export const globleStyle = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // access Button Design in theme
  layout: {
    light: {
      tableFooterBg: '#FCFCFC',
      tableHeaderBg: '#FCFCFC',
      tableRowBg: '#ffffff',
      tableRowBgHover: '#FCFCFC',
      hoverColor: '#34aadc',
      activeColor: '#34aadc',
      heading: '#34aadc',
      color: '#000000',
      description: '#999999',
      lightText: '#ffffff',
      sidebar: {
        bg: '#ffffff',
        hover: '#34aadc',
        active: '#666CFF',
        textActive: '#000000',
        textHover: '#ffffff',
      },
      header: '#ffffff',
      main: '#ffffff',
    },
    dark: {
      tableFooterBg: '#242424',
      tableHeaderBg: '#242424',
      tableRowBg: '#000000',
      tableRowBgHover: '#242424',
      hoverColor: '#34aadc',
      activeColor: '#34aadc',
      heading: '#34aadc',
      color: '#ffffff',
      description: '#ffffff',
      lightText: '#ffffff',
      sidebar: {
        bg: '#000000',
        hover: '#34aadc',
        active: '#858afe',
        textActive: '#ffffff',
        textHover: '#ffffff',
      },
      header: '#000000',
      main: '#000000',
    },
  },
  button: {
    borderRadius: '4px',
    light: {
      borderRadius: '4px',
    },
    dark: {
      borderRadius: '4px',
    },
  },

  // access font fimily in theme

  font: {
    type: '"Inter", sans-serif',
  },
};
