import React, { useState } from 'react';
import Header from '../../Component/AuthLayout/Header';
import { Box, TextField, Typography } from '@mui/material';
import Buttons from '../../Component/Buttons';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';
import { useNavigate } from 'react-router-dom';
import { AUTHPATH } from '../../Route/Path';
import apiService from '../../API/APIService';
import { decryptData, encryptData } from '../../API/encrypt';
import { Notification } from '../../helper/Notification';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onSubmit = async () => {
    try {
      setLoading(true);
      if (email !== '') {
        if (emailRegex.test(email)) {
          const finalData = {
            email: email,
          };
          const incriptData = (await encryptData(finalData)) as string;
          const secret_text = {
            secret_text: incriptData,
          };
          const res = await apiService.put(
            'v1/api/admin/forget-password',
            secret_text
          );
          if (res.status) {
            navigate(AUTHPATH.verify, { state: { email: email } });
            setLoading(false);
            Notification('success', res.message);
          } else {
            Notification('error', res.message);
            setLoading(false);
          }
        } else {
          setLoading(false);
          setEmailError('Invalid email address.');
        }
      } else {
        setLoading(false);
        setEmailError('Please Enter Email Address');
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Header
        heading="Forgot Password 🔒"
        text="Enter your email and we'll send you instructions to reset your password"
        card={
          <Box>
            <TextField
              type="text"
              sx={{ width: '100%' }}
              onChange={e => {
                setEmail(e.target.value);
              }}
              placeholder="Email Address"
            />
            <Typography variant="caption" sx={{ color: '#d32f2f' }}>
              {emailError}
            </Typography>
            {/* <Typography
              variant="body2"
              sx={{ textAlign: 'end', color: '#999999' }}
            >
              20/2000
            </Typography> */}
            <Buttons
              style={{ width: '100%', marginTop: '20px' }}
              loading={loading}
              iconAlign="left"
              iconSize={18}
              icon=""
              variant="button"
              onClick={() => {
                onSubmit();
              }}
              label="SUBMIT"
            />
            <Box
              onClick={() => {
                navigate(AUTHPATH.root);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '5%',
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  marginTop: 0.8,
                  marginRight: 1,
                  color: ManageColorTheme(
                    globleStyle.layout.light.heading,
                    globleStyle.layout.dark.heading
                  ),
                }}
              >
                <ArrowBackIosIcon />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  cursor: 'pointer',
                  color: ManageColorTheme(
                    globleStyle.layout.light.heading,
                    globleStyle.layout.dark.heading
                  ),
                }}
              >
                Back to login
              </Typography>
            </Box>
          </Box>
        }
      />
    </div>
  );
}
