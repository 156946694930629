import { Box, Card, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { userTablePropsType } from './type';
import DropdownMenu from '../../../../../Component/Dropdown';
import { ActionIcon } from '../../../../../assets/Image/Icon';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Models from '../../../../../Component/Model';
import DeleteModel from '../../../../../Component/Table/DeleteModel';
import EditIcon from '@mui/icons-material/Edit';
import apiService from '../../../../../API/APIService';
import { Notification } from '../../../../../helper/Notification';
import { S3_URL } from '../../../../../config';
import { ManageColorTheme } from '../../../../../helper/ChangeTheme';

export default function UserTableRow(props: userTablePropsType) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id: string | number) => {
    setLoading(true);
    const res = await apiService.delete(
      `/v1/api/admin/cms-delete?cms_id=${id}`
    );
    if (res.status) {
      Notification('success', res.message);
      handleClose();
      setLoading(false);
      props.getData();
    } else {
      Notification('error', res.message);
      setLoading(false);
    }
  };

  const dropdownData = [
    {
      name: (
        <MenuItem
          onClick={() => {
            navigate(`/cms/${props?.data?.cms_key}`);
          }}
        >
          <EditIcon />
          &nbsp;&nbsp;Edit
        </MenuItem>
      ),
      icon: '',
    },
    {
      name: (
        <Models
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          button={
            <MenuItem sx={{ color: 'rgb(211, 47, 47)' }}>
              <DeleteIcon />
              &nbsp;&nbsp;Delete
            </MenuItem>
          }
          content={
            <DeleteModel
              handleDelete={handleDelete}
              description="You want to delete CMS"
              handleClose={handleClose}
              loading={loading}
              id={props?.data?.id}
            />
          }
        />
      ),
      icon: <DeleteIcon />,
    },
  ];

  return (
    <Card variant="elevation" sx={{ marginBottom: 2 }}>
      <Box
        onClick={() => {
          props.setOpenSlice(props.data.cms_key);
        }}
        sx={{
          padding: 1,
          cursor: 'pointer',
          backgroundColor: ManageColorTheme('#ebebeb', 'black'),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="h6">
          {props.data?.title}
          <Typography sx={{ color: '#afafaf' }} variant="h6">
            ( {props.data?.cms_key} )
          </Typography>
        </Typography>
        <DropdownMenu
          button={
            <Box sx={{ borderRadius: '100%' }}>
              <ActionIcon />
            </Box>
          }
          data={dropdownData}
        />
      </Box>
      {props.openSlice === props.data.cms_key && (
        <Box>
          {props.data.image && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img width={200} height={200} src={S3_URL + props.data.image} />
            </Box>
          )}
          <Box sx={{ padding: 1 }}>
            <div
              dangerouslySetInnerHTML={{ __html: `${props.data.content}` }}
            />
          </Box>
        </Box>
      )}
    </Card>
  );
}
