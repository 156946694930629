import React from 'react';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { PATH } from '../../../../Route/Path';
import { Box } from '@mui/material';
import Form from '../Form';

export default function CMSEdit() {
  return (
    <div>
      <BredCrums
        heading="Edit CMS"
        path={[
          { name: 'Dashboard', path: PATH.home },
          { name: 'CMS List', path: PATH.cms },
          { name: 'CMS Edit', path: '' },
        ]}
        description="Edit your company’s CMS."
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />
      <Box sx={{ marginTop: '3%' }}>
        <Form isEdit={true} />
      </Box>
    </div>
  );
}
