import React from 'react';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { Box } from '@mui/material';
import { PATH } from '../../../../Route/Path';
import Form from '../Form';

export default function CreateArtStyle() {
  return (
    <div>
      <BredCrums
        heading="Create Art Style"
        path={[
          { name: 'Dashboard', path: PATH.home },
          { name: 'Art Styles', path: '/master' + PATH.ArtStyleList },
          { name: 'Create Art Style', path: '' },
        ]}
        description="Add new art style."
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />
      <Box sx={{ marginTop: '3%' }}>
        <Form isEdit={false} />
      </Box>
    </div>
  );
}
