import React from 'react';
import Login from '../Screen/Auth/Login';
// import Signup from '../Screen/Auth/Signup';
import Notfound from '../Screen/Error/Notfound';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import { ROLE } from '../constant/role';
import { AUTHPATH, PATH } from './Path';
import { ProtectedRoute } from './ProtectedRoute';
import ForgotPassword from '../Screen/Auth/ForgotPassword';
import ResetPassword from '../Screen/Auth/ResetPassword';
import EmailVerify from '../Screen/Auth/EmailVerify';
// import TwoStepVerification from '../Screen/Auth/TwoStepVerification';
import List from '../Screen/Main/User/Table';
import Dashboard from '../Screen/Main/Dashboard';
import CMSList from '../Screen/Main/Cms/Table';
import CMSCreate from '../Screen/Main/Cms/Create';
import CMSEdit from '../Screen/Main/Cms/Edit';
import ArtList from '../Screen/Main/Art/Table';
import CatogaryList from '../Screen/Main/Category/Table';
import CreateCategory from '../Screen/Main/Category/Create';
import CategoryEdit from '../Screen/Main/Category/Edit';
import SubCatogaryList from '../Screen/Main/SubCatogary/Table';
import SubCreateCategory from '../Screen/Main/SubCatogary/Create';
import SubCategoryEdit from '../Screen/Main/SubCatogary/Edit';
import OrderList from '../Screen/Main/Order/Table';
import OrderItemList from '../Screen/Main/OrderItem/Table';
import ArtStyleList from '../Screen/Main/ArtStyle/Table';
import CreateArtStyle from '../Screen/Main/ArtStyle/Create';
import ArtStyleEdit from '../Screen/Main/ArtStyle/Edit';
import PaymentList from '../Screen/Main/Payment/Table';
import CommissionList from '../Screen/Main/Commission/Table';
import Profile from '../Screen/Main/Profile';
import SettingList from '../Screen/Main/Setting/Table';
import CreateSetting from '../Screen/Main/Setting/Create';
import SettingEdit from '../Screen/Main/Setting/Edit';
import SellerRequestList from '../Screen/Main/SellerRequest/Table';
import CountryList from '../Screen/Main/Country/Table';
import CreateCountry from '../Screen/Main/Country/Create';
import CountryEdit from '../Screen/Main/Country/Edit';
import Notification from '../Screen/Main/Notification';

export default function Routes() {
  return (
    <RouterRoutes>
      <Route
        path={AUTHPATH.root}
        element={
          <ProtectedRoute element={<Login />} allowedRoles={[ROLE.auth]} />
        }
      />
      {/* <Route
        path={AUTHPATH.signup}
        element={
          <ProtectedRoute element={<Signup />} allowedRoles={[ROLE.auth]} />
        }
      /> */}

      <Route
        path={AUTHPATH.forgot}
        element={
          <ProtectedRoute
            element={<ForgotPassword />}
            allowedRoles={[ROLE.auth]}
          />
        }
      />

      <Route
        path={AUTHPATH.verify}
        element={
          <ProtectedRoute
            element={<EmailVerify />}
            allowedRoles={[ROLE.auth]}
          />
        }
      />

      <Route
        path={AUTHPATH.reset}
        element={
          <ProtectedRoute
            element={<ResetPassword />}
            allowedRoles={[ROLE.auth]}
          />
        }
      />

      {/* <Route
        path={AUTHPATH.twoStep}
        element={
          <ProtectedRoute
            element={<TwoStepVerification />}
            allowedRoles={[ROLE.auth]}
          />
        }
      /> */}

      <Route
        path={PATH.home}
        element={
          <ProtectedRoute element={<Dashboard />} allowedRoles={[ROLE.user]} />
        }
      />

      <Route
        path={`/users${PATH.userList}`}
        element={
          <ProtectedRoute
            element={<List type="buyer" label="Buyers" />}
            allowedRoles={[ROLE.user]}
          />
        }
      />
      <Route
        path={`/users${PATH.seller}`}
        element={
          <ProtectedRoute
            element={<List type="seller" label="Sellers" />}
            allowedRoles={[ROLE.user]}
          />
        }
      />
      <Route
        path={`/users${PATH.buyerAndSeller}`}
        element={
          <ProtectedRoute
            element={<List type="Seller_and_Buyer" label="Buyers & Sellers" />}
            allowedRoles={[ROLE.user]}
          />
        }
      />
      <Route
        path={PATH.cms}
        element={
          <ProtectedRoute element={<CMSList />} allowedRoles={[ROLE.user]} />
        }
      />

      <Route
        path={PATH.cmsCreate}
        element={
          <ProtectedRoute element={<CMSCreate />} allowedRoles={[ROLE.user]} />
        }
      />

      <Route
        path={PATH.editCms}
        element={
          <ProtectedRoute element={<CMSEdit />} allowedRoles={[ROLE.user]} />
        }
      />

      <Route
        path={PATH.artList}
        element={
          <ProtectedRoute element={<ArtList />} allowedRoles={[ROLE.user]} />
        }
      />

      <Route
        path={'/master' + PATH.CatogaryList}
        element={
          <ProtectedRoute
            element={<CatogaryList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />
      <Route
        path={'/master' + PATH.CatogaryCreate}
        element={
          <ProtectedRoute
            element={<CreateCategory />}
            allowedRoles={[ROLE.user]}
          />
        }
      />
      <Route
        path={'/master' + PATH.CatogaryEdit}
        element={
          <ProtectedRoute
            element={<CategoryEdit />}
            allowedRoles={[ROLE.user]}
          />
        }
      />
      <Route
        path={'/master' + PATH.SubCatogaryList}
        element={
          <ProtectedRoute
            element={<SubCatogaryList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />
      <Route
        path={'/master' + PATH.SubCatogaryCreate}
        element={
          <ProtectedRoute
            element={<SubCreateCategory />}
            allowedRoles={[ROLE.user]}
          />
        }
      />
      <Route
        path={'/master' + PATH.SubCatogaryEdit}
        element={
          <ProtectedRoute
            element={<SubCategoryEdit />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/sales' + PATH.OrderListItem}
        element={
          <ProtectedRoute
            element={<OrderItemList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/sales' + PATH.OrderList}
        element={
          <ProtectedRoute element={<OrderList />} allowedRoles={[ROLE.user]} />
        }
      />

      <Route
        path={'/master' + PATH.ArtStyleList}
        element={
          <ProtectedRoute
            element={<ArtStyleList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/master' + PATH.ArtStyleCreate}
        element={
          <ProtectedRoute
            element={<CreateArtStyle />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/master' + PATH.ArtStyleEdit}
        element={
          <ProtectedRoute
            element={<ArtStyleEdit />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/sales' + PATH.PaymentList}
        element={
          <ProtectedRoute
            element={<PaymentList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/sales' + PATH.CommissionList}
        element={
          <ProtectedRoute
            element={<CommissionList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={PATH.profile}
        element={
          <ProtectedRoute element={<Profile />} allowedRoles={[ROLE.user]} />
        }
      />

      <Route
        path={PATH.setting}
        element={
          <ProtectedRoute
            element={<SettingList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={PATH.settingCreate}
        element={
          <ProtectedRoute
            element={<CreateSetting />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={PATH.settingEdit}
        element={
          <ProtectedRoute
            element={<SettingEdit />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={`/users${PATH.SellerRequest}`}
        element={
          <ProtectedRoute
            element={<SellerRequestList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/master' + PATH.CountryList}
        element={
          <ProtectedRoute
            element={<CountryList />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/master' + PATH.CountryCreate}
        element={
          <ProtectedRoute
            element={<CreateCountry />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={'/master' + PATH.CountryEdit}
        element={
          <ProtectedRoute
            element={<CountryEdit />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route
        path={PATH.notification}
        element={
          <ProtectedRoute
            element={<Notification />}
            allowedRoles={[ROLE.user]}
          />
        }
      />

      <Route path={PATH.notFound} element={<Notfound />} />
    </RouterRoutes>
  );
}
