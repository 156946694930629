import { HeaderType } from './type';

export const CreateFilter = (headers: HeaderType[]) => {
  type ObjType = {
    [key: string]: string | Date;
  };
  const obj: ObjType = {};
  for (let i = 0; i < headers.length; i++) {
    const element: HeaderType = headers[i];
    if (element.search && element.value !== '') {
      obj[`${element.filterQuery}`] = element.value;
    }
  }

  return obj;
};
