/* eslint-disable */

import * as CryptoJS from 'crypto-js';
import { configData } from '../config';

export interface DecrytpData {
  data: any;
  status: boolean;
  message: string;
  status_code: number;
  request_status_code: number;
}

function createSecureKey() {
  return new Promise(async resolve => {
    let result = '';
    const characters: string = configData?.CreateSecureKey!;
    const charactersLength: number = characters?.length;
    let counter = 0;
    while (counter < 128) {
      result += characters?.charAt(
        Math.floor(Math.random() * charactersLength)
      );
      counter += 1;
    }
    resolve(result);
  });
}

export async function encryptData<T>(value: T) {
  try {
    let encKey = (await createSecureKey()) as string;
    let encrypt = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      encKey
    ).toString();
    return encKey + encrypt;
  } catch (error) {
    return error;
  }
}

export function decryptData(encryptedString: string): Promise<DecrytpData> {
  return new Promise(async (resolve, reject) => {
    try {
      const length = 128;
      const CryptoSecretKey = encryptedString.substring(0, length).toString();
      const data = encryptedString
        .substring(length, encryptedString?.length)
        .toString();
      let bytes = CryptoJS.AES.decrypt(data, CryptoSecretKey);
      if (!bytes) {
        reject('Invalid decrypted data');
        return;
      }
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      resolve(decryptedData);
    } catch (error) {
      console.error('Error while decrypting and parsing data:', error);
      reject(null);
    }
  });
}
