import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, useTheme } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import { ToggleIcon } from '../../assets/Image/Icon';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';
import { authData } from '../../Route/Path';
import Routes from '../../Route/Routes';

export default function Layout() {
  const [sidebar, setSidebar] = useState(true);
  const sidebarStatus = localStorage.getItem('sidebar');
  const theme = useTheme();

  useEffect(() => {
    if (!sidebarStatus) {
      localStorage.setItem('sidebar', 'full');
    }
  }, [sidebar]);

  const location = useLocation();
  const pathSplit = location.pathname.split('/');

  return !authData.includes(`/${pathSplit[1]}`) ? (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Header />
        <Box
          onClick={() => {
            setSidebar(!sidebar);
            localStorage.setItem('sidebar', sidebar === true ? 'mini' : 'full');
          }}
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            top: sidebarStatus === 'mini' ? 45 : 55,
            left: 0,
            transform:
              sidebarStatus !== 'mini' ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 0.3s ease',
          }}
        >
          <ToggleIcon />
        </Box>
        <Box
          sx={{
            height: '93vh',
            backgroundColor: ManageColorTheme(
              globleStyle.layout.light.main,
              globleStyle.layout.dark.main
            ),
            width: '100%',
            padding: '20px',
          }}
        >
          <Card
            sx={{
              border: 'none',
              borderRadius: '4px',
              padding: '20px',
              boxShadow: '4px 10px 20px 0px #919eab2e',
              overflow: 'auto',
              [theme.breakpoints.down('xs')]: {
                height: '88vh',
              },
              [theme.breakpoints.between('xs', 'sm')]: {
                height: '88vh',
              },
              [theme.breakpoints.between('sm', 'md')]: {
                height: '88vh',
              },
              [theme.breakpoints.between('md', 'lg')]: {
                height: '82vh',
              },
              [theme.breakpoints.between('lg', 'xl')]: {
                height: '82vh',
              },
              [theme.breakpoints.up('xl')]: {
                height: '82vh',
              },
              '&::-webkit-scrollbar': {
                width: '2px', // Scrollbar width
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#e4e4e4', // Track color
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#b2b2b2', // Thumb color
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555', // Thumb color on hover
              },
            }}
            variant="outlined"
          >
            <Routes />
          </Card>
          <Box
            sx={{
              // Apply custom media queries
              [theme.breakpoints.down('xs')]: {
                display: 'none',
              },
              [theme.breakpoints.between('xs', 'sm')]: {
                display: 'none',
              },
              [theme.breakpoints.between('sm', 'md')]: {
                display: 'none',
              },
              [theme.breakpoints.between('md', 'lg')]: {
                display: 'block',
              },
              [theme.breakpoints.between('lg', 'xl')]: {
                display: 'block',
              },
              [theme.breakpoints.up('xl')]: {
                display: 'block',
              },
            }}
          >
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box>
      <Routes />
    </Box>
  );
}
