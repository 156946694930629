import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, TextField } from '@mui/material';
import Buttons from '../../../../Component/Buttons';

import { useLocation, useNavigate } from 'react-router-dom';
import UploadBox from '../../../../Component/UploadBox';
import { decryptData, encryptData } from '../../../../API/encrypt';
import apiService from '../../../../API/APIService';
import { headerSet } from '../../../../API/Header';
import { Notification } from '../../../../helper/Notification';
import { AUTHPATH, PATH } from '../../../../Route/Path';
import { S3_URL } from '../../../../config';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/slices/AuthSlice';
import { roleStoragekey } from '../../../../constant/role';

interface IFormInput {
  firstName: string;
}

interface FileData {
  // eslint-disable-next-line
  image: any; // Changed to 'string'
  // eslint-disable-next-line
  file: any;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Category name is required.'),
});

export default function Form(props: { isEdit: boolean }) {
  const [imageData, setImageData] = useState<FileData[]>([]);
  const [newImage, setNewImage] = useState<File[]>([]);
  const [newOldImage, setNewOldImage] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const splitPath = location.pathname.split('/');

  useEffect(() => {
    setImageData([]);
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
  });

  const values = watch();

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getData = async (key: string) => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/get-category-details?id=${key}`,
        headerSet(false)
      );
      if (res.status) {
        setValue('firstName', res.data.name);
        setImageData([{ image: S3_URL + res.data.image, file: null }]);
      }
    } catch (error) {
      console.log('error', error);
      handleLogout();
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      getData(splitPath[3]);
    }
  }, [location, props]);

  const addData = async (data: IFormInput) => {
    setLoading(true);
    try {
      const finalData = {
        name: data.firstName,
        parent_id: '0',
        added_by: '0',
        image: newOldImage[0],
      };
      const incriptData = (await encryptData(finalData)) as string;
      const formData = new FormData();
      formData.append('secret_text', incriptData);
      if (newImage?.length) {
        formData.append('image', newImage[0]);
      }
      if (newImage?.length > 0) {
        const res = await apiService.post(
          '/v1/api/admin/add-category',
          formData,
          headerSet(true)
        );
        if (res.status) {
          setLoading(true);
          Notification('success', res.message);
          navigate('/master' + PATH.CatogaryList);
        } else {
          setLoading(false);
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  const EditData = async (data: IFormInput) => {
    if (newOldImage?.length > 0 || newImage?.length > 0) {
      setLoading(true);
      try {
        const finalData = {
          id: splitPath[3],
          name: data.firstName,
          parent_id: '0',
          added_by: '0',
          image: newOldImage[0],
        };
        const incriptData = (await encryptData(finalData)) as string;
        const formData = new FormData();
        formData.append('secret_text', incriptData);
        if (newImage?.length > 0) {
          formData.append('image', newImage[0]);
        }
        const res = await apiService.put(
          '/v1/api/admin/update-category',
          formData,
          headerSet(true)
        );
        if (res.status) {
          setLoading(true);
          Notification('success', res.message);
          navigate('/master' + PATH.CatogaryList);
        } else {
          setLoading(false);
          Notification('error', res.message);
        }
        // eslint-disable-next-line
      } catch (error: any) {
        const res = await decryptData(error?.response?.data);
        Notification('error', res?.message);
        setLoading(false);
      }
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (props.isEdit) {
      EditData(data);
    } else {
      addData(data);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Category Name"
        variant="outlined"
        type="text"
        value={values.firstName}
        fullWidth
        {...register('firstName')}
        sx={{ marginBottom: 2 }}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        InputLabelProps={{ shrink: !!values.firstName || undefined }}
      />
      <UploadBox
        label="Upload Image"
        id="category-image"
        select="Select image jpg, svg, png, gif, jpeg"
        error={
          submit
            ? newImage?.length > 0 || newOldImage?.length > 0
              ? false
              : true
            : false
        }
        type="image"
        imageData={imageData}
        validation={['jpg', 'jpeg', 'svg', 'png', 'Svg', 'gif']}
        helperText={
          submit
            ? newImage?.length > 0 || newOldImage?.length > 0
              ? ''
              : 'Image is Required.'
            : ''
        }
        getFileData={(newImage: File[], oldImage: string[]) => {
          setNewImage(newImage);
          setNewOldImage(oldImage);
        }}
        multiple={false}
        size={500000000}
      />
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Buttons
          type="submit"
          label={props.isEdit ? 'Save' : 'Create'}
          iconAlign="left"
          loading={loading}
          variant="button"
          onClick={() => {
            setSubmit(true);
          }}
        />
        <Buttons
          type="button"
          iconAlign="left"
          label="Cancel"
          variant="outline"
          style={{ marginLeft: 2 }}
          onClick={() => {
            navigate('/master/category');
          }}
        />
      </Box>
    </Box>
  );
}
