import { Box, TableCell, Typography } from '@mui/material';
import React, { useState } from 'react';
import { userTablePropsType } from './type';
import moment from 'moment';
import ImageWithName from '../../../../../Component/ImageWithName';
import { AdminUserStatus, UserStatus } from '../../../../../constant/StaticData';
import apiService from '../../../../../API/APIService';
import { decryptData, encryptData } from '../../../../../API/encrypt';
import { Notification } from '../../../../../helper/Notification';
import Status from '../../../../../Component/Status';
import Models from '../../../../../Component/Model';
import ActiveModel from '../../../../../Component/Table/ActiveModel';

export default function UserTableRow(props: userTablePropsType) {
  const [verify, setVerify] = useState('');

  const verifyAdmin = async () => {
    try {
      if (verify) {
        const finalData = {
          user_id: props.data.user_id,
          is_admin_verified: verify === 'active' ? 1 : 0, // 0 => Inactive 1 => Active
        };
        const incriptData = (await encryptData(finalData)) as string;
        const secret_text = {
          secret_text: incriptData,
        };
        const res = await apiService.put(
          '/v1/api/admin/verify-user-profile',
          secret_text
        );
        if (res.status) {
          setVerify('');
          Notification('success', res.message);
          props.getData();
        } else {
          setVerify('');
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setVerify('');
    }
  };



  return (
    <>
      <TableCell>
        <Box sx={{ display: 'flex' }}>
          <ImageWithName
            imageURL={props?.data?.image}
            imageStyle={{ width: 50, height: 50, borderRadius: 8 }}
            alt={props.data.name}
          />
          <Box sx={{ marginLeft: 2 }}>
            <Typography variant="body1">{props.data.name}</Typography>
            <Typography variant="body2">
              {props?.data?.profile?.email}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        {props.data.role_name === 'Seller_and_Buyer'
          ? 'Buyer and Seller'
          : props.data.role_name}
      </TableCell>
      {/* <TableCell>{props.data.artCount}</TableCell> */}
      <TableCell>
        <Box>
          {moment(props.data.created_at).format('MMM Do YYYY')}
          <br />
          <Typography variant="caption">
            {moment(props.data.created_at).format('hh:mm A')}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Models
          open={verify ? true : false}
          handleOpen={() => {
            if (UserStatus(props?.data?.profile?.status) === 'Active') {
              setVerify(
                props?.data?.is_admin_verified === 1 ? 'inActive' : 'active'
              );
            }
          }}
          handleClose={() => {
            setVerify('');
            props.getData();
          }}
          button={
            <Status
              label={`${AdminUserStatus(props?.data?.is_admin_verified)}`}
              disebled={
                UserStatus(props?.data?.profile?.status) === 'Active'
                  ? false
                  : true
              }
              status={
                AdminUserStatus(props?.data?.is_admin_verified) === 'Verified'
                  ? true
                  : false
              }
            />
          }
          content={
            <ActiveModel
              handleDelete={verifyAdmin}
              description="Are you sure you want to change the status"
              handleClose={() => {
                setVerify('');
                props.getData();
              }}
              label={
                props?.data?.is_admin_verified === 1 ? 'Yes' : 'Yes'
              }
              loading={false}
              id={props?.data?.id}
            />
          }
        />
      </TableCell>
      {/* <TableCell
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DropdownMenu
          button={
            <Box sx={{ color: ManageColorTheme('#000000', '#ffffff') }}>
              <MoreVertIcon />
            </Box>
          }
          data={dropdownData}
          icon={false}
        />
      </TableCell> */}
    </>
  );
}
