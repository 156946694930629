import axios from 'axios';
import { BASE_URL } from '../config';
import { decryptData } from './encrypt';

// Create an Axios instance

const token = localStorage.getItem('access_token');

const axiosInstance = axios.create({
  baseURL: BASE_URL, // Set your base URL here
  timeout: 10000, // Set timeout if needed
  headers: {
    'Content-Type': 'application/json', // Default header
    Authorization: `Bearer ${token}`,
  },
});

// Dynamic API service
const apiService = {
  // GET request
  get: async (endpoint: string, headers = {}) => {
    const res = await axiosInstance.get(endpoint, { headers });
    const data = await decryptData(res.data);
    return data;
  },

  // POST request
  post: async (endpoint: string, data = {}, headers = {}) => {
    const res = await axiosInstance.post(endpoint, data, { headers });
    const decryptResponce = await decryptData(res.data);
    return decryptResponce;
  },

  // PUT request
  put: async (endpoint: string, data = {}, headers = {}) => {
    const res = await axiosInstance.put(endpoint, data, { headers });
    const decryptResponce = await decryptData(res.data);
    return decryptResponce;
  },

  // PATCH request
  patch: async (endpoint: string, data = {}, headers = {}) => {
    const res = await axiosInstance.patch(endpoint, data, { headers });
    const decryptResponce = await decryptData(res.data);
    return decryptResponce;
  },

  // DELETE request
  delete: async (endpoint: string, headers = {}) => {
    const res = await axiosInstance.delete(endpoint, { headers });
    const decryptResponce = await decryptData(res.data);
    return decryptResponce;
  },
};

export default apiService;
