import { Box, TableCell, Typography } from '@mui/material';
import { ArtStylePropsType } from './type';
import moment from 'moment';

export default function CommissionTableRow(props: ArtStylePropsType) {
  return (
    <>
      <TableCell>
        <Typography variant="body1">{props?.data?.order_no}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.userInfo?.profile?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.artistInfo?.profile?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{props?.data?.buyer_tax_amount}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.seller_tax_amount}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body1">
          {props?.data?.buyer_tax_amount + props?.data?.seller_tax_amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Box>
          {moment(props.data.created_at).format('MMM Do YYYY')}
          <br />
          <Typography variant="caption">
            {moment(props.data.created_at).format('hh:mm A')}
          </Typography>
        </Box>
      </TableCell>
    </>
  );
}
