import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '4px',
  maxHeight: '95vh',
  overflow: 'auto',
  boxShadow: 24,
  pt: 0,
  px: 0,
  pb: 0,
  '&::-webkit-scrollbar': {
    width: '2px', // Scrollbar width
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#e4e4e4', // Track color
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#b2b2b2', // Thumb color
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555', // Thumb color on hover
  },
};

export default function Models(props: {
  button: React.ReactNode;
  content: React.ReactNode;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}) {
  return (
    <div>
      <Box sx={{ cursor: 'pointer' }} onClick={props.handleOpen}>
        {props.button}
      </Box>
      <Modal
        open={props.open}
        sx={{ zIndex: '999999' }}
        onClose={props.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>{props.content}</Box>
      </Modal>
    </div>
  );
}
