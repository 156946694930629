import { Box, Typography, useTheme } from '@mui/material';
// import React from 'react'
// import FadeSlider from '../../../../Component/Slider';
// import { SwiperSlide } from 'swiper/react';
// import ImageWithName from '../../../../Component/ImageWithName';
import { OrderDataType } from '../Table/TableRow/type';
import FadeSlider from '../../../../Component/Slider';
import { SwiperSlide } from 'swiper/react';
import { ArtImage } from './type';
import { S3_URL } from '../../../../config';
import { copyType, isFrame } from '../../../../constant/StaticData';
import { ManageColorTheme } from '../../../../helper/ChangeTheme';
import { globleStyle } from '../../../../assets/Style/globleStyle';
import moment from 'moment';
import { OrderStatusStepper } from '../../../../Component/Steper';
import { useEffect, useState } from 'react';
import PriceList from './PriceList';
import CloseIcon from '@mui/icons-material/Close';
// import { S3_URL } from '../../../../config';
// import { ReadMore } from '../../../../Component/ShowMore';

// Step colors for each status
const defaultStepColors = [
  'rgb(99, 185, 103)', // Color for "Confirmed your order"
  'rgb(251, 140, 0)', // Color for "In Progress"
  'rgb(5, 161, 200)', // Color for "Dispatch"
  'rgb(76, 108, 193)', // Color for "In Transit"
  'rgb(99, 185, 103)', // Color for "Delivered"
];

const setpText = [
  'Confirmed your order',
  'In Progress',
  'Dispatch',
  'In Transit',
  'Delivered',
];

interface OrderDataTypeStep {
  label: string;
  description: string;
  date: string;
}

export default function OrderDetails(props: {
  data: OrderDataType;
  onClose: () => void;
}) {
  const [stepsWithDetails, setStepsWithDetails] = useState<OrderDataTypeStep[]>(
    []
  );
  const [activeStep, setActiveStep] = useState(2);
  const theme = useTheme();

  useEffect(() => {
    const sortedOrders = props?.data?.order_status.sort(
      (a, b) => a.order_status - b.order_status
    );
    const step = sortedOrders[sortedOrders.length - 1];
    const data = [];
    for (let i = 0; i < setpText.length; i++) {
      const element = setpText[i];
      data.push({
        label: element,
        description: sortedOrders[i + 1]?.remark
          ? `${sortedOrders[i + 1]?.remark}`
          : '',
        date: sortedOrders[i + 1]?.created_at
          ? `${moment(sortedOrders[i + 1]?.created_at).format('MMM DD hh:mm A')}`
          : '',
      });
    }
    setStepsWithDetails(data);
    setActiveStep(step.order_status);
  }, []);

  const findDileverd =
    props?.data?.order_status?.filter(
      val => val.status_title === 'Delivered'
    ) ?? [];
  return (
    <Box
      sx={{
        maxHeight: '80vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginBottom: 2,
        padding: 2,
        '&::-webkit-scrollbar': {
          width: '2px', // Scrollbar width
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#e4e4e4', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#b2b2b2', // Thumb color
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555', // Thumb color on hover
        },
        [theme.breakpoints.down('xs')]: {
          width: '100vw',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
          width: '95vw',
        },
        [theme.breakpoints.between('sm', 'md')]: {
          width: '70vw',
        },
        [theme.breakpoints.between('md', 'lg')]: {
          width: '95vw',
        },
        [theme.breakpoints.between('lg', 'xl')]: {
          width: '90vw',
        },
        [theme.breakpoints.up('xl')]: {
          width: '70vw',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box></Box>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Order Detail
        </Typography>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            props.onClose();
          }}
        >
          <CloseIcon />
        </Box>
      </Box>
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap', paddingTop: 2, width: '100%' }}
      >
        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
          <Box sx={{ width: 250, marginRight: 2 }}>
            {props?.data?.art?.thumbnail_image ? (
              <video
                width="100%"
                height="100%"
                style={{ backgroundColor: 'black', borderRadius: 4 }}
                controls
                controlsList="nodownload"
                poster={S3_URL + props?.data?.art?.thumbnail_image}
              >
                <source
                  src={S3_URL + props?.data?.art?.artImages[0]?.image}
                  type="video/mp4"
                />
              </video>
            ) : (
              <FadeSlider
                slide={
                  <>
                    {props?.data?.art?.artImages?.map(
                      (val: ArtImage, i: number) => {
                        return (
                          <SwiperSlide key={i}>
                            <img
                              src={S3_URL + val.image}
                              style={{ borderRadius: 4 }}
                              alt={props.data.art?.title}
                            />
                          </SwiperSlide>
                        );
                      }
                    )}
                  </>
                }
              />
            )}
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.between('xs', 'sm')]: {
                width: '100%',
              },
              [theme.breakpoints.between('sm', 'md')]: {
                width: '100%',
              },
              [theme.breakpoints.between('md', 'lg')]: {
                width: '100%',
              },
              [theme.breakpoints.between('lg', 'xl')]: {
                width: '70%',
              },
              [theme.breakpoints.up('xl')]: {
                width: '70%',
              },
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                [theme.breakpoints.down('xs')]: {
                  width: '100%',
                },
                [theme.breakpoints.between('xs', 'sm')]: {
                  width: '100%',
                },
                [theme.breakpoints.between('sm', 'md')]: {
                  width: '100%',
                },
                [theme.breakpoints.between('md', 'lg')]: {
                  width: '50%',
                },
                [theme.breakpoints.between('lg', 'xl')]: {
                  width: '50%',
                },
                [theme.breakpoints.up('xl')]: {
                  width: '50%',
                },
              }}
            >
              <Typography variant="h6">{props.data?.art?.title}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" sx={{ marginRight: 3 }}>
                  {props?.data?.dimension?.value_one} x{' '}
                  {props?.data?.dimension?.value_two}
                </Typography>
                {props.data.category?.name !== 'Video' && (
                  <Typography sx={{ marginRight: 3 }} variant="caption">
                    {copyType(props?.data?.frame_type)}
                  </Typography>
                )}
                {copyType(props?.data?.frame_type) === 'Hard Copy' && (
                  <Typography variant="caption">
                    {isFrame(props?.data?.is_frame)}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography variant="caption">
                  Seller Name - <b>{props?.data?.artist?.profile?.name}</b>
                </Typography>
                <br />
                <Typography variant="caption">
                  Buyer Name -{' '}
                  <b>{props?.data?.order?.userInfo?.profile?.name}</b>
                </Typography>
              </Box>
              <Typography variant="caption">
                Order No: {props.data?.order_art_no}
              </Typography>
              <Box sx={{ marginTop: 1 }}>
                <Typography variant="h6">Address</Typography>
                <Typography variant="body2">
                  {props?.data?.address?.address_detail}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: ManageColorTheme(
                  globleStyle.layout.light.activeColor,
                  globleStyle.layout.dark.activeColor
                ),
                padding: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                borderRadius: 1,
                marginTop: 1,
                height: 100,
                [theme.breakpoints.down('xs')]: {
                  width: '100%',
                },
                [theme.breakpoints.between('xs', 'sm')]: {
                  width: '100%',
                },
                [theme.breakpoints.between('sm', 'md')]: {
                  width: '100%',
                },
                [theme.breakpoints.between('md', 'lg')]: {
                  width: '40%',
                },
                [theme.breakpoints.between('lg', 'xl')]: {
                  width: '40%',
                },
                [theme.breakpoints.up('xl')]: {
                  width: '40%',
                },
              }}
            >
              <Box
                sx={{
                  color: 'white',
                  borderRightColor: 'white',
                  borderRightStyle: 'solid',
                  borderRightWidth: 2,
                  width: '50%',
                }}
              >
                <Typography variant="caption">Order Date</Typography>
                <br />
                <Typography variant="caption">
                  {moment(props?.data?.order?.created_at).format('YYYY-MM-DD')}
                </Typography>
              </Box>
              <Box sx={{ color: 'white' }}>
                <Typography variant="caption">Delivered Date</Typography>
                <br />
                <Typography variant="caption">
                  {findDileverd?.length > 0
                    ? moment(findDileverd[0]?.created_at).format('YYYY-MM-DD')
                    : '-'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography variant="h5" sx={{ marginTop: 5, textAlign: 'center' }}>
        Price Details
      </Typography>
      <Box
        sx={{
          padding: 1,
          marginTop: 5,
          borderWidth: 2,
          borderColor: theme.palette.grey[300],
          borderStyle: 'solid',
          borderRadius: 1,
        }}
      >
        <PriceList data={props.data} />
      </Box>
      <Typography variant="h5" sx={{ marginTop: 5, textAlign: 'center' }}>
        Order Status
      </Typography>
      <Box sx={{ marginTop: 5, marginBottom: 10 }}>
        <OrderStatusStepper
          activeStep={activeStep}
          stepColors={defaultStepColors}
          steps={stepsWithDetails}
        />
      </Box>
    </Box>
  );
}
