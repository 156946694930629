import { Box, Typography } from '@mui/material';
import React from 'react';
import BredCrums from '../../../Component/Layout/BredCrums';

export default function Dashboard() {
  return (
    <Box>
      <BredCrums
        heading="Dashboard"
        path={[{ name: 'Dashboard', path: '' }]}
        description=""
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />

      <Typography variant="h4" sx={{ textAlign: 'center', marginTop: 20 }}>
        Coming Soon
      </Typography>
    </Box>
  );
}
