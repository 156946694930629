import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function SideModel(props: {
  align: Anchor;
  button: React.ReactNode;
  content: React.ReactNode;
}) {
  const location = useLocation();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  React.useEffect(() => {
    setState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });
  }, [location.pathname]);

  return (
    <React.Fragment>
      <IconButton
        sx={{ width: 40, height: 40 }}
        onClick={toggleDrawer(props.align, true)}
      >
        {props.button}
      </IconButton>
      <SwipeableDrawer
        anchor={props.align}
        open={state[props.align]}
        onClose={toggleDrawer(props.align, false)}
        onOpen={toggleDrawer(props.align, true)}
        sx={{ zIndex: '99999999' }}
      >
        {props.content}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
