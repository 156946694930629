import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tables from '../../../../Component/Table';
import UserTableRow from './TableRow';
import { HeaderType, SortType } from '../../../../Component/Table/type';
import GlobleTableRow from '../../../../Component/Table/GlobleTableRow';
import apiService from '../../../../API/APIService';
import { generateQueryString } from '../../../../helper/generateQueryString';
import { CreateFilter } from '../../../../Component/Table/CreateFilter';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { userDataType } from './TableRow/type';
import { headerSet } from '../../../../API/Header';
import { useDispatch } from 'react-redux';
import { roleStoragekey } from '../../../../constant/role';
import { logout } from '../../../../Redux/slices/AuthSlice';
import { AUTHPATH } from '../../../../Route/Path';
import { useNavigate } from 'react-router-dom';
// import { Icon } from '../../../../assets/Image/Icon';
// import { useNavigate } from 'react-router-dom';
// import { PATH } from '../../../../Route/Path';

export default function List(props: { type: string; label: string }) {
  const [rowCount, setRowCount] = useState(10);
  const [sort, setSort] = useState<SortType[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<userDataType[]>([]);
  // const navigate = useNavigate();
  const [checkData, setCheckData] = useState<userDataType[]>([]);
  const [headers, setHeader] = useState<HeaderType[]>([
    {
      label: 'Name',
      sort: 'name',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'name__con',
      type: 'text',
      width: '30%',
      option: [],
    },
    {
      label: 'Art Count',
      sort: 'artCount',
      sortStatus: true,
      search: false,
      value: '',
      filterQuery: 'artCount__con',
      type: 'number',
      width: '10%',
      option: [],
    },
    {
      label: 'Created Date',
      sort: 'created_at',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'created_at__sw',
      type: 'date',
      width: '20%',
      option: [],
    },
    {
      label: 'Admin Verify',
      sort: 'is_admin_verified',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'is_admin_verified__in',
      type: 'select',
      width: '15%',
      option: [
        { value: '', label: 'All' },
        { value: '1', label: 'Verified' },
        { value: '0', label: 'Not Verified' },
      ],
    },
    {
      label: 'User Status',
      sort: 'status',
      sortStatus: true,
      search: true,
      value: '',
      filterQuery: 'status__in',
      type: 'select',
      width: '20%',
      option: [
        { value: '', label: 'All' },
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' },
      ],
    },
    {
      label: 'Action',
      sort: '',
      sortStatus: false,
      search: false,
      value: '',
      filterQuery: '',
      type: '',
      width: '5%',
      option: [],
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getData = async () => {
    try {
      const obj = CreateFilter(headers);
      setLoading(true);
      const data: {
        size: number;
        page: number;
        role_name__in: string;
        sort?: string; // Mark sort as optional
      } = {
        size: rowCount,
        page: currentPage,
        role_name__in: props.type,
        sort: `${sort[sort.length - 1]?.name}${sort[sort.length - 1]?.status === 1 ? '-' : ''}`,
        ...obj,
      };

      if (sort[sort.length - 1]?.name === undefined) {
        delete data.sort;
      }

      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/user-list?${query}`,
        headerSet(false)
      );
      if (res?.status) {
        setData(res?.data?.rows);
        setIsNext(res?.data?.pagination?.hasNextPage);
        setTotalData(res?.data?.rows?.length);
        setTotalData(res?.data?.pagination?.count);
        setCheckData([]);
        setLoading(false);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      console.log(error);
      handleLogout();
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, rowCount, sort, headers, props.type]);

  const previousPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(totalData / rowCount) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const rowChecked = (check: userDataType[]) => {
    if (check?.length !== data?.length) {
      const checked = checkData?.filter(val => val.id === check[0].id);
      const removeCheck = checkData?.filter(val => val.id !== check[0].id);
      if (checked?.length === 1) {
        setCheckData(removeCheck);
      } else {
        setCheckData([...checkData, ...check]);
      }
    } else {
      if (check?.length === checkData?.length) {
        setCheckData([]);
      } else {
        setCheckData(check);
      }
    }
  };

  useEffect(() => {
    setHeader([
      {
        label: 'Name',
        sort: 'name',
        sortStatus: true,
        search: true,
        value: '',
        filterQuery: 'name__con',
        type: 'text',
        width: '30%',
        option: [],
      },
      {
        label: 'Art Count',
        sort: 'artCount',
        sortStatus: true,
        search: false,
        value: '',
        filterQuery: 'artCount__con',
        type: 'number',
        width: '10%',
        option: [],
      },
      {
        label: 'Created Date',
        sort: 'created_at',
        sortStatus: true,
        search: true,
        value: '',
        filterQuery: 'created_at__sw',
        type: 'date',
        width: '20%',
        option: [],
      },
      {
        label: 'Admin Verify',
        sort: 'is_admin_verified',
        sortStatus: true,
        search: true,
        value: '',
        filterQuery: 'is_admin_verified__in',
        type: 'select',
        width: '15%',
        option: [
          { value: '', label: 'All' },
          { value: '1', label: 'Verified' },
          { value: '0', label: 'Not Verified' },
        ],
      },
      {
        label: 'User Status',
        sort: 'status',
        sortStatus: true,
        search: true,
        value: '',
        filterQuery: 'status__in',
        type: 'select',
        width: '20%',
        option: [
          { value: '', label: 'All' },
          { value: '1', label: 'Active' },
          { value: '0', label: 'Inactive' },
        ],
      },
      // {
      //   label: 'Action',
      //   sort: '',
      //   sortStatus: false,
      //   search: false,
      //   value: '',
      //   filterQuery: '',
      //   type: '',
      //   width: '5%',
      //   option: [],
      // },
    ]);
  }, [props.type]);

  const removeData =
    props.type === 'buyer' ? ['Art Count', 'Admin Verify'] : [];

  const buyerHeader = headers.filter(val => !removeData.includes(val.label));
  return (
    <>
      <BredCrums
        heading={props.label}
        path={[
          { name: 'Dashboard', path: '/dashboard' },
          { name: props.label, path: '' },
        ]}
        description={`Please locate all ${props.type === 'Seller_and_Buyer' ? 'Buyer and Seller' : props.type} accounts associated with your company.`}
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />
      <Box sx={{ marginTop: '3%' }}>
        <Box>
          <Tables
            checkbox={false}
            height={570}
            headers={buyerHeader}
            loading={loading}
            sort={sort.length > 1 ? [sort[sort.length - 1]] : sort}
            rowCount={rowCount}
            currentPage={currentPage}
            totalData={totalData}
            checkData={checkData}
            data={data}
            isNext={isNext}
            limit={[10, 20, 30, 40]}
            handleChangeRowCount={(value: number) => {
              setRowCount(value);
              setCurrentPage(0);
            }}
            setSort={(value: SortType[]) => {
              setSort(value);
            }}
            setCheckAll={() => {
              rowChecked([...data]);
            }}
            setHeader={(val: HeaderType[]) => {
              setHeader(val);
              setCurrentPage(0);
            }}
            previousPage={() => {
              previousPage();
            }}
            getData={() => {
              getData();
            }}
            nextPage={() => {
              nextPage();
            }}
            row={data.map((val: userDataType, i) => {
              return (
                <GlobleTableRow
                  setCheck={(val: userDataType) => {
                    rowChecked([val]);
                  }}
                  rowClick={(val: string) => {
                    console.log(val);
                  }}
                  checkData={checkData}
                  val={val}
                  checkbox={false}
                  key={i}
                  row={
                    <UserTableRow
                      getData={() => {
                        getData();
                      }}
                      type={props.type}
                      data={val}
                    />
                  }
                />
              );
            })}
          />
        </Box>
      </Box>
    </>
  );
}
