import React, { useEffect, useState } from 'react';
import BredCrums from '../../../Component/Layout/BredCrums';
import { Box, Card, TextField, Typography } from '@mui/material';
import { generateQueryString } from '../../../helper/generateQueryString';
import apiService from '../../../API/APIService';
import Buttons from '../../../Component/Buttons';
import { NotificationType } from '../../../Component/Layout/Notification/type';
import { ManageColorTheme } from '../../../helper/ChangeTheme';
import { S3_URL } from '../../../config';
import moment from 'moment';
import noImage from '../../../assets/Image/noimage.png';
import EastIcon from '@mui/icons-material/East';
import MultipleSelect from '../../../Component/Inputs/MultiSelect';
import noData from '../../../assets/Image/noData.gif';
import { headerSet } from '../../../API/Header';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../Redux/slices/AuthSlice';
import { roleStoragekey } from '../../../constant/role';
import { AUTHPATH } from '../../../Route/Path';

export default function Notification() {
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const [notificationFilter, setNotificationFilter] = useState({
    type__con: '',
    from_user: '',
    to_user: '',
    created_at__sw: '',
  });

  // Function to remove null, undefined, and empty string values
  const cleanFilter = (filter: {
    [key: string]: string | null | undefined;
  }): { [key: string]: string | null | undefined } => {
    return Object.fromEntries(
      // eslint-disable-next-line
      Object.entries(filter).filter(
        // eslint-disable-next-line
        ([key, value]) => value !== null && value !== undefined && value !== ''
      )
    );
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await apiService.get(
        `/v1/api/admin/logout`,
        headerSet(false)
      );
      if (res?.status) {
        dispatch(logout());
        localStorage.removeItem(roleStoragekey);
        navigate(AUTHPATH.root);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getNotification = async () => {
    try {
      const cleanedNotificationFilter = cleanFilter(notificationFilter);
      setLoading(true);
      const data = {
        page: 0,
        size: limit,
        ...cleanedNotificationFilter,
      };
      const query = generateQueryString(data);
      const res = await apiService.get(
        `/v1/api/admin/get-notification-list?${query}`,
        headerSet(false)
      );
      if (res.status) {
        setNotification(res?.data?.rows);
        setStatus(res?.data?.pagination?.hasNextPage);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      handleLogout();
      console.log('error', error);
    }
  };

  useEffect(() => {
    getNotification();
  }, [limit, notificationFilter]);

  const isDiliver = [
    { label: 'Quotation', value: 'quotation' },
    { label: 'Favourite', value: 'favourite' },
    { label: 'Admin Action', value: 'admin_action' },
    { label: 'Order', value: 'order' },
  ];

  return (
    <>
      <BredCrums
        heading="Notifications"
        path={[
          { name: 'Dashboard', path: '/dashboard' },
          { name: 'Notifications', path: '' },
        ]}
        description="Find all of your notification."
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginTop: 5,
        }}
      >
        <Box sx={{ width: '20%', marginLeft: 5 }}>
          <TextField
            value={notificationFilter.from_user}
            onChange={e => {
              setLimit(10);
              setNotificationFilter({
                ...notificationFilter,
                from_user: e.target.value,
              });
            }}
            sx={{ width: '90%' }}
            label="From"
            variant="outlined"
          />
        </Box>
        <EastIcon color="info" />
        <Box sx={{ width: '20%', marginLeft: 5 }}>
          <TextField
            value={notificationFilter.to_user}
            onChange={e => {
              setLimit(10);
              setNotificationFilter({
                ...notificationFilter,
                to_user: e.target.value,
              });
            }}
            label="To"
            sx={{ width: '90%' }}
            variant="outlined"
          />
        </Box>
        <Box sx={{ width: '20%', marginLeft: 5 }}>
          <MultipleSelect
            setSelectedValues={val => {
              setLimit(10);
              setNotificationFilter({
                ...notificationFilter,
                type__con: val[0],
              });
            }}
            selectedValues={[notificationFilter.type__con]}
            label="Notification Type"
            data={isDiliver}
            error={false}
            errorMessage={''}
            type={false}
            id="delivery-input"
          />
        </Box>
        <Box sx={{ width: '20%', marginLeft: 5 }}>
          <TextField
            value={notificationFilter.created_at__sw}
            onChange={e => {
              setLimit(10);
              setNotificationFilter({
                ...notificationFilter,
                created_at__sw: e.target.value,
              });
            }}
            sx={{ marginLeft: 3, width: '100%' }}
            type="date"
          />
        </Box>
      </Box>

      <Box
        sx={{
          maxHeight: '48vh',
          overflowY: 'scroll',
          marginTop: 5,
          '&::-webkit-scrollbar': {
            width: '1px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: ManageColorTheme('#ffffff', '#000000'),
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#e9e9e9',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#e9e9e9',
          },
        }}
      >
        {notification?.length > 0 ? (
          notification?.map((val: NotificationType, i: number) => {
            const artData = JSON.parse(val?.data);
            return (
              <Card sx={{ padding: 2, margin: 2 }} variant="elevation" key={i}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    {artData?.thumbnail_image ? (
                      <img
                        height={70}
                        width={70}
                        style={{ borderRadius: 5 }}
                        src={S3_URL + artData?.thumbnail_image}
                      />
                    ) : artData?.image?.length > 0 ? (
                      <img
                        height={70}
                        width={70}
                        style={{ borderRadius: 5 }}
                        src={S3_URL + artData?.image?.[0]?.image}
                      />
                    ) : (
                      <img
                        height={70}
                        width={70}
                        style={{ borderRadius: 5 }}
                        src={noImage}
                      />
                    )}

                    <Box sx={{ marginLeft: 1 }}>
                      <Typography variant="subtitle2">{val.title}</Typography>
                      <Typography variant="caption">
                        {val.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="caption">
                      {moment(val.created_at).format('MMM DD YYYY hh:mm A')}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            );
          })
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Box sx={{ marginTop: 14 }}>
                <img src={noData} />
              </Box>
              <Typography
                variant="h5"
                sx={{ color: '#bdbdbd', marginBottom: 14 }}
              >
                No Data Found
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {status && (
        <Buttons
          onClick={() => {
            setLimit(limit + 10);
          }}
          iconAlign="left"
          loading={loading}
          label="Show More"
          type="button"
          variant="outline"
          style={{
            width: 'fit-content',
            margin: 'auto',
            display: 'block',
            marginTop: 2,
            marginBottom: 3,
          }}
        />
      )}
    </>
  );
}
