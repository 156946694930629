import React from 'react';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { PATH } from '../../../../Route/Path';
import { Box } from '@mui/material';
import Form from '../Form';

export default function ArtStyleEdit() {
  return (
    <div>
      <BredCrums
        heading="Edit Art Style"
        path={[
          { name: 'Dashboard', path: PATH.home },
          { name: 'Art Styles', path: '/master' + PATH.ArtStyleList },
          { name: 'Edit Art Style', path: '' },
        ]}
        description="Edit your art style."
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />
      <Box sx={{ marginTop: '3%' }}>
        <Form isEdit={true} />
      </Box>
    </div>
  );
}
