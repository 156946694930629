import { THEME, themeStoragekey } from '../constant/theme';

export const onChangeTheme = () => {
  const themes = localStorage.getItem(themeStoragekey);
  if (themes) {
    if (themes === THEME.light) {
      localStorage.setItem(themeStoragekey, THEME.dark);
    } else {
      localStorage.setItem(themeStoragekey, THEME.light);
    }
  } else {
    localStorage.setItem(themeStoragekey, THEME.light);
  }
};

export const ManageColorTheme = (light: string, dark: string) => {
  const themes = localStorage.getItem(themeStoragekey);

  return themes === THEME.light ? light : dark;
};
