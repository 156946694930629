import React from 'react';
import BredCrums from '../../../../Component/Layout/BredCrums';
import { PATH } from '../../../../Route/Path';
import { Box } from '@mui/material';
import Form from '../Form';
import { useLocation } from 'react-router-dom';

export default function SubCategoryEdit() {
  const location = useLocation();
  const splitPath = location.pathname.split('/');

  return (
    <div>
      <BredCrums
        heading="Edit sub Category"
        path={[
          { name: 'Dashboard', path: PATH.home },
          { name: 'Categories', path: '/master' + PATH.CatogaryList },
          {
            name: 'Sub Categories',
            path: `/master/sub-category/${splitPath[3]}`,
          },
          { name: 'Edit Sub Categories', path: '' },
        ]}
        description="Edit your sub category."
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />
      <Box sx={{ marginTop: '3%' }}>
        <Form isEdit={true} />
      </Box>
    </div>
  );
}
