export const PATH = {
  notFound: '*',
  home: '/dashboard',
  userList: '/buyers',
  seller: '/sellers',
  buyerAndSeller: '/buyers-and-sellers',
  userCreate: '/user/create',
  editUser: '/user/:id',
  chat: '/chat/dashboard',
  cms: '/cms',
  cmsCreate: '/cms/create',
  editCms: '/cms/:id',
  artList: '/art',
  CatogaryList: '/category',
  CatogaryCreate: '/category/create',
  CatogaryEdit: '/category/:id',
  SubCatogaryEdit: '/sub-category/:catogaryid/:id',
  SubCatogaryCreate: '/sub-category/create/:id',
  SubCatogaryList: '/sub-category/:id',
  OrderList: '/order',
  OrderListItem: '/order/:id',
  ArtStyleList: '/art-style',
  ArtStyleCreate: '/art-style/create',
  ArtStyleEdit: '/art-style/:id',
  PaymentList: '/payment',
  CommissionList: '/commission',
  profile: '/profile',
  setting: '/setting',
  settingCreate: '/setting/create',
  settingEdit: '/setting/:id',
  SellerRequest: '/seller-request',
  CountryList: '/country',
  CountryCreate: '/country/create',
  CountryEdit: '/country/:id',
  notification: '/notification',
};

export const authData = [
  '/',
  '/signup',
  '/forgot-password',
  '/resetpassword',
  '/email-verify',
  '/tow-step-verification',
];
export const AUTHPATH = {
  root: '/',
  signup: '/signup',
  forgot: '/forgot-password',
  reset: '/resetpassword/:token',
  verify: '/email-verify',
  twoStep: '/tow-step-verification',
};
