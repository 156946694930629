import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import FadeSlider from '../../../../Component/Slider';
import { ArtImage } from '../Table/TableRow/type';
import { SwiperSlide } from 'swiper/react';
import ImageWithName from '../../../../Component/ImageWithName';
import { ArtDetailsProps } from './type';
import Tags from '../../../../Component/Tag';
import { Dimention, copyType, isFrame } from '../../../../constant/StaticData';
import { S3_URL } from '../../../../config';
import CloseIcon from '@mui/icons-material/Close';

export default function ArtDetails(props: {
  data: ArtDetailsProps;
  onClose: () => void;
}) {
  const theme = useTheme();

  console.log('props?.data', props?.data?.artassignedstyle);
  return (
    <Box
      sx={{
        maxHeight: '80vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginBottom: 2,
        padding: 2,
        '&::-webkit-scrollbar': {
          width: '2px', // Scrollbar width
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#e4e4e4', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#b2b2b2', // Thumb color
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555', // Thumb color on hover
        },
        [theme.breakpoints.down('xs')]: {
          width: '100vw',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
          width: '90vw',
        },
        [theme.breakpoints.between('sm', 'md')]: {
          width: '80vw',
        },
        [theme.breakpoints.between('md', 'lg')]: {
          width: '70vw',
        },
        [theme.breakpoints.between('lg', 'xl')]: {
          width: '50vw',
        },
        [theme.breakpoints.up('xl')]: {
          width: '30vw',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box></Box>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Art Detail
        </Typography>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            props.onClose();
          }}
        >
          <CloseIcon />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Box sx={{ width: 250, height: 250, marginRight: 2, marginTop: 2 }}>
          {props?.data?.thumbnail_image ? (
            <video
              width="100%"
              height="100%"
              style={{ backgroundColor: 'black' }}
              controls
              controlsList="nodownload"
              poster={S3_URL + props?.data?.thumbnail_image}
            >
              <source
                src={S3_URL + props?.data?.artImages[0]?.image}
                type="video/mp4"
              />
            </video>
          ) : (
            <FadeSlider
              slide={
                <>
                  {props?.data?.artImages?.map((val: ArtImage, i: number) => {
                    return (
                      <SwiperSlide key={i}>
                        <ImageWithName
                          imageURL={val.image}
                          imageStyle={{
                            width: 250,
                            height: 250,
                            borderRadius: 5,
                          }}
                          alt={props.data.title}
                        />
                      </SwiperSlide>
                    );
                  })}
                </>
              }
            />
          )}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Box sx={{ width: 'fit-content', marginBottom: 1 }}>
            <Tags label={props.data.status === 1 ? 'Active' : 'Inactive'} />
          </Box>
          <Box sx={{ marginLeft: 0.5 }}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              {props.data.title}
            </Typography>
            <Typography variant="body2">
              Category - <b>{props.data.category_name}</b>
            </Typography>
            <Typography variant="body2">
              Sub Category - <b>{props.data.subcategory_name}</b>
            </Typography>
            <Typography variant="body2">
              No. of Edition -{' '}
              <b>
                {props?.data?.sold_copies_qty == props?.data?.copies_qty
                  ? props?.data?.sold_copies_qty
                  : props?.data?.sold_copies_qty + 1}{' '}
                / {props?.data?.copies_qty}
              </b>
            </Typography>
            {copyType(props?.data?.frame_type) === 'Hard Copy' && (
              <Typography variant="body2">
                Print Type - <b>{isFrame(props?.data?.is_frame)}</b>
              </Typography>
            )}
            <Typography variant="body2">
              Owner - <b>{props?.data?.userInfo?.profile?.name}</b>
            </Typography>
            <Typography variant="body2">
              Country of Origin - <b>{props?.data?.countryInfo?.title}</b>
            </Typography>
            {/* <Typography variant="body2">
              Painting Medium -{' '}
              <b>{props?.data?.paintingMaterialInfo?.title}</b>
            </Typography> */}
            {props.data.category_name !== 'Video' && (
              <Typography variant="body2">
                Copy Type - <b>{copyType(props?.data?.frame_type)}</b>
              </Typography>
            )}
            {copyType(props?.data?.frame_type) === 'Hard Copy' && (
              <Typography variant="body2">
                Dimension Type - <b>{Dimention(props?.data?.dimension)}</b>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {props?.data?.artassignedstyle?.length > 0 && (
        <Box>
          <Typography variant="body1">
            <b>Art Style</b>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {props?.data?.artassignedstyle?.map((val, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    width: 'fit-content',
                    marginBottom: 1,
                    marginRight: 1,
                    marginTop: 1,
                  }}
                >
                  <Tags
                    label={
                      <Typography variant="caption">
                        {val?.artStyleInfo?.title}
                      </Typography>
                    }
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      {copyType(props?.data?.frame_type) === 'Hard Copy' && (
        <Box sx={{ marginTop: 1 }}>
          <Typography variant="body1">
            <b>Dimensions</b>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
            {props?.data?.artDimensions?.map((val, i) => {
              return (
                <Box
                  key={i}
                  sx={{ width: 'fit-content', marginBottom: 1, marginRight: 1 }}
                >
                  <Tags
                    label={
                      <Typography variant="caption">{`${val?.value_one} * ${val?.value_two}`}</Typography>
                    }
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box sx={{ marginTop: 0.5 }}>
        <Typography variant="body1">
          <b>Description</b>
        </Typography>
        <Typography sx={{ wordWrap: 'break-word', marginTop: 1 }}>
          {props.data.description}
        </Typography>
      </Box>
    </Box>
  );
}
