import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { SortType, tablePropsType } from './type';
import {
  // Next,
  // NextActive,
  // Previous,
  // PreviousActive,
  SortAsce,
  SortAsceDown,
  SortAsceDownTop,
} from '../../assets/Image/Icon';
import IconButton from '../IconButton/IconButton';
import { ManageColorTheme } from '../../helper/ChangeTheme';
import { globleStyle } from '../../assets/Style/globleStyle';
import Models from '../Model';
import DeleteModel from './DeleteModel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import noData from '../../assets/Image/noData.gif';
import { nullRow } from '../../constant/StaticData';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Tables(props: tablePropsType) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteAll = () => {
    props.getData();
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <TableContainer
          sx={{
            borderRadius: 2,
            maxHeight: props.height,
            overflowX: 'auto',
            overflowY: 'auto', // Allow vertical scrolling for the body and header
            '&::-webkit-scrollbar': {
              width: '1px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: ManageColorTheme('#ffffff', '#000000'),
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#e9e9e9',
              borderRadius: '2px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#e9e9e9',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Button
              onClick={() => {
                const updatedHeaders = [];
                for (let i = 0; i < props.headers.length; i++) {
                  const element = props.headers[i];
                  updatedHeaders.push({
                    ...element,
                    value: '',
                  });
                }
                props.setHeader(updatedHeaders);
                props.setSort([]);
              }}
              sx={{
                color: 'red',
                ':hover': { color: 'red', backgroundColor: '#ff000008' },
              }}
            >
              <DeleteIcon /> Clear All
            </Button>
          </Box>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {props.checkbox && (
                  <TableCell
                    sx={{
                      width: 'auto',
                      whiteSpace: 'nowrap',
                      borderBottom: 'none',
                      backgroundColor: ManageColorTheme(
                        globleStyle.layout.light.tableHeaderBg,
                        globleStyle.layout.dark.tableHeaderBg
                      ),
                    }}
                  >
                    <Checkbox
                      onChange={() => {
                        props.setCheckAll();
                      }}
                      checked={
                        props?.data?.length > 0 &&
                          props?.checkData?.length === props?.data?.length
                          ? true
                          : false
                      }
                    />
                  </TableCell>
                )}
                {props.headers.map((header, index) => {
                  const sortValue = props.sort.filter(
                    (val: SortType) => val.name === header.sort
                  );
                  const removecurrentSort = props.sort.filter(
                    (val: SortType) => val.name !== header.sort
                  );
                  return (
                    <TableCell
                      sx={{
                        width: header.width ? header.width : 'auto',
                        whiteSpace: 'nowrap',
                        borderBottom: 'none',
                        backgroundColor: ManageColorTheme(
                          globleStyle.layout.light.tableHeaderBg,
                          globleStyle.layout.dark.tableHeaderBg
                        ),
                      }}
                      onClick={() => {
                        if (header.sortStatus === true) {
                          console.log("sortValue[0]?.status", sortValue[0]?.status)
                          props.setSort(
                            sortValue[0]?.status === 1
                              ? [
                                ...removecurrentSort,
                                { name: header.sort, status: 0 },
                              ]
                              : sortValue[0]?.status === 0 ? [
                                ...removecurrentSort,
                              ] : [
                                ...removecurrentSort,
                                { name: header.sort, status: 1 },
                              ]
                          );
                        }
                      }}
                      key={index}
                    >
                      <Box
                        sx={
                          header.search
                            ? { display: 'flex', alignItems: 'center' }
                            : {
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 6,
                            }
                        }
                      >
                        <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
                          {header.label}
                        </Typography>
                        {header.sortStatus === true &&
                          (sortValue[0]?.name === header.sort ? (
                            <Box
                              sx={{
                                cursor: 'pointer',
                              }}
                            >
                              {sortValue[0]?.status === 1 ? (
                                <SortAsceDown />
                              ) : (
                                <SortAsce />
                              )}
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                cursor: 'pointer',
                              }}
                            >
                              <SortAsceDownTop />
                            </Box>
                          ))}
                      </Box>
                      {header.label === 'Action' && props.checkbox && (
                        <Models
                          open={open}
                          handleOpen={() => {
                            handleOpen();
                          }}
                          handleClose={() => {
                            handleClose();
                          }}
                          button={
                            <Typography
                              variant="inherit"
                              sx={{
                                marginTop: -5,
                                color: 'red',
                                cursor: 'pointer',
                              }}
                            >
                              Delete All
                            </Typography>
                          }
                          content={
                            <DeleteModel
                              handleDelete={() => {
                                deleteAll();
                              }}
                              handleClose={() => {
                                handleClose();
                              }}
                              loading={false}
                              description="You want to delete all user"
                              id=""
                            />
                          }
                        />
                      )}
                      {header.type === 'select' ? (
                        <FormControl
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          sx={{ m: 0, mb: 0, width: '100%', border: 'none' }}
                        >
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={header.value || ''}
                            onChange={(e: SelectChangeEvent<string>) => {
                              const updatedHeaders = [...props.headers];
                              updatedHeaders[index] = {
                                ...updatedHeaders[index],
                                value: e.target.value,
                              };
                              props.setHeader(updatedHeaders);
                            }}
                            displayEmpty
                            autoWidth
                            sx={{
                              backgroundColor: 'transparent',
                              paddingTop: 3,
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderRight: 'none',
                              borderBottomWidth: 3,
                              borderRadius: 0,
                              width: '100%',
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                                borderBottomWidth: 3,
                                borderRadius: 0,
                                width: '100%',
                              },
                              '& .MuiSelect-select': {
                                padding: 0,
                                width: '100%',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '14%', // This ensures that the dropdown menu matches the select's width
                                },
                              },
                            }}
                          >
                            {header?.option.map((val, i) => {
                              return (
                                <MenuItem key={i} value={val.value}>
                                  {val.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        header.search && (
                          <Box
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <TextField
                              onChange={e => {
                                const updatedHeaders = [...props.headers];
                                updatedHeaders[index] = {
                                  ...updatedHeaders[index],
                                  value: e.target.value,
                                };
                                props.setHeader(updatedHeaders);
                              }}
                              value={header.value}
                              sx={{ width: '100%' }}
                              type={header.type}
                              placeholder={`${header.label}`}
                            />
                          </Box>
                        )
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.totalData > 0 ? (
                props.loading ? (
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: 'none' }}
                      colSpan={
                        props.checkbox
                          ? props.headers.length + 1
                          : props.headers.length
                      }
                      align="center"
                    >
                      {nullRow?.map((val: string, i?: number) => {
                        return (
                          <Skeleton
                            key={val + i}
                            variant="rectangular"
                            sx={{
                              marginBottom: 0.5,
                              backgroundColor: '#f5f5f5',
                            }}
                            width="100%"
                            height={60}
                          />
                        );
                      })}
                    </TableCell>
                  </TableRow>
                ) : (
                  props.row
                )
              ) : props.loading ? (
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: 'none' }}
                    colSpan={
                      props.checkbox
                        ? props.headers.length + 1
                        : props.headers.length
                    }
                    align="center"
                  >
                    {nullRow?.map((val: string, i?: number) => {
                      return (
                        <Skeleton
                          key={val + i}
                          variant="rectangular"
                          sx={{ marginBottom: 0.5, backgroundColor: '#f5f5f5' }}
                          width="100%"
                          height={60}
                        />
                      );
                    })}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: 'none' }}
                    colSpan={
                      props.checkbox
                        ? props.headers.length + 1
                        : props.headers.length
                    }
                    align="center"
                  >
                    <Box sx={{ marginTop: 14 }}>
                      <img src={noData} />
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{ color: '#bdbdbd', marginBottom: 14 }}
                    >
                      No Data Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Table sx={{ marginTop: 2 }}>
          <TableFooter
            sx={{
              position: 'sticky',
              bottom: -1,
              zIndex: 1,
            }}
          >
            <TableRow>
              <TableCell
                colSpan={
                  props?.checkbox
                    ? props.headers.length + 1
                    : props.headers.length
                }
                sx={{ padding: 0, borderBottom: 'none' }}
              >
                <Box
                  sx={{
                    backgroundColor: ManageColorTheme(
                      globleStyle.layout.light.tableFooterBg,
                      globleStyle.layout.dark.tableFooterBg
                    ),
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      [theme.breakpoints.down('xs')]: {
                        width: '100vw',
                      },
                      [theme.breakpoints.between('xs', 'sm')]: {
                        width: '100vw',
                      },
                      [theme.breakpoints.between('sm', 'md')]: {
                        width: '100vw',
                      },
                      [theme.breakpoints.between('md', 'lg')]: {
                        width: '50%',
                      },
                      [theme.breakpoints.between('lg', 'xl')]: {
                        width: '35%',
                      },
                      [theme.breakpoints.up('xl')]: {
                        width: '30%',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2">Rows per page:</Typography>
                      <FormControl
                        sx={{ m: 1, minWidth: 'auto', border: 'none' }}
                      >
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={props.rowCount}
                          onChange={(e: SelectChangeEvent<number>) => {
                            props.handleChangeRowCount(Number(e.target.value));
                          }}
                          autoWidth
                          sx={{
                            backgroundColor: 'transparent',
                            padding: 0,
                            border: 'none',
                            color: ManageColorTheme(
                              globleStyle.layout.light.activeColor,
                              globleStyle.layout.dark.activeColor
                            ),
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiSelect-select': {
                              padding: 0,
                            },
                          }}
                        >
                          {props.limit?.map((val, i) => {
                            return (
                              <MenuItem key={i} value={val}>
                                {val}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Typography variant="body2">
                      {props.totalData === 0
                        ? 0
                        : props.currentPage * props.rowCount + 1}
                      -
                      {props.isNext
                        ? props.currentPage * props.rowCount + props.rowCount
                        : props.totalData}{' '}
                      of {props.totalData}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        icon={
                          props.currentPage > 0 ? (
                            <Box
                              sx={{
                                color: ManageColorTheme(
                                  globleStyle.layout.light.activeColor,
                                  globleStyle.layout.dark.activeColor
                                ),
                              }}
                            >
                              <ChevronLeftIcon />
                            </Box>
                          ) : (
                            <Box>
                              <ChevronLeftIcon />
                            </Box>
                          )
                        }
                        onClick={() => {
                          props.previousPage();
                        }}
                      />
                      <IconButton
                        icon={
                          props.isNext ? (
                            <Box
                              sx={{
                                color: ManageColorTheme(
                                  globleStyle.layout.light.activeColor,
                                  globleStyle.layout.dark.activeColor
                                ),
                              }}
                            >
                              <KeyboardArrowRightIcon />
                            </Box>
                          ) : (
                            <Box>
                              <KeyboardArrowRightIcon />
                            </Box>
                          )
                        }
                        onClick={
                          props.isNext
                            ? () => {
                              props.nextPage();
                            }
                            : () => { }
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </>
  );
}
