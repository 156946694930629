import {
  Box,
  IconButton,
  MenuItem,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ArtStylePropsType } from './type';
import apiService from '../../../../../API/APIService';
import { Notification } from '../../../../../helper/Notification';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import DeleteIcon from '@mui/icons-material/Delete';
import { headerSet } from '../../../../../API/Header';
import DropdownMenu from '../../../../../Component/Dropdown';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
// import { UserStatus } from '../../../../../constant/StaticData';
// import Status from '../../../../../Component/Status';
// import DeleteModel from '../../../../../Component/Table/DeleteModel';
// import Models from '../../../../../Component/Model';
import { ManageColorTheme } from '../../../../../helper/ChangeTheme';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { decryptData, encryptData } from '../../../../../API/encrypt';

export default function ArtStyleTableRow(props: ArtStylePropsType) {
  // const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState('');
  const [rowValue, setRowValue] = useState('');
  const [rowTitle, setRowTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [valueError, setValueError] = useState(false);
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleEdit = async () => {
    // setLoading(true);
    if (!rowTitle.trim()) {
      setTitleError(true);
      return;
    }
    if (!rowValue.trim()) {
      setValueError(true);
      return;
    }

    try {
      const finalData = {
        setting_id: rowId,
        title: rowTitle.trim(),
        setting_value: rowValue.trim(),
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };

      if (rowValue.length > 0 && rowTitle.length > 0) {
        const res = await apiService.put(
          '/v1/api/admin/setting-update',
          secret_text,
          headerSet(false)
        );
        if (res.status) {
          // setLoading(true);
          Notification('success', res.message);
          setRowId('');
          setRowValue('');
          setRowTitle('');
          props.getData();
        } else {
          // setLoading(false);
          Notification('error', res.message);
          setRowId('');
          setRowValue('');
          setRowTitle('');
          props.getData();
        }
      } else {
        Notification('error', 'Please Enter valid Value and Title');
      }

      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      // setLoading(false);
      setRowId('');
      setRowValue('');
      setRowTitle('');
      props.getData();
    }
  };

  // const handleDelete = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await apiService.delete(
  //       `/v1/api/admin/setting-delete?setting_id=${props?.data?.id}`,
  //       headerSet(false)
  //     );
  //     if (res.status) {
  //       props.getData();
  //       Notification('success', res.message);
  //       setLoading(false);
  //       handleClose();
  //     } else {
  //       Notification('error', res.message);
  //       setLoading(false);
  //       handleClose();
  //     }
  //     // eslint-disable-next-line
  //   } catch (error: any) {
  //     const res = await decryptData(error?.response?.data);
  //     Notification('error', res?.message);
  //     setLoading(false);
  //     handleClose();
  //   }
  // };

  const dropdownData = [
    {
      name: (
        <MenuItem
          onClick={() => {
            setRowId(props.data.id);
            setRowValue(props.data.setting_value);
            setRowTitle(props.data.title);
            // navigate(`/setting/${props.data.id}`);
          }}
        >
          {' '}
          <EditIcon sx={{ marginRight: 1 }} /> Edit
        </MenuItem>
      ),
      icon: '',
    },
    // {
    //   name: (
    //     <Models
    //       open={open}
    //       handleOpen={handleOpen}
    //       handleClose={handleClose}
    //       button={
    //         <MenuItem sx={{ color: 'rgb(211, 47, 47)' }} onClick={() => {}}>
    //           <DeleteIcon color="error" sx={{ marginRight: 1 }} /> Delete
    //         </MenuItem>
    //       }
    //       content={
    //         <DeleteModel
    //           handleDelete={handleDelete}
    //           description="You want to delete setting"
    //           handleClose={handleClose}
    //           loading={loading}
    //           id={props?.data?.id}
    //         />
    //       }
    //     />
    //   ),
    //   icon: '',
    // },
  ];

  return (
    <>
      <TableCell>
        {rowId === props.data.id ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              type="string"
              onChange={e => {
                setRowTitle(e.target.value);
                setTitleError(!e.target.value.trim());
              }}
              value={rowTitle}
              error={titleError}
              helperText={titleError ? 'Title cannot be empty' : ''}
            />
          </Box>
        ) : (
          <Typography variant="body1">{props?.data?.title}</Typography>
        )}
      </TableCell>
      <TableCell>
        {rowId === props.data.id ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              type="string"
              onChange={e => {
                setRowValue(e.target.value);
                setValueError(!e.target.value.trim());
              }}
              value={rowValue}
              error={valueError}
              helperText={valueError ? 'Value cannot be empty' : ''}
            />
            <IconButton
              onClick={() => {
                handleEdit();
              }}
              disabled={!rowTitle.trim() || !rowValue.trim()}
            >
              <DoneIcon color="success" />
            </IconButton>
            <IconButton
              onClick={() => {
                setRowId('');
                setRowValue('');
                setRowTitle('');
                setTitleError(false);
                setValueError(false);
              }}
            >
              <ClearIcon color="error" />
            </IconButton>
          </Box>
        ) : (
          <Typography variant="body1">{props?.data?.setting_value}</Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body1">{props?.data?.setting_key}</Typography>
      </TableCell>
      <TableCell>
        <Box>
          {moment(props.data.created_at).format('MMM Do YYYY')}
          <br />
          <Typography variant="caption">
            {moment(props.data.created_at).format('hh:mm A')}
          </Typography>
        </Box>
      </TableCell>
      {/* <TableCell>
        <Status
          label={`${UserStatus(props?.data?.status)}`}
          status={UserStatus(props?.data?.status) === 'Active' ? true : false}
        />
      </TableCell> */}
      <TableCell
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DropdownMenu
          button={
            <Box sx={{ color: ManageColorTheme('#000000', '#ffffff') }}>
              <MoreVertIcon />
            </Box>
          }
          data={dropdownData}
          icon={false}
        />
      </TableCell>
    </>
  );
}
