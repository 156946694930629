import { Box, TableCell, Typography } from '@mui/material';
import { Notification } from '../../../../../helper/Notification';
import apiService from '../../../../../API/APIService';
import { decryptData, encryptData } from '../../../../../API/encrypt';
import { useEffect, useState } from 'react';
import { OrderTablePropsType } from './type';
import ImageWithName from '../../../../../Component/ImageWithName';
import FadeSlider from '../../../../../Component/Slider';
import { ArtImage } from '../../../Art/Table/TableRow/type';
import { SwiperSlide } from 'swiper/react';
import { copyType, isFrame } from '../../../../../constant/StaticData';

export default function OrderTableRow(props: OrderTablePropsType) {
  const [role, setRole] = useState('');
  const handleChangeStatus = async () => {
    try {
      if (role) {
        const finalData = {
          art_id: props.data.id,
          status: role === 'Active' ? 1 : 0, // 0 => Inactive 1 => Active
        };
        const incriptData = (await encryptData(finalData)) as string;
        const secret_text = {
          secret_text: incriptData,
        };
        const res = await apiService.put(
          '/v1/api/admin/update-art-status',
          secret_text
        );
        if (res.status) {
          setRole('');
          Notification('success', res.message);
          props.getData();
        } else {
          setRole('');
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setRole('');
    }
  };

  useEffect(() => {
    handleChangeStatus();
  }, [role]);

  return (
    <>
      <TableCell sx={{ cursor: 'pointer' }}>
        {props?.data?.order_art_no}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: 50, height: 50 }}>
            {props?.data?.art?.thumbnail_image ? (
              <ImageWithName
                imageURL={props?.data?.art?.thumbnail_image}
                imageStyle={{ width: 50, height: 50, borderRadius: 4 }}
                alt={props.data.art?.title}
              />
            ) : (
              <FadeSlider
                slide={
                  <>
                    {props?.data?.art?.artImages?.map(
                      (val: ArtImage, i: number) => {
                        return (
                          <SwiperSlide key={i}>
                            <ImageWithName
                              imageURL={val.image}
                              imageStyle={{
                                width: 50,
                                height: 50,
                                borderRadius: 4,
                              }}
                              alt={props.data.art?.title}
                            />
                          </SwiperSlide>
                        );
                      }
                    )}
                  </>
                }
              />
            )}
          </Box>
          <Box sx={{ marginLeft: 2 }}>
            <Typography variant="body1">{props.data.art?.title}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="caption" sx={{ marginRight: 3 }}>
                {props?.data?.dimension?.value_one} x{' '}
                {props?.data?.dimension?.value_two}
              </Typography>
              {props.data.category?.name !== 'Video' && (
                <Typography sx={{ marginRight: 3 }} variant="caption">
                  {copyType(props?.data?.frame_type)}
                </Typography>
              )}
              {copyType(props?.data?.frame_type) === 'Hard Copy' && (
                <Typography variant="caption">
                  {isFrame(props?.data?.is_frame)}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {props?.data?.category?.name}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {props?.data?.sub_category?.name}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>art style</TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {props.data?.art?.paintingMaterialInfo?.title}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {Number(
          props?.data?.buyer_total_amount + props?.data?.stripe_tax_amount
        ).toFixed(2)}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }}>
        {Number(props?.data?.seller_total_amount).toFixed(2)}
      </TableCell>
    </>
  );
}
