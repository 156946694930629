import { Box, TableCell, Typography } from '@mui/material';
import React, { useState } from 'react';
import { userTablePropsType } from './type';
import moment from 'moment';
import ImageWithName from '../../../../../Component/ImageWithName';
import {
  AdminUserStatus,
  UserStatus,
} from '../../../../../constant/StaticData';
import apiService from '../../../../../API/APIService';
import { decryptData, encryptData } from '../../../../../API/encrypt';
import { Notification } from '../../../../../helper/Notification';
import Status from '../../../../../Component/Status';
import ActiveModel from '../../../../../Component/Table/ActiveModel';
import Models from '../../../../../Component/Model';

export default function UserTableRow(props: userTablePropsType) {
  const [role, setRole] = useState('');
  const [verify, setVerify] = useState('');

  const handleChangeStatus = async () => {
    try {
      if (role) {
        const finalData = {
          user_id: props.data.user_id,
          status: role === 'active' ? 1 : 0, // 0 => Inactive 1 => Active
        };
        const incriptData = (await encryptData(finalData)) as string;
        const secret_text = {
          secret_text: incriptData,
        };
        const res = await apiService.put(
          '/v1/api/admin/update-user-status',
          secret_text
        );
        if (res.status) {
          setRole('');
          Notification('success', res.message);
          props.getData();
        } else {
          setRole('');
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setRole('');
    }
  };

  const verifyAdmin = async () => {
    try {
      if (verify) {
        const finalData = {
          user_id: props.data.user_id,
          is_admin_verified: verify === 'active' ? 1 : 0, // 0 => Inactive 1 => Active
        };
        const incriptData = (await encryptData(finalData)) as string;
        const secret_text = {
          secret_text: incriptData,
        };
        const res = await apiService.put(
          '/v1/api/admin/verify-user-profile',
          secret_text
        );
        if (res.status) {
          setRole('');
          Notification('success', res.message);
          props.getData();
        } else {
          setRole('');
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setRole('');
    }
  };

  return (
    <>
      <TableCell>
        <Box sx={{ display: 'flex' }}>
          <ImageWithName
            imageURL={props?.data?.image}
            imageStyle={{ width: 50, height: 50, borderRadius: 8 }}
            alt={props.data.name}
          />
          <Box sx={{ marginLeft: 2 }}>
            <Typography variant="body1">{props.data.name}</Typography>
            <Typography variant="body2">
              {props?.data?.profile?.email}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      {props?.type !== 'buyer' && <TableCell>{props.data.artCount}</TableCell>}

      <TableCell>
        {moment(props.data.created_at).format('MMM Do YYYY')}
      </TableCell>
      {props?.type !== 'buyer' && (
        <TableCell>
          <Models
            open={verify ? true : false}
            handleOpen={() => {
              if (UserStatus(props?.data?.profile?.status) === 'Active') {
                setVerify(
                  props?.data?.is_admin_verified === 1 ? 'inActive' : 'active'
                );
              }
            }}
            handleClose={() => {
              setVerify('');
              props.getData();
            }}
            button={
              <Status
                label={`${AdminUserStatus(props?.data?.is_admin_verified)}`}
                disebled={
                  UserStatus(props?.data?.profile?.status) === 'Active'
                    ? false
                    : true
                }
                status={
                  AdminUserStatus(props?.data?.is_admin_verified) === 'Verified'
                    ? true
                    : false
                }
              />
            }
            content={
              <ActiveModel
                handleDelete={verifyAdmin}
                description="Are you sure you want to change the status"
                handleClose={() => {
                  setVerify('');
                  props.getData();
                }}
                label={
                  props?.data?.is_admin_verified === 1 ? 'Yes' : 'Yes'
                }
                loading={false}
                id={props?.data?.id}
              />
            }
          />
        </TableCell>
      )}
      <TableCell>
        <Models
          open={role ? true : false}
          handleOpen={() => {
            setRole(props?.data?.profile?.status === 1 ? 'inActive' : 'active');
          }}
          handleClose={() => {
            setRole('');
            props.getData();
          }}
          button={
            <Status
              label={`${UserStatus(props?.data?.profile?.status)}`}
              status={
                UserStatus(props?.data?.profile?.status) === 'Active'
                  ? true
                  : false
              }
            />
          }
          content={
            <ActiveModel
              handleDelete={handleChangeStatus}
              description="Are you sure you want to change the status"
              handleClose={() => {
                setRole('');
                props.getData();
              }}
              label={
                props?.data?.profile?.status === 1 ? 'Yes' : 'Yes'
              }
              loading={false}
              id={props?.data?.id}
            />
          }
        />
      </TableCell>
    </>
  );
}
