import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { OrderDataType } from '../Table/TableRow/type';
import moment from 'moment';

export default function PriceList(props: { data: OrderDataType }) {
  const theme = useTheme();

  console.log('props?.data', props?.data?.order?.created_at);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRightWidth: 2,
            borderRightStyle: 'solid',
            borderRightColor: theme.palette.grey[200],
            paddingRight: 5,
          }}
        >
          <Box sx={{ marginRight: 10 }}>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              Art Price
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              Frame Price
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              Shipping Charge
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              Seller Platform Fee
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              Buyer Platform Fee
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              Stripe Tax (Paying for Buyer)
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              $ {Number(props?.data?.art_price).toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              $ {Number(props?.data?.frame_charges).toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              $ {Number(props?.data?.shipping_charges).toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              $ {Number(props?.data?.buyer_tax_amount).toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              $ {Number(props?.data?.seller_tax_amount).toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
              $ {Number(props?.data?.stripe_tax_amount).toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginLeft: 5 }}>
          <Typography variant="caption" sx={{ color: theme.palette.grey[400] }}>
            <b>Buyer Total</b>
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            <b>
              ${' '}
              {Number(
                props?.data?.buyer_total_amount + props?.data?.stripe_tax_amount
              ).toFixed(2)}
            </b>
          </Typography>

          <Box sx={{ marginTop: 2 }}>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.grey[400] }}
            >
              <b>Seller Total</b>
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              <b>$ {Number(props?.data?.seller_total_amount).toFixed(2)}</b>
            </Typography>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.grey[400] }}
            >
              <b>Platform Charge</b>
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              <b>
                ${' '}
                {Number(
                  props?.data?.buyer_tax_amount + props?.data?.seller_tax_amount
                ).toFixed(2)}
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginLeft: 5 }}>
        <Typography variant="body1">
          <b style={{ color: theme.palette.grey[400] }}>Paid via </b> Credit
          Card **** **** **** 4242
        </Typography>
        <Typography variant="body1">
          <b style={{ color: theme.palette.grey[400] }}>Paid to</b>{' '}
          {props?.data?.artist?.profile?.name}
        </Typography>
        <Typography variant="body1">
          <b style={{ color: theme.palette.grey[400] }}>Date</b>{' '}
          {moment(props?.data?.order?.created_at).format('YYYY-MM-DD hh:mm a')}
        </Typography>
      </Box>
    </Box>
  );
}
