import { createTheme } from '@mui/material/styles';
import { globleStyle } from './assets/Style/globleStyle';

// Define the light theme

const light = globleStyle.layout.light;
const dark = globleStyle.layout.dark;

const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480, // Small devices
      md: 768, // Medium devices (tablets)
      lg: 1024, // Large devices (desktops)
      xl: 1280, // Extra large devices (large desktops)
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
      dark: '#000000',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: globleStyle.font.type,
    h1: {
      fontFamily: globleStyle.font.type,
      fontSize: '2.5rem', // 40px
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: globleStyle.font.type,
      fontSize: '2rem', //32px
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: globleStyle.font.type,
      fontSize: '1.75rem', // 28px
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: globleStyle.font.type,
      fontSize: '1.5rem', // 24px
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: globleStyle.font.type,
      fontSize: '1.25rem', // 20px
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: globleStyle.font.type,
      fontSize: '1rem', //16px
      fontWeight: 700,
      lineHeight: 1.7,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: globleStyle.font.type,
      fontSize: '1rem', // 16px
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.875rem', //14px
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: globleStyle.font.type,
      fontSize: '1rem', //16px
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.875rem', //14px
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.75rem', //12px
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.75rem', //12px
      fontWeight: 400,
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: globleStyle.button.borderRadius || '4px', // Customize button border radius
          textTransform: 'none', // Disable uppercase transformation
        },
        containedPrimary: {
          color: '#ffffff',
          backgroundColor: light.activeColor, // Primary button color
          '&:hover': {
            backgroundColor: light.hoverColor, // Darker shade on hover
          },
        },
        outlined: {
          borderColor: '#DFE1E6', // Custom border color for outlined buttons
          color: '#666666', // Custom text color for outlined buttons
          '&:hover': {
            borderColor: '#DFE1E6', // Darker border color on hover
            backgroundColor: 'transparent', // Light blue background on hover
            color: '#666666',
          },
        },
        text: {
          color: light.activeColor, // Custom text color for text buttons
          '&:hover': {
            color: light.hoverColor,
            backgroundColor: 'rgba(25, 118, 210, 0.04)', // Light blue background on hover
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#999999', // Default color
          '&.Mui-checked': {
            color: light.activeColor, // Checked state color
          },
          '&.Mui-disabled': {
            color: '#cccccc', // Disabled state color
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            fontFamily: globleStyle.font.type, // Font for input text
            paddingLeft: '2px',
            paddingBottom: '10px',
            borderRadius: '0px',
          },
          '& .MuiInputLabel-root': {
            fontFamily: globleStyle.font.type,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderBottomWidth: '3px',
              borderBottomColor: '#DFE1E6',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0px',
            },
            '&:hover fieldset': {
              borderBottomWidth: '3px',
              borderBottomColor: '#DFE1E6',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0px',
            },
            '&.Mui-focused fieldset': {
              borderBottomWidth: '3px',
              borderBottomColor: light.activeColor,
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0px',
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: globleStyle.font.type, // Font for input text
          paddingLeft: '2px',
          paddingBottom: '5px',
          borderRadius: '0px',
        },
        underline: {
          '&:before': {
            borderBottomWidth: '3px',
            borderBottomColor: '#DFE1E6',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: '0px',
          },
          '&:hover:before': {
            borderBottomWidth: '3px',
            borderBottomColor: '#DFE1E6',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: '0px',
          },
          '&:after': {
            borderBottomWidth: '3px',
            borderBottomColor: light.activeColor,
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: '0px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#888888', // Example: '#888888'
          '&.Mui-focused': {
            color: light.activeColor, // Label color when input is focused, Example: '#1976D2'
          },
        },
        shrink: {
          color: light.activeColor, // Example: '#1976D2' when the label is shrunk
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // Customize the select box itself
          backgroundColor: 'white',
          borderRadius: '4px',
          borderWidth: '4px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: '#3f51b5', // Color on hover
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#DFE1E6', // Outline color
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderWidth: '3px',
            borderRadius: '0px',
          },
          '&:hover fieldset': {
            borderColor: '#DFE1E6', // Outline color on hover
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderWidth: '3px',
            borderRadius: '0px',
          },
          '&.Mui-focused fieldset': {
            borderColor: light.activeColor, // Outline color when focused
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderWidth: '3px',
            borderRadius: '0px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: light.activeColor,
            color: 'white',
          },
          '&.Mui-selected:hover': {
            backgroundColor: light.activeColor,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: light.activeColor,
          color: 'white',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#999999', // Custom color
          '&.Mui-checked': {
            color: light.activeColor,
          },
          width: 24,
          height: 24,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'white', // Tab bar background color
        },
        indicator: {
          backgroundColor: light.activeColor, // Indicator color
          height: '4px', // Indicator height
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Remove uppercase transformation
          fontWeight: 500, // Custom font weight
          fontSize: '14px', // Custom font size
          color: '#666666', // Tab text color
          width: '20%',
          '&.Mui-selected': {
            color: light.activeColor, // Selected tab text color
            backgroundColor: 'white', // Indicator color
          },
          '&:hover': {
            color: light.activeColor, // Hover state text color
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});

// Define the dark theme
const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480, // Small devices
      md: 768, // Medium devices (tablets)
      lg: 1024, // Large devices (desktops)
      xl: 1280, // Extra large devices (large desktops)
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
      dark: '#262626',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
  typography: {
    fontFamily: globleStyle.font.type,
    h1: {
      fontFamily: globleStyle.font.type,
      fontSize: '2.5rem', // 40px
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: globleStyle.font.type,
      fontSize: '2rem', //32px
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: globleStyle.font.type,
      fontSize: '1.75rem', // 28px
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: globleStyle.font.type,
      fontSize: '1.5rem', // 24px
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: globleStyle.font.type,
      fontSize: '1.25rem', // 20px
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: globleStyle.font.type,
      fontSize: '1rem', //16px
      fontWeight: 700,
      lineHeight: 1.7,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: globleStyle.font.type,
      fontSize: '1rem', // 16px
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.875rem', //14px
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: globleStyle.font.type,
      fontSize: '1rem', //16px
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.875rem', //14px
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.75rem', //12px
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: globleStyle.font.type,
      fontSize: '0.75rem', //12px
      fontWeight: 400,
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: globleStyle.button.borderRadius || '4px', // Customize button border radius
          textTransform: 'none', // Disable uppercase transformation
        },
        containedPrimary: {
          color: dark.color,
          backgroundColor: dark.activeColor, // Primary button color
          '&:hover': {
            backgroundColor: dark.hoverColor, // Darker shade on hover
          },
        },
        outlined: {
          borderColor: '#ffffff', // Custom border color for outlined buttons
          color: '#ffffff', // Custom text color for outlined buttons
          '&:hover': {
            borderColor: '#ffffff', // Darker border color on hover
            backgroundColor: 'transparent', // Light blue background on hover
            color: '#ffffff',
          },
        },
        text: {
          color: dark.activeColor, // Custom text color for text buttons
          '&:hover': {
            color: dark.hoverColor,
            backgroundColor: 'rgba(25, 118, 210, 0.04)', // Light blue background on hover
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#999999', // Default color
          '&.Mui-checked': {
            color: dark.activeColor, // Checked state color
          },
          '&.Mui-disabled': {
            color: '#cccccc', // Disabled state color
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            fontFamily: globleStyle.font.type, // Font for input text
            paddingLeft: '2px',
            paddingBottom: '10px',
            borderRadius: '0px',
          },
          '& .MuiInputLabel-root': {
            fontFamily: globleStyle.font.type,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderBottomWidth: '3px',
              borderBottomColor: '#DFE1E6',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0px',
            },
            '&:hover fieldset': {
              borderBottomWidth: '3px',
              borderBottomColor: '#DFE1E6',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0px',
            },
            '&.Mui-focused fieldset': {
              borderBottomWidth: '3px',
              borderBottomColor: dark.activeColor,
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0px',
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: globleStyle.font.type, // Font for input text
          paddingLeft: '2px',
          paddingBottom: '10px',
          borderRadius: '0px',
        },
        underline: {
          '&:before': {
            borderBottomWidth: '3px',
            borderBottomColor: '#DFE1E6',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: '0px',
          },
          '&:hover:before': {
            borderBottomWidth: '3px',
            borderBottomColor: '#DFE1E6',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: '0px',
          },
          '&:after': {
            borderBottomWidth: '3px',
            borderBottomColor: dark.activeColor,
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: '0px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#888888', // Example: '#888888'
          '&.Mui-focused': {
            color: dark.activeColor, // Label color when input is focused, Example: '#1976D2'
          },
        },
        shrink: {
          color: dark.activeColor, // Example: '#1976D2' when the label is shrunk
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // Customize the select box itself
          backgroundColor: 'white',
          borderRadius: '4px',
          borderWidth: '4px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: '#3f51b5', // Color on hover
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#DFE1E6', // Outline color
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderWidth: '3px',
            borderRadius: '0px',
          },
          '&:hover fieldset': {
            borderColor: '#DFE1E6', // Outline color on hover
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderWidth: '3px',
            borderRadius: '0px',
          },
          '&.Mui-focused fieldset': {
            borderColor: dark.activeColor, // Outline color when focused
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderWidth: '3px',
            borderRadius: '0px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: dark.activeColor,
            color: 'white',
          },
          '&.Mui-selected:hover': {
            backgroundColor: dark.activeColor,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: dark.activeColor,
          color: 'white',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#999999', // Custom color
          '&.Mui-checked': {
            color: dark.activeColor,
          },
          width: 24,
          height: 24,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'white', // Tab bar background color
        },
        indicator: {
          backgroundColor: dark.activeColor, // Indicator color
          height: '4px', // Indicator height
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Remove uppercase transformation
          fontWeight: 500, // Custom font weight
          fontSize: '14px', // Custom font size
          color: '#666666', // Tab text color
          width: '20%',
          '&.Mui-selected': {
            color: dark.activeColor, // Selected tab text color
            backgroundColor: 'white', // Indicator color
          },
          '&:hover': {
            color: dark.activeColor, // Hover state text color
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
