import { Box, MenuItem, TableCell, Typography } from '@mui/material';
// import { ArtTablePropsType } from './type';
// import ImageWithName from '../../../../../Component/ImageWithName';
// import moment from 'moment';
// import { SwiperSlide } from 'swiper/react';
// import FadeSlider from '../../../../../Component/Slider';
// import { ManageColorTheme } from '../../../../../helper/ChangeTheme';
// import DropdownMenu from '../../../../../Component/Dropdown';
import { Notification } from '../../../../../helper/Notification';
import apiService from '../../../../../API/APIService';
import { decryptData, encryptData } from '../../../../../API/encrypt';
import { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ClearIcon from '@mui/icons-material/Clear';
// import DoneIcon from '@mui/icons-material/Done';
// import { headerSet } from '../../../../../API/Header';
import { OrderTablePropsType } from './type';
import DropdownMenu from '../../../../../Component/Dropdown';
// import { copyType, isFrame } from '../../../../../constant/StaticData';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { OrderStatus } from '../../../../../constant/StaticData';
import { ManageColorTheme } from '../../../../../helper/ChangeTheme';

export default function OrderTableRow(props: OrderTablePropsType) {
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  // const handleDelete = async () => {
  //   try {
  //     const res = await apiService.delete(`/v1/api/admin/delete-art?art_id=${props?.data?.id}`, headerSet(false))
  //     if (res.status) {
  //       props.getData()
  //       Notification('success', res.message)
  //     } else {
  //       Notification('error', res.message)
  //     }
  //   } catch (error) {
  //     console.log("error", error)
  //   }
  // }

  const dropdownData = [
    {
      name: (
        <MenuItem
          onClick={() => {
            navigate(`/sales/order/${props.data.id}`);
          }}
        >
          <ViewListIcon sx={{ marginRight: 1 }} /> View Order
        </MenuItem>
      ),
      icon: '',
    },
  ];

  const handleChangeStatus = async () => {
    try {
      if (role) {
        const finalData = {
          art_id: props.data.id,
          status: role === 'Active' ? 1 : 0, // 0 => Inactive 1 => Active
        };
        const incriptData = (await encryptData(finalData)) as string;
        const secret_text = {
          secret_text: incriptData,
        };
        const res = await apiService.put(
          '/v1/api/admin/update-art-status',
          secret_text
        );
        if (res.status) {
          setRole('');
          Notification('success', res.message);
          props.getData();
        } else {
          setRole('');
          Notification('error', res.message);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setRole('');
    }
  };

  useEffect(() => {
    handleChangeStatus();
  }, [role]);
  return (
    <>
      <TableCell>{props?.data?.order_no}</TableCell>
      <TableCell>{props?.data?.total_item}</TableCell>
      <TableCell>{props?.data?.artistInfo?.profile?.name}</TableCell>
      <TableCell>{props?.data?.userInfo?.profile?.name}</TableCell>
      <TableCell>
        {Number(props?.data?.buyer_total_amount).toFixed(2)}
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              backgroundColor:
                props?.data?.is_payment === 0
                  ? 'rgb(251, 140, 0)'
                  : props?.data?.is_payment === 1
                    ? 'green'
                    : 'red',
              borderRadius: 100,
              marginRight: 1,
            }}
          ></Box>
          <Typography>{OrderStatus(props?.data?.is_payment)}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          {moment(props.data.created_at).format('MMM Do YYYY')}
          <br />
          <Typography variant="caption">
            {moment(props.data.created_at).format('hh:mm A')}
          </Typography>
        </Box>
      </TableCell>

      <TableCell
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DropdownMenu
          button={
            <Box sx={{ color: ManageColorTheme('#000000', '#ffffff') }}>
              <MoreVertIcon />
            </Box>
          }
          data={dropdownData}
          icon={false}
        />
      </TableCell>
    </>
  );
}
