import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from './theme';
import { useEffect, useState } from 'react';
import { THEME_MODE } from './config';
import { useSelector } from 'react-redux';
import { RootState } from './Redux/slices';
import { THEME, themeStoragekey } from './constant/theme';
import Layout from './Component/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.css';

function App() {
  const theme = useSelector((state: RootState) => state.theme.theme);
  const [themeMode, setThemeMode] = useState<string | undefined>('');
  const defaultMode: string | undefined = THEME_MODE;

  useEffect(() => {
    if (defaultMode === THEME.light || defaultMode === THEME.dark) {
      setThemeMode(defaultMode);
    } else {
      if (theme) {
        setThemeMode(theme);
      } else {
        localStorage.setItem(themeStoragekey, THEME.light);
        setThemeMode(THEME.light);
      }
    }
  }, [theme, defaultMode]);

  return (
    <Router>
      <ThemeProvider theme={themeMode === THEME.dark ? darkTheme : lightTheme}>
        <CssBaseline />
        <Layout />
        <ToastContainer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
