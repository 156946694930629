import { Box, MenuItem, TableCell, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CountryPropsType } from './type';
import apiService from '../../../../../API/APIService';
import { Notification } from '../../../../../helper/Notification';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import DeleteIcon from '@mui/icons-material/Delete';
import { headerSet } from '../../../../../API/Header';
import DropdownMenu from '../../../../../Component/Dropdown';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Status from '../../../../../Component/Status';
import Models from '../../../../../Component/Model';
// import DeleteModel from '../../../../../Component/Table/DeleteModel';
import { ManageColorTheme } from '../../../../../helper/ChangeTheme';
import { decryptData, encryptData } from '../../../../../API/encrypt';
import ActiveModel from '../../../../../Component/Table/ActiveModel';

export default function CountryTableRow(props: CountryPropsType) {
  const navigate = useNavigate();
  const [role, setRole] = useState('');
  const [roleStripe, setRoleStripe] = useState('');
  
  const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleDelete = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await apiService.delete(
  //       `/v1/api/admin/country-delete?country_id=${props?.data?.id}`,
  //       headerSet(false)
  //     );
  //     if (res.status) {
  //       setLoading(false);
  //       handleClose();
  //       props.getData();
  //       Notification('success', res.message);
  //     } else {
  //       setLoading(false);
  //       handleClose();
  //       Notification('error', res.message);
  //     }
  //     // eslint-disable-next-line
  //   } catch (error: any) {
  //     const res = await decryptData(error?.response?.data);
  //     Notification('error', res?.message);
  //     setLoading(false);
  //     handleClose();
  //   }
  // };

  const handleChangeStatus = async () => {
    try {
      const finalData = {
        country_id: props?.data?.id,
        title: props?.data?.title,
        is_delivery_available: props?.data?.is_delivery_available
          ? false
          : true,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.put(
        '/v1/api/admin/country-update',
        secret_text,
        headerSet(false)
      );
      if (res.status) {
        setLoading(true);
        Notification('success', res.message);
        setRole('');
        props.getData();
      } else {
        setLoading(false);
        Notification('error', res.message);
        setRole('');
        props.getData();
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
      setRole('');
      props.getData();
    }
  };

  const handleChangeStripeStatus = async () => {
    try {
      const finalData = {
        country_id: props?.data?.id,
        title: props?.data?.title,
        account_creation_status : props?.data?.account_creation_status == 1 ?  2 : 1
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.put(
        '/v1/api/admin/country-update',
        secret_text,
        headerSet(false)
      );
      if (res.status) {
        setLoading(true);
        Notification('success', res.message);
        setRole('');
        props.getData();
      } else {
        setLoading(false);
        Notification('error', res.message);
        setRole('');
        props.getData();
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
      setRole('');
      props.getData();
    }
  };

  const dropdownData = [
    {
      name: (
        <MenuItem
          onClick={() => {
            navigate(`/master/country/${props.data.id}`);
          }}
        >
          {' '}
          <EditIcon sx={{ marginRight: 1 }} /> Edit
        </MenuItem>
      ),
      icon: '',
    },
    // {
    //   name: (
    //     <Models
    //       open={open}
    //       handleOpen={handleOpen}
    //       handleClose={handleClose}
    //       button={
    //         <MenuItem sx={{ color: 'rgb(211, 47, 47)' }} onClick={() => {}}>
    //           <DeleteIcon color="error" sx={{ marginRight: 1 }} /> Delete
    //         </MenuItem>
    //       }
    //       content={
    //         <DeleteModel
    //           handleDelete={handleDelete}
    //           description="You want to delete country"
    //           handleClose={handleClose}
    //           loading={loading}
    //           id={props?.data?.id}
    //         />
    //       }
    //     />
    //   ),
    //   icon: '',
    // },
  ];

  return (
    <>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.flag_url + ' ' + props?.data?.title}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {props?.data?.currency_code} - {props?.data?.currency}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{props?.data?.phone_code}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{props?.data?.code}</Typography>
      </TableCell>
      <TableCell>
        {moment(props.data.created_at).format('MMM Do YYYY')}
      </TableCell>
      <TableCell>
        <Models
          open={role ? true : false}
          handleOpen={() => {
            setRole(
              props?.data?.is_delivery_available === true
                ? 'inActive'
                : 'active'
            );
          }}
          handleClose={() => {
            setRole('');
            props.getData();
          }}
          button={
            <Status
              label={
                props?.data?.is_delivery_available ? 'Available' : 'Unavailable'
              }
              status={props?.data?.is_delivery_available}
            />
          }
          content={
            <ActiveModel
              handleDelete={handleChangeStatus}
              description="Are you sure you want to change the status"
              handleClose={() => {
                setRole('');
                props.getData();
              }}
              label={
                props?.data?.is_delivery_available ? 'Unavailable' : 'Available'
              }
              loading={loading}
              id={props?.data?.id}
            />
          }
        />
      </TableCell>
      <TableCell>
        <Models
          open={roleStripe ? true : false}
          handleOpen={() => {
            setRoleStripe(
              props?.data?.account_creation_status !== 1
                ? 'inActive'
                : 'active'
            );
          }}
          handleClose={() => {
            setRoleStripe('');
            props.getData();
          }}
          button={
            <Status
              label={
                props?.data?.account_creation_status == 1 ? 'Available' : 'Disable'
              }
              status={props?.data?.account_creation_status == 1 ? true : false}
            />
          }
          content={
            <ActiveModel
              handleDelete={handleChangeStripeStatus}
              description="Are you sure you want to change the status"
              handleClose={() => {
                setRole('');
                props.getData();
              }}
              label={
                props?.data?.account_creation_status == 1 ? 'Disable' : 'Available'
              }
              loading={loading}
              id={props?.data?.id}
            />
          }
        />
      </TableCell>
      <TableCell
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DropdownMenu
          button={
            <Box sx={{ color: ManageColorTheme('#000000', '#ffffff') }}>
              <MoreVertIcon />
            </Box>
          }
          data={dropdownData}
          icon={false}
        />
      </TableCell>
    </>
  );
}
