import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './slices';

// Create and configure the Redux store
const store = configureStore({
  reducer: rootReducer,
  // Optional: Add middleware or devTools options here
});

// Export the store
export default store;
