import React, { useState } from 'react';
import apiService from '../../../API/APIService';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Buttons from '../../../Component/Buttons';
import { decryptData, encryptData } from '../../../API/encrypt';
import { Notification } from '../../../helper/Notification';
import { AUTHPATH } from '../../../Route/Path';
import { useNavigate } from 'react-router-dom';
import { headerSet } from '../../../API/Header';
import BredCrums from '../../../Component/Layout/BredCrums';
import { passwordRegex } from '../../../constant/StaticData';

interface IFormInput {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Old Password is required')
    .matches(passwordRegex, 'Password must be at least 8 characters long, contain both letters and numbers, and include at least one special character'),

  password: Yup.string()
    .required('New Password is required')
    .matches(passwordRegex, 'Password must be at least 8 characters long, contain both letters and numbers, and include at least one special character')
    .notOneOf(
      [Yup.ref('oldPassword')],
      'New Password cannot be the same as Old Password'
    ),

  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export default function Profile() {
  // const [userData, setUserData] = useState({})
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    try {
      setLoading(true);
      const finalData = {
        old_password: data.oldPassword,
        password: data.password,
      };
      const incriptData = (await encryptData(finalData)) as string;
      const secret_text = {
        secret_text: incriptData,
      };
      const res = await apiService.put(
        '/v1/api/admin/change-password',
        secret_text,
        headerSet(false)
      );
      if (res.status) {
        setLoading(false);
        Notification('success', res.message);
        navigate(AUTHPATH.root);
      } else {
        setLoading(false);
        Notification('error', res.message);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const res = await decryptData(error?.response?.data);
      Notification('error', res?.message);
      setLoading(false);
    }
  };

  // const getProfile = async () => {
  //     const res = await apiService.get('/v1/api/admin/profile')
  //     if (res.status) {
  //         setUserData(res.data)
  //     }
  // }

  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(prev => !prev);
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(prev => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // useEffect(() => {
  //     getProfile()
  // }, [])

  // console.log("userData", userData)
  return (
    <Box>
      {/* <Typography variant='h5'>Profile</Typography> */}
      <BredCrums
        heading="Change Password"
        path={[
          { name: 'Dashboard', path: '/dashboard' },
          { name: 'Change Password', path: '' },
        ]}
        description=""
        action={<Box sx={{ display: 'flex', flexWrap: 'wrap' }}></Box>}
      />
      <Box
        component="form"
        sx={{ marginTop: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <TextField
                type={showPassword ? 'text' : 'password'}
                sx={{ width: '100%' }}
                placeholder="Current Password"
                {...register('oldPassword')}
                error={!!errors.oldPassword}
                helperText={errors.oldPassword?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={showPassword1 ? 'text' : 'password'}
                sx={{ width: '100%' }}
                placeholder="New Password"
                {...register('password')}
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={showPassword2 ? 'text' : 'password'}
                sx={{ width: '100%' }}
                placeholder="Confirm Password"
                {...register('confirmPassword')}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Buttons
              style={{ width: 'fit-content', marginTop: '20px' }}
              loading={loading}
              iconAlign="left"
              iconSize={18}
              type="submit"
              icon=""
              variant="button"
              onClick={() => {}}
              label="Change Password"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
