import { Box, FormControlLabel, Switch } from '@mui/material';
import React from 'react';

export default function Status(props: {
  label: string;
  status: boolean;
  disebled?: boolean;
}) {
  return (
    <Box
    // sx={{
    //   backgroundColor: props.status ? 'rgb(46, 125, 50)' : 'rgb(211, 47, 47)',
    //   width: 'fit-content',
    //   paddingX: 1,
    //   paddingY: 0.5,
    //   borderRadius: 1,
    //   color: '#ffffff',
    // }}
    >
      <FormControlLabel
        control={
          <Switch
            defaultChecked={props?.status}
            disabled={props?.disebled ? props?.disebled : false}
          />
        }
        label=""
      />
      {/* <Typography variant="body2" sx={{ color: '#ffffff' }}>
        {props.label}
      </Typography> */}
    </Box>
  );
}
